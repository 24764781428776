import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../lib/hooks/WindowSizeHook";
// import { useTranslation } from "react-i18next";

const WhyUs = () => {
  const [scrollY, setScrollY] = useState(0);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const {width} = useWindowSize();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Parallax speed={width < 700 ? 10 : 20}>
      <div
        className={`xl:mb-[160px] max-md:mt-[70px] max-sm:mt-10 ${
          inView ? "fadeIn" : "opacity-0"
        }`}
        ref={ref}
      >
        <p
          className="text-[42px] lg:text-[54px] font-semibold text-white text-center mb-8 max-sm:mb-5"
          style={{ letterSpacing: "-2.16px" }}
        >
          Why us?
        </p>
        <p
          className="text-dark-text text-base pb-14 max-w-[800px] mx-auto container text-center max-md:pb-10 max-sm:text-sm max-sm:pb-8"
          style={{ letterSpacing: "-0.64px" }}
        >
          The answer is quite simple, just like our interface. We offer the
          latest banking, crypto exchange, and trading options for our users.
        </p>
        <div className="flex max-lg:flex-col max-lg:items-center justify-center gap-8">
          <div className="w-[90%] sm:w-[355px] p-8 bg-card-dark border-[#353D45] border-[1px] rounded-2xl max-sm:p-6">
            <img src="/icons/Security.png" alt="" className="w-[64px] pb-6 max-sm:pb-4 max-sm:w-12" />
            <p
              className="text-2xl text-white font-[600] pb-6 max-sm:pb-4 max-sm:text-xl"
              style={{ letterSpacing: "-0.96px" }}
            >
              Elite Security
            </p>
            <p
              className="text-lg text-white/80 font-[300] max-sm:text-sm"
              style={{ letterSpacing: "-0.36px" }}
            >
              The best integrated protection system that money can buy, without you paying for it. All you need to worry about is where to spend your crypto.
            </p>
          </div>
          <div className="w-[90%] sm:w-[355px] p-8 bg-card-dark border-[#353D45] border-[1px] rounded-2xl max-sm:p-6">
            <img src="/icons/Support.png" alt="" className="w-[64px] pb-6 max-sm:pb-4 max-sm:w-12" />
            <p
              className="text-2xl text-white font-[600] pb-6 max-sm:pb-4 max-sm:text-xl"
              style={{ letterSpacing: "-0.96px" }}
            >
              24/7 Support
            </p>
            <p
              className="text-lg text-white/80 font-[300] max-sm:text-sm"
              style={{ letterSpacing: "-0.36px" }}
            >
              Our support team is always ready to answer your questions, no matter the time or your time zone.
            </p>
          </div>
          <div className="w-[90%] sm:w-[355px] p-8 bg-card-dark border-[#353D45] border-[1px] rounded-2xl max-sm:p-6">
            <img src="/icons/easyToUse.svg" alt="" className="w-[64px] pb-6" />
            <p
              className="text-2xl text-white font-[600] pb-6 max-sm:pb-4 max-sm:text-xl"
              style={{ letterSpacing: "-0.96px" }}
            >
              Easy to use
            </p>
            <p
              className="text-lg text-white/80 font-[300] max-sm:text-sm"
              style={{ letterSpacing: "-0.36px" }}
            >
              Our platform is designed for ease of use. You will find everything at your fingertips.
            </p>
          </div>
        </div>
        <div className="flex justify-center mt-[60px] max-sm:mt-11">
          <Link to={process.env.REACT_APP_SIGN_UP}>
            <div className="border-custom-primary border-[1px] text-custom-primary hover:bg-custom-primary hover:border-transparent cursor-pointer 
                transition-colors hover:text-black px-12 py-4 w-fit rounded-lg font-[600] max-sm:px-6 max-sm:py-3">
              Try Now
            </div>
          </Link>
        </div>
      </div>
    </Parallax>
  );
};

export default WhyUs;

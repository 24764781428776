import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { TECollapse } from "tw-elements-react";

const Commission = () => {
  const [show, setShow] = useState({
    collapse1: false,
    collapse2: false,
  });

  const toggleShow = (value) => {
    setShow({ ...show, ...value });
  };

  return (
    <div className="container py-[150px] max-sm:py-20">
      <p className="text-[40px] md:text-[54px] text-white font-[500] text-center pb-[54px]">
        How To Get <span className="text-custom-primary">Commissions</span>
      </p>
      <div className="flex max-md:flex-col gap-9">
        <div className="rounded-xl bg-gradient-to-br from-custom-primary/50 via-custom-primary/10 to-transparent p-[1px] md:w-1/2 w-full">
          <div className="bg-[#0e1921] rounded-xl p-8 flex flex-col gap-4 justify-between h-full">
            <div className="flex flex-col gap-4">
              <img src="/icons/security_icon.png" alt="" className="w-[64px]" />
              <p className="text-white font-[500] text-2xl">Refer Friends</p>
              <p className="text-dark-text text-md md:text-xl leading-[132%]">
                Send a link to invite your friends to Niza.
                <br /> After they join and create their account, you will earn a
                30% commission from our referral program.
              </p>
            </div>
            <Link to={process.env.REACT_APP_SIGN_IN}>
              <div className="bg-custom-primary border-[1px] border-custom-primary rounded-lg w-fit p-4 font-[600] mt-8 cursor-pointer hover:bg-transparent hover:text-custom-primary transition-colors max-sm:py-3">
                Refer Friends
              </div>
            </Link>
          </div>
        </div>
        <div className="rounded-xl bg-gradient-to-br from-custom-primary/50 via-custom-primary/10 to-transparent p-[1px] md:w-1/2 w-full">
          <div className="bg-[#0e1921] rounded-xl p-8 flex flex-col gap-4 justify-between h-full">
            <div className="flex flex-col gap-4">
              <img src="/icons/referral_icon.png" alt="" className="w-[64px]" />
              <p className="text-white font-[500] text-2xl">Earn commission</p>
              <p className="text-dark-text text-md md:text-xl leading-[132%]">
                You Can Bring new friends and earn your commission. <br />
                Get rewarded for sharing Niza's platform, and enjoy your
                experience of becoming Financial-Free.
              </p>
            </div>
            {/* <img src="/icons/security_icon.png" alt="" className="w-[54px]" /> */}
            <Link to={process.env.REACT_APP_SIGN_IN}>
              <div className="bg-custom-primary border-[1px] border-custom-primary rounded-lg w-fit p-4 font-[600] mt-8 cursor-pointer hover:bg-transparent hover:text-custom-primary transition-colors max-sm:py-3">
                Earn Comission
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* Referral Program */}
      <p className="text-[42px] font-[600] text-white sm:mt-[180px] mt-20 mb-2 max-sm:text-4xl">
        Referral Program
      </p>
      <p className="text-base text-dark-text">
        Niza Global believes in the power of partnerships and the value they bring to our global business ecosystem. To further strengthen our commitment to 
        collaboration and growth.
      </p>
      <p className="text-2xl font-[500] text-white mt-5 mb-2">
        Program Structure
      </p>
      <p className="text-base text-dark-text">
        The Niza Global Affiliate Program operates on a straightforward and transparent structure. Clients enrolled in the program have the opportunity to refer new clients to 
        Niza Global. When a referred client engages in trades, the referred client (affiliate) is rewarded with a generous 30% commission based on the trade fee charged by Niza 
        Global to the referred client, with an upper limit of $1500 per referred client in earnings for the affiliate. These rewards are credited to the affiliate's account in the 
        form of Niza Coin at the end of each month.
      </p>
      <div id="accordionExample" className="mt-5">
        <div className="bg-transparent">
          <div className="mb-0" id="headingOne">
            <button
              className=" [sm:box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] group relative flex w-full items-center rounded-t-[15px] border-0 pr-5 py-4 text-left md:text-xl text-lg transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-transparent text-white"
              type="button"
              onClick={() =>
                toggleShow({ ...show, collapse1: !show.collapse1 })
              }
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              How it Works
              <span
                className={`${
                  show.collapse1
                    ? `rotate-[-180deg] -mr-1`
                    : `rotate-0 fill-[#212529]  dark:fill-white`
                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>
          </div>
          <TECollapse
            show={show.collapse1}
            className="!mt-0 !rounded-b-none !shadow-none"
          >
            <ol className="list-decimal list-inside pr-5 py-4 text-dark-text text-sm mb-[42]">
              <li className="max-md:mb-2">Client Referral: Affiliates actively promote and refer Niza Global's services to potential clients, encouraging them to engage with our platform.</li>
              <li className="max-md:mb-2">Referred Client Engagement: When a referred client registers and begins transacting through Niza Global, the affiliate starts accruing rewards based on the trading fees incurred by the referred client.</li>
              <li className="max-md:mb-2">Accruing Rewards: For each trade made by the referred client, Niza Global allocates a 30% commission of the trade fee to the affiliate who made the referral.</li>
            </ol>
          </TECollapse>
        </div>
      </div>
      <div className="bg-transparent">
        <div className="mb-0" id="headingTwo">
          <button
            className=" [sm:box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] group relative flex w-full items-center rounded-t-[15px] border-0 pr-5 pb-4 pt-8  text-left md:text-xl text-lg transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-transparent text-white"
            type="button"
            onClick={() => toggleShow({ ...show, collapse2: !show.collapse2 })}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Benefits for Affiliates
            <span
              className={`${
                show.collapse2
                  ? `rotate-[-180deg] -mr-1`
                  : `rotate-0 fill-[#212529] dark:fill-white`
              } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
        </div>
        <TECollapse
          show={show.collapse2}
          className="!mt-0 !rounded-b-none !shadow-none"
        >
          <ul className="list-disc list-inside pr-5 py-4 text-dark-text text-sm">
            <li className="max-md:mb-2">Financial Incentives: Affiliates receive a percentage of the trade fees generated by the clients they refer, adding an additional stream of income.</li>
            <li className="max-md:mb-2">Passive Income Opportunity: As referred clients continue to transact, affiliates continuously earn rewards without additional effort.</li>
            <li className="max-md:mb-2">Expand Network and Influence: By referring clients, affiliates expand their network and influence within their business circles, enhancing their credibility and opportunities.</li>
            <li className="max-md:mb-2">Support and Resources: Niza Global provides affiliates with the necessary tools, marketing materials, and support to effectively refer potential clients.</li>
          </ul>
        </TECollapse>
      </div>
      <p className="text-2xl font-[500] text-white mt-10 mb-2 max-sm:mt-4">
        Encouraging Collaboration and Growth
      </p>
      <p className="text-base text-dark-text">
        The Niza Global Affiliate Program is designed to foster a symbiotic relationship between Niza Global and our clients. We believe that our success is intertwined with the 
        success of our clients and affiliates. This program incentivizes referrals, empowering our clients to actively participate in our growth journey.
      </p>
    </div>
  );
};

export default Commission;

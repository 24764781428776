import React from 'react'
// import ActionBar from '../bar/ActionBar'
import Navbar from './Navbar'


const Navigation = () => {

  return (
    <div className="fixed top-0 left-0 w-full z-20 md:z-[1000]">
      <Navbar />
    </div>
  )
}

export default Navigation

import React from "react";
import { allCoins } from "../../lib/data/CoinsData";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { useWindowSize } from "../../lib/hooks/WindowSizeHook";
// import { useTranslation } from "react-i18next";
// import parse from "html-react-parser";

import "../../styles/allCoins.css";

const AllCoins = () => {
  
  const {width} = useWindowSize();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Parallax speed={width < 700 ? 10 : 30}>
      <div
        className={`container my-[100px] relative max-sm:my-[50px] ${
          inView ? "fadeIn" : "opacity-0"
        }`}
        ref={ref}
      >
        <div className="absolute left-[-80%] md:left-[-50%] top-[-120%] md:top-[-150%] w-6 h-6 side-light2 max-md:hidden" />
        <div className="absolute right-[-80%] md:right-[-50%] top-[500%] md:top-[60%] w-6 h-6 side-light2 max-md:hidden" />
        <p
          className="text-[42px] lg:text-[54px] font-semibold text-white text-center pb-3.5 z-10"
          style={{ letterSpacing: "-2.16px" }}
        >
          Hot Coins
        </p>
        <p
          className="text-dark-text text-sm md:text-base max-w-[800px] mx-auto mb-11 text-center z-10"
          style={{ letterSpacing: "-0.36px" }}
        >
          All the hot coins that have gained the most views are in the list below. See for yourself; maybe you see something you like.
        </p>
        <div className="relative hot-coins cz-10">
          {allCoins.map((coinCategory) => (
            <Slider {...settings}>
              {coinCategory.map((coins) => (
                <div className="md:mx-2">
                  <div className="p-2 md:p-4 bg-white bg-opacity-20 rounded-[10px] mx-2">
                    <div className="flex items-center gap-3">
                      <img src={coins.icon} alt="" className="w-6 h-6" />
                      <p
                        className="w-fit text-white"
                        style={{ letterSpacing: "-0.36px" }}
                      >
                        {coins.name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ))}
          <div className="absolute top-0 right-0 bg-gradient-to-r from-transparent to-[#13191C] w-[30%] h-[100%]"></div>
          <div className="absolute top-0 left-0 bg-gradient-to-l from-transparent to-[#13191C] w-[30%] h-[100%]"></div>
        </div>
      </div>
    </Parallax>
  );
};

export default AllCoins;

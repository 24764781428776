import React from "react";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import Cards from "../common/Cards";

const WhyNiza = () => {
  // const { t } = useTranslation();

  // const cardsContent = [
  //   {
  //     icon: "/icons/Security.png",
  //     title: "Elite Security",
  //     description: "The best integrated protection system that money can buy without you paying for it. What you need to worry about is where to spend your crypto.",
  //   },
  //   {
  //     icon: "/icons/Support.png",
  //     title: "24/7 Support",
  //     description:
  //       "Our support team is always ready to answer your questions, no matter the time or time zone you are located in.",
  //   },
  //   {
  //     icon: "/icons/easyToUse.svg",
  //     title: "Easy to use",
  //     description:
  //       "Our platform was crafted for you to use it with ease. You will find everything in the palm of your hand.",
  //   },
  // ];

  return (
    <div className="bg-[#0E141C] py-[160px] max-sm:py-24">
      <div className="container">
        <p
          className="text-[42px] lg:text-[54px] font-semibold text-center text-white"
          style={{ letterSpacing: "-2.16px" }}
        >
          Why NIZA
          {/* {t("home.getStarted.title")} */}
        </p>
        <p
          className="text-base lg:text-lg font-[300] lg:font-[400] text-center text-dark-text"
          style={{ letterSpacing: "-0.36px" }}
        >
          <span className="text-white/80 font-medium">Established in 2021</span>,{" "}
          <span className="text-custom-primary font-medium">NIZA</span> stands
          out for its cutting-edge high-performance
          <br /> exchange and mega transaction matching technology.
          {/* {parse(t("home.getStarted.description"))} */}
        </p>
      </div>
      <div className="flex justify-center mt-[50px] max-sm:mt-10">
        <div className="flex max-lg:flex-col max-lg:items-center justify-center gap-8 md:w-[1186px] max-sm:gap-6">
          <div className="w-[90%] sm:1/3 p-8 bg-card-dark border-[#353D45] border-[1px] rounded-2xl">
            <img src="/icons/Security.png" alt="" className="w-[64px] pb-6" />
            <p
              className="text-2xl text-white font-semibold pb-6"
              style={{ letterSpacing: "-0.96px" }}
            >
              Elite Security
            </p>
            <p
              className="text-lg text-white/80 font-[300]"
              style={{ letterSpacing: "-0.36px" }}
            >
              The best integrated protection system that money can buy without
              you paying for it. What you need to worry about is where to spend
              your crypto.
            </p>
          </div>
          <div className="w-[90%] sm:1/3 p-8 bg-card-dark border-[#353D45] border-[1px] rounded-2xl">
            <img src="/icons/Support.png" alt="" className="w-[64px] pb-6" />
            <p
              className="text-2xl text-white font-semibold pb-6"
              style={{ letterSpacing: "-0.96px" }}
            >
              24/7 Support
            </p>
            <p
              className="text-lg text-white/80 font-[300]"
              style={{ letterSpacing: "-0.36px" }}
            >
              Our support team is always ready to answer your questions, no
              matter the time or time zone you are located in.
            </p>
          </div>
          <div className="w-[90%] sm:1/3 p-8 bg-card-dark border-[#353D45] border-[1px] rounded-2xl">
            <img src="/icons/easyToUse.svg" alt="" className="w-[64px] pb-6" />
            <p
              className="text-2xl text-white font-semibold pb-6"
              style={{ letterSpacing: "-0.96px" }}
            >
              Easy to use
            </p>
            <p
              className="text-lg text-white/80 font-[300]"
              style={{ letterSpacing: "-0.36px" }}
            >
              Our platform was crafted for you to use it with ease. You will
              find everything in the palm of your hand.
            </p>
          </div>
        </div>
      </div>

      <div className="border-[1px] border-dark-text/50 flex max-md:flex-col max-md:gap-8 max-md:w-[90%] justify-between container md:w-[1186px] mt-8 p-8 rounded-2xl items-center max-sm:mt-6">
        <div className="flex items-center max-md:gap-3">
          <p className="bg-gradient-to-r bg-clip-text font-bold text-transparent from-slate-50/20 to-slate-50/0 md:text-[32px] text-base">
            Time is going...
          </p>
          <p className="text-custom-primary md:text-[32px] text-base font-semibold">
            Take action for your financial tomorrow
          </p>
        </div>
        <Link to={process.env.REACT_APP_SIGN_UP}>
          <div className="border-custom-primary border-[1px] text-custom-primary hover:bg-custom-primary hover:border-transparent cursor-pointer transition-colors hover:text-black px-5 py-3 w-fit rounded-lg text-base font-semibold">
            Get Started
          </div>
        </Link>
      </div>
    </div>
  );
};

export default WhyNiza;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const TextCards = () => {
  return (
    <div className="container mx-auto mt-[124px] mb-[224px] max-md:mb-[120px] max-sm:mb-20">
      <div className="flex max-lg:flex-col gap-5">
        <div className="bg-custom-secondary border-[#353D45] border-[2px] p-4 rounded-xl flex gap-3 lg:w-1/2 w-full">
          <FontAwesomeIcon
            icon={faNewspaper}
            className="text-custom-primary bg-custom-primary/30 p-2 rounded-md"
          />
          <div>
            <p className="font-semibold text-base text-custom-primary max-sm:text-sm pb-1">
              Why did altcoins rise again in value?
            </p>
            <p className="text-sm text-gray-300 pb-3 max-sm:text-xs max-sm:pb-2">
              A large number of factors contributed to this increase. But what
              was the most significant one?
            </p>
            <div class="flex">
              <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
                <p class="text-custom-primary cursor-pointer text-sm hover:decoration-custom-primary hover:underline">
                  Learn More
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-custom-secondary border-[#353D45] border-[2px] p-4 rounded-xl flex gap-3 lg:w-1/2 w-full">
          <FontAwesomeIcon
            icon={faNewspaper}
            className="text-custom-primary bg-custom-primary/30 p-2 rounded-md"
          />
          <div>
            <p className="font-semibold text-base text-custom-primary max-sm: pb-1">
              Nothing stops the increase in the value of cryptocurrency.
            </p>
            <p className="text-sm text-gray-300 pb-3 max-sm:text-xs max-sm:pb-2">
              They have gained more than half a trillion in value. Not even the
              controversy over the FTX collapse halted their rise.
            </p>
            <div class="flex">
              <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
                <p class="text-custom-primary cursor-pointer text-sm hover:decoration-custom-primary hover:underline">
                  Learn More
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextCards;

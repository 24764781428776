import React, { useState, useEffect } from "react";
import CryptoCoinTables from "../tables/CryptoCoinTables";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useGetCoinData } from "../../lib/api/table";
import { useWindowSize } from "../../lib/hooks/WindowSizeHook";
// import Chart from "react-apexcharts";

const CryptoTable = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [scrollY, setScrollY] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(1);
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const { coinData } = useGetCoinData();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function getPagination() {
    const allPaginations = Array.from(
      { length: parseInt(coinData.length / 6) + 1 },
      (_, i) => i + 1
    );

    if (allPaginations.length <= 5) {
      return allPaginations;
    } else {
      if (currentPagination < 3) {
        return allPaginations.slice(0, 5);
      } else if (currentPagination > allPaginations.length - 3) {
        return allPaginations.slice(
          allPaginations.length - 6,
          allPaginations.length - 1
        );
      } else {
        return allPaginations.slice(
          currentPagination - 3,
          currentPagination + 2
        );
      }
    }
  }

  return (
    <Parallax speed={width < 700 ? 10 :40}>
      <div className={`relative lg:mt-[50px] mt-[400px]`}>
        <div class="side-light"></div>
        <div
          className={`container m-auto  ${inView ? "fadeIn" : "opacity-0"}`}
          ref={ref}
        >
          <p
            className="text-center text-white text-[42px] md:text-[54px] font-semibold max-sm:text-4xl max-sm:mb-3"
            style={{ letterSpacing: "-2.16px" }}
          >
            {t("home.cyptoTable.title")}
          </p>
          <p
            className="text-center text-dark-text text-base md:text-lg mb-[46px] max-sm:text-sm max-sm:mb-6 max-w-[800px] mx-auto"
            style={{ letterSpacing: "-0.36px" }}
          >
            {parse(t("home.cyptoTable.description"))}
          </p>
          <CryptoCoinTables
            pagination={currentPagination - 1}
            coinData={coinData}
          />
          <nav
            aria-label="Page navigation example"
            className="flex justify-center mt-[42px] select-none max-sm:mt-8"
          >
            <ul class="inline-flex -space-x-px text-sm bg-gradient-to-b from-[#ffffff12] to-[#ffffff05] rounded-lg">
              <li
                onClick={() => {
                  if (currentPagination > 1) {
                    setCurrentPagination(currentPagination - 1);
                  }
                }}
              >
                <p class="flex items-center gap-2 justify-center px-3 h-10 ms-0 leading-tight text-white  border border-e-0 rounded-s-lg border-gray-700 hover:bg-gray-700 hover:text-white cursor-pointer">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Previous
                </p>
              </li>
              {getPagination().map((pagination) => (
                <li onClick={() => setCurrentPagination(pagination)}>
                  <div
                    class={`flex items-center justify-center h-10  cursor-pointer ${
                      pagination === currentPagination
                        ? " border-y  border-gray-700 text-black "
                        : "leading-tight border-y  border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white"
                    }`}
                  >
                    <p
                      className={`px-4 border-x border-gray-700 ${
                        pagination === currentPagination
                          ? " bg-custom-primary rounded-lg py-1 "
                          : ""
                      }`}
                    >
                      {pagination}
                    </p>
                  </div>
                </li>
              ))}
              <li
                onClick={() => {
                  if (currentPagination < parseInt(coinData.length / 6) + 1) {
                    setCurrentPagination(currentPagination + 1);
                  }
                }}
              >
                <p class="flex items-center gap-2 justify-center px-3 h-10 leading-tight text-white border border-l-0 rounded-e-lg  border-gray-700  hover:bg-gray-700 hover:text-white  cursor-pointer">
                  Next
                  <FontAwesomeIcon icon={faArrowRight} />
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Parallax>
  );
};

export default CryptoTable;

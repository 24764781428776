export const disclosuresText = [
  "Digital Assets. The term “digital asset(s)” does not refer to any legal or regulatory category under any national framework. The value of digital assets can be volatile and there can be a substantial risk that you lose money buying, selling, holding, or investing in digital assets. Each digital asset has unique features and you should research and understand an asset before you trade.",
  "Not Investment Advice. The information about digital assets on any NIZA website (including www.Niza.io and blog.Niza.io), the NIZA exchange, e-mails, or any other communications is for general information purposes only. NIZA does not provide investment, tax, or legal advice, and you are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances, and risk tolerance. The information should not be construed as a recommendation or solicitation to buy, sell, stake, or hold any digital asset or to open an account or engage in any specific trading strategy. You should consult your financial advisor, or legal or tax professional regarding your specific situation and financial condition and carefully consider whether trading, staking, or holding digital assets is suitable for you."
]

export const disclosureCountries = [
  {
    img: "/images/flags/usa.png",
    name: "U.S.A",
    description: "Niza Global successfully obtained a Money Service Business registration in U.S.A. Registration details : MSB Registration Number: 31000257328129 Registration Type: Initial Registration, Legal Name: Niza Global LLC, MSB Activities: Money transmitter, Street Address: 605 Geddes St. City: Wilmington, State: DELAWARE, Zip: 19805",
    id: "31000257328129"
  },
  {
    img: "/images/flags/canada.png",
    name: "Canada",
    description: "NIZA GLOBAL LTD is a company registered in London,United Kingdom, holding a Canadian Money Service Business license with registration number M23437126. Providing Foreign exchange dealing, Money transferring, Dealing in virtual currencies and payment service provider.",
    id: "M23437126"
  },
  {
    img: "/images/flags/lithuania.png",
    name: "Lithuania",
    description: "Niza Global UAB with registration number 306048866 and license number 306048866 for Providing virtual currency service, and whose registered address is Architektų g. 56-101, LT-04111 Vilnius.",
    id: "306048866"
  },
  {
    img: "/images/flags/bulgaria.png",
    name: "Bulgaria",
    description: "Niza Global EOOD with registration number 207107327 and license number 207107327 for Providing virtual currency service with authorization number ВВ-109/28.10.2022, and whose registered address is 49A Bulgaria blvd., ap.24, Sofia, Triaditsa, Bulgaria, 1404.",
    id: "207107327"
  },
  {
    img: "/images/flags/costarica.png",
    name: "Costa Rica",
    description: "NIZA HOLDINGS SOCIEDAD DE RESPONSABILIDAD LIMITADA, number of identification 3102886795.Providing FINANCIAL MARKETS ADMINISTRATION with activity id 671101. Registration address is SAN JOSE, SAN JOSE, HOSPITAL, DON BOSCO, Calle 22, Avenida 4-6.",
    id: "3102886795"
  },
  {
    img: "/images/flags/greatbritain.png",
    name: "Iban services: UK FCA",
    description: "E-Money & IBAN Services – Your Account will be provided by Banxe LTD (FCA Reference No 931509). Any communications in relation to the account can be sent to Banxe LTD, Queensbury House, 106 Queens Road, Brighton, East Sussex, England, BN1 3XF. Banxe is a registered trademark. Banxe LTD. Is trading as Banxe and performing its activities as a Distributor of Tompay LTD, authorised by the Financial Conduct Authority as an Electronic Money Institution under the Electronic Money Regulations 2011 (931509).",
    id: "931509"
  },
  {
    img: "/images/flags/italy.png",
    name: "Italy",
    description: "Consob Italy License coming soon",
    id: "Coming Soon"
  },
]
export function getCurrentDimension(){
  return {
      width: window.innerWidth,
      height: window.innerHeight
  }
}

export function decodeHtmlEntities(encodedString) {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(
    `<!doctype html><body>${encodedString}`,
    "text/html"
  ).body.textContent;
  return decodedString;
}

export function getContent(content) {
  const decodedContent =
    typeof window !== "undefined" ? decodeHtmlEntities(content) : content;

  return decodedContent;
}

export const convertStringToHTML = htmlString => {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString, 'text/html');

  return html.body;
}

export const formatNumber = (num) => {
  const absNum = Math.abs(num);

  const abbreviations = {
    K: 1e3,
    M: 1e6,
    B: 1e9,
    T: 1e12,
  };

  for (const key in abbreviations) {
    if (absNum >= abbreviations[key] && absNum < abbreviations[key] * 1000) {
      return (num / abbreviations[key]).toFixed(1) + key;
    }
  }

  return num.toString();
};
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import "../../styles/allCoins.css";

const CallToAction = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#142128] py-[122px] relative overflow-hidden max-sm:py-[60px]">
      <div className="absolute right-[-20%] top-[-30%] w-6 h-6 side-light2 max-md:hidden " />
      <div className="absolute left-0 bottom-0 z-0">
        <img src="/icons/niza_vector.png" alt="" />
      </div>
      <div className="flex max-md:flex-col max-md:gap-20 max-sm:gap-15 justify-between container">
        <div className="flex flex-col z-[1] gap-8 max-sm:gap-6">
          <p className="text-white md:text-[54px] text-[42px] font-semibold relative max-sm:text-4xl">
            Start your{" "}
            <span className="text-custom-primary">Wealth Journey!</span>
            <span className="absolute right-0 top-[-20%]">
              <img src="/icons/nizaSmallVector.png" alt="" />
            </span>
          </p>
          <p className="text-dark-text md:text-[28px] text-[20px] max-sm:text-base">
            NIZA makes it easy to get started.
            <br /> Sign up today to{" "}
            <span className="text-custom-primary">
              Buy and Sell 170+
              <br /> Cryptocurrencies.
            </span>
          </p>
          <div className="flex">
            <Link to={process.env.REACT_APP_SIGN_UP}>
              <div className="p-4 border-custom-primary text-custom-primary rounded-lg w-fit font-semibold cursor-pointer bg-transparent 
                    border-[1px] transition-colors max-sm:p-3 max-sm:text-sm hover:bg-custom-primary hover:text-black">
                Get Started with NIZA
              </div>
            </Link>
          </div>
        </div>
        <div className="flex justify-center">
          <img src="icons/Coins_cta.svg" alt="" className="md:w-[520px] w-[80%]" />
        </div>
      </div>
    </div>
  );
};

export default CallToAction;

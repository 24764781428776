import React from 'react'
import { Link } from 'react-router-dom'
import CarouselBanking from '../banking/CarouselBanking'

const Banking = () => {
  return (
    <div 
        className='max-lg:w-11/12 lg:container mx-auto my-[124px] rounded-[18px] border-2 border-[#FFFFFF1A] bg-[#FFFFFF14] flex gap-20 
            h-[555px] items-center max-lg:gap-8 max-md:flex-col-reverse max-md:h-full max-md:gap-2 max-sm:gap-0
        '
        style={{fontFamily: 'Geist Variable'}}
    >
        <CarouselBanking />

        {/* Right Side */}
        <div className='flex w-full md:w-1/2 md:justify-center pr-20 max-lg:pr-4 max-md:px-8 max-md:py-6'>
            <div className='flex flex-col gap-10 max-md:gap-4'>
                <div className='flex flex-col gap-5 max-w-lg max-sm:gap-2'>
                    <div className='flex flex-col gap-3 max-sm:gap-2'>
                        <p className='text-slate-300 font-medium max-sm:text-xs'>Banking</p>
                        <p className='font-semibold text-4xl text-white max-sm:text-2xl'>Explore the forefront of modern finance</p>
                    </div>
                    <p className='text-gray-300 text-lg max-sm:text-sm'>From everyday banking to crypto credit card, enter the future of finance with a crypto bank account.</p>
                </div>
                <Link 
                    to={process.env.REACT_APP_SIGN_UP} 
                    className="text-black text-base bg-custom-primary hover:bg-custom-primary-dark transition-colors cursor-pointer rounded-md font-semibold py-3 px-4 w-48 
                    flex items-center justify-center  gap-2 max-sm:py-2 max-sm:px-2 max-sm:w-32 max-sm:text-xs"
                >
                    Get Started
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 10.1157C3.5 9.70151 3.83579 9.36572 4.25 9.36572L14.8879 9.36572L10.7302 5.40635C10.4316 5.11925 10.4223 4.64447 10.7094 4.34589C10.9965 4.04731 11.4713 4.038 11.7698 4.3251L17.2698 9.5751C17.4169 9.7165 17.5 9.91171 17.5 10.1157C17.5 10.3197 17.4169 10.5149 17.2698 10.6563L11.7698 15.9063C11.4713 16.1934 10.9965 16.1841 10.7094 15.8856C10.4223 15.587 10.4316 15.1122 10.7302 14.8251L14.8879 10.8657L4.25 10.8657C3.83579 10.8657 3.5 10.5299 3.5 10.1157Z" fill="black"/></svg>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Banking
import React from "react";

const AboutText = () => {
  return (
    <div className="bg-[#141B24]">
      <div className="container flex max-md:flex-col max-md:gap-14 justify-between items-center py-[160px] max-sm:py-24 max-sm:gap-10">
        <div className="md:w-1/2 w-full">
          <p className="text-[54px] text-white font-semibold mb-8 max-md:mb-4">
            About <span className="text-custom-primary">NIZA</span>
          </p>
          <p className="text-dark-text w-[80%]">
            <span className="text-white/80 font-[500]">
              Established in March 2021,
            </span>{" "}
            <span className="text-custom-primary font-[500]">NIZA</span> stands
            out for its cutting-edge high-performance exchange and mega
            transaction matching technology.
            <br />
            <br />
            As pioneers in financial and blockchain tech, our team has quickly
            grown to serve over{" "}
            <span className="text-white/80 font-[500]">
              100,000 users across 100+ countries.
            </span>
            <br />
            <br /> NIZA aims to be the preferred platform for both new traders
            and seasoned investors. With a global presence and regulation in
            renowned jurisdictions, we offer localized language support and
            prioritize user security through independently hosted servers across
            multiple countries.
          </p>
        </div>
        <div className="max-md:w-full">
          <img
            src="images/about_us.jpg"
            className="h-[743px] max-w-[631px] w-[631px] max-lg:w-[350px] max-lg:h-[470px] max-md:w-full max-md:max-w-full max-md:h-auto rounded-lg"
            height={700}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AboutText;

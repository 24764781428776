import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { getCurrentDimension } from "../../lib/utils/helper";
import { useContext } from "react";
import { OpeningSoonContext } from "../../context/openingSoonContext";
import { useTranslation } from "react-i18next";
import { tableCoins } from "../../lib/data/CoinsData";
import { formatNumber } from "../../lib/utils/helper";

const CryptoCoinTables = ({ pagination, coinData }) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { setWarning } = useContext(OpeningSoonContext);
  const { t } = useTranslation();

  const coinsToUseNow = coinData.slice(6 * pagination, 6 * pagination + 6);

  return (
    <div class="flex flex-col w-[98%] sm:w-[90%] lg:w-[1165px] m-auto">
      <div class="overflow-x-auto shadow-md rounded-lg">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden ">
            <table class="min-w-full">
              <thead class="bg-[#1d232a]">
                <tr>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-5 text-sm font-medium tracking-wider text-left text-secondary-string"
                  >
                    {t("home.cyptoTable.headers.coin")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-3 text-sm font-medium tracking-wider text-right text-secondary-string"
                  >
                    {t("home.cyptoTable.headers.price")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-3 text-sm font-medium tracking-wider text-center text-secondary-string min-w-[50px] max-md:hidden"
                  >
                    {t("home.cyptoTable.headers.chart")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-3 text-sm font-medium tracking-wider text-center text-secondary-string max-lg:hidden"
                  >
                    {t("home.cyptoTable.headers.change")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-3 text-sm font-medium tracking-wider text-right text-secondary-string max-lg:hidden"
                  >
                    {t("home.cyptoTable.headers.marketCap")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-3 text-sm font-medium tracking-wider text-right text-secondary-string max-lg:hidden"
                  >
                    {t("home.cyptoTable.headers.volume")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] px-3 text-sm font-medium tracking-wider text-right text-secondary-string max-lg:hidden"
                  >
                    {t("home.cyptoTable.headers.supply")}
                  </th>
                  <th
                    scope="col"
                    class="pt-[41px] pb-[19px] pl-3 pr-[31px] text-sm font-medium tracking-wider text-right text-secondary-string"
                  >
                    {t("home.cyptoTable.headers.trade")}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-[#1d232a]">
                {/* {coinData.split(pagination - 1, (pagination - 1) + 6)} */}
                {coinsToUseNow.map((coin, indx) => (
                  <tr
                    class={`${
                      tableCoins.length - 1 === indx ? "pb-[39px]" : ""
                    }`}
                  >
                    <td
                      class={`px-2 ${
                        tableCoins.length - 1 === indx
                          ? "pb-[39px] pt-2"
                          : "py-4"
                      } w-[220px]`}
                    >
                      <div className="flex items-center">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-[#5B616E] text-[16px] p-2 md:p-3"
                        />
                        <div className="flex gap-2 items-center">
                          <img
                            // src={`/tableicons/${coin.name}.png`}/
                            src={`https://app.niza.io/storage/icons/assets/60x60/${coin.asset_id}.png`}
                            alt=""
                            className="w-[32px] h-[32px]"
                          />
                          <div>
                            <p className="text-sm md:text-base font-[600] text-white">
                              {coin.name}
                            </p>
                            <p className="text-sm text-secondary-string">
                              {coin.asset_id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class={`${
                        coinsToUseNow.length - 1 === indx
                          ? "pb-[39px] pt-4"
                          : "py-4"
                      } px-3 text-[10px] sm:text-[13px] md:text-[15px] font-[400] text-white whitespace-nowrap text-right`}
                    >
                      {coin.currency} {coin.quote.price.toFixed(
                        6 - parseInt(coin.quote.price).toString().length
                      )}
                      {screenSize.width < 580 ? (
                        <p className="text-[11px] font-medium text-red-500">
                          1.52%
                        </p>
                      ) : null}
                    </td>
                    <td
                      class={`flex py-0 justify-end max-md:hidden h-[75px]`}
                    >
                      <div className="w-[120px] h-[35px] sparkline-chart" dangerouslySetInnerHTML={{__html: coin.graph}}></div>
                    </td>
                    <td
                      class={`${
                        coinsToUseNow.length - 1 === indx
                          ? "pb-[39px] pt-4"
                          : "py-4"
                      } px-3 text-sm font-medium text-red-500 whitespace-nowrap text-center max-lg:hidden`}
                    >
                      {coin.quote.percent_change_24h.toFixed(2)}%
                    </td>
                    <td
                      class={`${
                        coinsToUseNow.length - 1 === indx
                          ? "pb-[39px] pt-4"
                          : "py-4"
                      } px-3 text-sm font-medium text-right whitespace-nowrap text-white max-lg:hidden`}
                    >
                      {/* ALL 53.5T */}
                      {coin.currency} {formatNumber(coin.quote.market_cap)}
                      {/* {coin.currency} {parseInt(coin.quote.market_cap).toString().length < 6 ? coin.quote.market_cap.toFixed(
                        6 - parseInt(coin.quote.market_cap).toString().length
                      ) : coin.quote.market_cap} */}
                    </td>
                    <td
                      class={`${
                        coinsToUseNow.length - 1 === indx
                          ? "pb-[39px] pt-4"
                          : "py-4"
                      } px-3 text-sm font-medium text-right whitespace-nowrap text-white max-lg:hidden`}
                    >
                      {coin.currency} {formatNumber(coin.quote.volume_24h)}
                      {/* ALL 1.1T */}
                    </td>
                    <td
                      class={`${
                        coinsToUseNow.length - 1 === indx
                          ? "pb-[39px] pt-4"
                          : "py-4"
                      } px-3 text-sm font-medium text-right whitespace-nowrap text-white max-lg:hidden`}
                    >
                      {/* 19.4M */}
                      {formatNumber(coin.circulating_supply)}
                    </td>
                    <td
                      class={`${
                        coinsToUseNow.length - 1 === indx
                          ? "pb-[39px] pt-4"
                          : "py-4"
                      } pl-3 pr-[31px] lg:flex justify-end items-center whitespace-nowrap`}
                    >
                      <Link to={`https://trade.niza.io/trade/${coin.asset_id.toLowerCase()}-usdt`}>
                        <div className="bg-custom-primary hover:bg-custom-primary-dark transition-colors py-[11px] px-3 md:px-6 w-fit rounded-lg font-[600] float-right mb-2">
                          {t("home.cyptoTable.headers.trade")}
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoCoinTables;

export const amlPolicy = [
    {
      title: "GUIDELINES FOR ANTI-MONEY LAUNDERING, COMBATING FINANCING TERRORIST AND SANCTION MEASURES",
      description: `
        <p className="text-white">INTRODUCTION</p>
        <br/>
        <p className="text-white">DEFINITIONS</p>
        <br/>
        <p className="text-white">PRINCIPLES FOR STRUCTURE AND MANAGEMENT OF THE COMPANY</p>
        <ul className="pl-3 list-inside">
            <li>THE MANAGEMENT BOARD</li>
            <li>THE FIRST LINE OF DEFENSE – THE EMPLOYEES</li>
            <li>THE SECOND LINE OF DEFENSE – RISK MANAGEMENT AND COMPLIANCE, MLRO</li>
            <li>THE THIRD LINE OF DEFENSE – INTERNAL AUDIT</li>
            <li>THE COMPANY'S STRUCTURE CHART</li>
        </ul>
        <br/>
        <p className="text-white">PRINCIPLES OF CUSTOMER DUE DILIGENCE MEASURES IMPLEMENTATION</p>
        <ul className="pl-3 list-inside">
            <li>MAIN PRINCIPLES</li>
            <li>THE SERVICES PROVIDED</li>
            <li>THE LIST OF CUSTOMER DUE DILIGENCE MEASURES</li>
            <li>THE VERIFICATION OF INFORMATION USED FOR THE CUSTOMER'S IDENTIFICATION</li>
            <li>APPLICATION OF SIMPLIFIED DUE DILIGENCE MEASURES (LEVEL 1)</li>
            <li>APPLICATION OF SIMPLIFIED DUE DILIGENCE MEASURES (LEVEL 2)</li>
            <li>APPLICATION OF SIMPLIFIED DUE DILIGENCE MEASURES (LEVEL 3)</li>
        </ul>
        <br/>
        <p className="text-white">CUSTOMER DUE DILIGENCE MEASURES</p>
        <ul className="pl-3 list-inside">
            <li>IDENTIFICATION OF THE CUSTOMER – NATURAL PERSON</li>
            <li>IDENTIFICATION OF THE CUSTOMER – LEGAL ENTITY</li>
            <li>THE IDENTIFICATION OF THE CUSTOMER'S BENEFICIAL OWNER</li>
            <li>POLITICAL EXPOSED PERSON'S IDENTIFICATION</li>
            <li>IDENTIFICATION OF THE PURPOSE AND NATURE OF THE BUSINESS RELATIONSHIP OR A TRANSACTION</li>
            <li>MONITORING OF THE BUSINESS RELATIONSHIP</li>
        </ul>
        <br/>
        <p className="text-white">IMPLEMENTATION OF SANCTIONS</p>
        <ul className="pl-3 list-inside">
            <li>PROCEDURE FOR IDENTIFYING THE SUBJECT OF SANCTIONS AND A TRANSACTION VIOLATING SANCTIONS</li>
            <li>PROCEDURE IN THE EVENT OF IDENTIFICATION OF THE SUBJECT OF SANCTIONS OR A TRANSACTION VIOLATING</li>
            <li>SANCTIONS</li>
        </ul>
        <br/>
        <p className="text-white">REFUSAL TO THE TRANSACTION OR BUSINESS RELATIONSHIP AND THEIR TERMINATION</p>
        <br/>
        <p className="text-white">REPORTING OBLIGATION</p>
        <br/>
        <p className="text-white">TRAINING OBLIGATION</p>
        <br/>
        <p className="text-white">AVOIDING CONFLICT OF INTERESTS</p>
        <br/>
        <p className="text-white">INTERNAL CONTROL OF EXECUTION OF THE GUIDELINES</p>
        <br/>
        <p className="text-white">ANNEXES</p>
        <br/>
        <p className="text-white">VERSION CONTROL TABLE</p>
        <br/>
      `
    },
    {
      title: "INTRODUCTION",
      description: `
        <ol className="list-decimal list-inside">
            <li>The purpose of these Guidelines for Anti-Money Laundering (AML), Combating Terrorist 
            Financing (CFT) and Sanctions measures is to ensure that <b>UAB "Niza Global "</b>; (Company) has 
            internal guidelines to prevent the use of its business for money laundering and terrorist 
            financing and internal guidelines for implementation of international sanctions.</li>
        </ol>
        <br/>
        These Guidelines have been adopted to ensure that the Company complies with the rules and regulations set out in:
        <ul className="list-disc list-inside">
            <li>the Lithuania Money Laundering and Terrorist Financing Prevention Act;</li>
            <li>the Lithuanian International Sanctions Act (ISA);</li>
            <li>the Lithuania Financial Crime Investigation Services general guidelines regarding 
            measures against money laundering, terrorist financing and regarding implementation of 
            international sanctions;</li>
            <li>DIRECTIVE (EU) 2018/843 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 30 
            May 2018 amending Directive (EU) 2015/849 on the prevention of the use of the financial 
            system for the purposes of money laundering or terrorist financing, and amending 
            Directives 2009/138/EC and 2013/36/EU (AMLD5).</li>
        </ul>
        <br/>
        These Guidelines are the subject of a review by the Management Board at least annually. The 
        proposal for a review and the review of these Guidelines may be scheduled more often by the 
        decision of the Company's Money Laundering Reporting Officer (MLRO) or the Internal Control 
        Officer.
        <br/><br/>
        These Guidelines shall be accepted and approved by the resolution of the Management Board.
      `
    },
    {
        title: "DEFINITIONS",
        description: `
        The Company means legal entity with following data:
        <ul className="list-disc list-inside">
            <li>company name: <b>UAB "Niza Global "</b>;</li>
            <li>registration number: <b>306048866</b>;</li>
            <li>address: <b>Architektų g. 56-101, LT-04111 Vilnius, Lithuania</b>;</li>
            <li>email: <a href="mailto:compliance@niza.io" className="underline"><b>compliance@niza.io</b></a></li>
        </ul>
        <br/>
        <b>The Guidelines</b> – this document including all annexes as provided above. The Guidelines include 
        inter alia the Company's Internal control rules regarding the Guidelines and the Company's risk 
        assessment policy regarding risk based approach for ML/TF risks.
        <br/><br/>
        <b>The Money Laundering</b> (ML) means the concealment of the origins of illicit funds through their 
        introduction into the legal economic system and transactions that appear to be legitimate. There 
        are three recognized stages in the money laundering process:
        <ul className="list-disc list-inside">
            <li>placement, which involves placing the proceeds of crime into the financial system;</li>
            <li>layering, which involves converting the proceeds of crime into another form and creating 
            complex layers of financial transactions to disguise the audit trail and the source and 
            ownership of funds;</li>
            <li>integration, which involves placing the laundered proceeds back into the economy to 
            create the perception of legitimacy</li>
        </ul>
        <br/>
        <b>The Terrorist Financing</b> (TF) means the financing and supporting of an act of terrorism and 
        commissioning thereof as well as the financing and supporting of travel for the purpose of 
        terrorism in the meaning of applicable legislation.
        <br/><br/>
        <b>Sanctions</b> mean an essential tool of foreign policy aimed at supporting the maintenance or 
        restoration of peace, international security, democracy and the rule of law, following human 
        rights and international law or achieving other objectives of the United Nations Charter or the 
        common foreign and security Policy of the European Union. Sanctions include:
        <ul className="list-disc list-inside">
            <li>international sanctions which are imposed with regard to a state, territory, territorial 
            unit, regime, organization, association, group or person by a resolution of the United 
            Nations Security Council, a decision of the Council of the European Union or any other 
            legislation imposing obligations on Lithuania;</li>
            <li>sanctions of the Government of the Republic of Lithuania which is a tool of foreign policy 
            which may be imposed in addition to the objectives specified in previous clause in order 
            to protect the security or interests of Lithuania.</li>
        </ul>
        <br/>
        International sanctions may ban the entry of a subject of an international sanction in the state, 
        restrict international trade and international transactions, and impose other prohibitions or 
        obligations.
        <br/><br/>
        The subject of Sanctions is any natural or legal person, entity, or body, designated in the legal act 
        imposing or implementing Sanctions, with regard to which the Sanctions apply.
        <br/><br/>
        <b>The Customer</b> means a natural person or a legal entity which has the business relationship with 
        the Company or a natural person or legal entity with which the Company enters into the 
        occasional transaction.
        <br/><br/>
        <b>The Beneficial Owner</b> means a natural person who, taking advantage of their influence, makes a 
        transaction, act, action, operation or step or exercises control in another manner over a 
        transaction, act, action, operation or step or over another person and in whose interests or for 
        whose benefit or on whose account a transaction or act, action, operation or step is made. In the 
        case of a legal entity, the beneficial owner is a natural person whose direct or indirect holding, 
        or the sum of all direct and indirect holdings in the legal person, exceeds 25 percent, including 
        holdings in the form of shares or other forms of bearer. 
        <br/><br/>
        <b>MLRO</b> means Money Laundering Reporting Officer, who is appointed to the Company as a 
        compliance officer in the meaning of § 17 of MLTFPA.
        <br/><br/>
        <b>The Employee</b> means the Company´s employee, including persons which are involved in 
        application of these Guidelines in the Company.
        <br/><br/>
        <b>The Management Board</b> means management board of the Company.
        <br/><br/>
        <b>The Business Relationship</b> means a relationship that is established upon conclusion of a long-term contract by the Company in economic or professional activities for the purpose of provision 
        of a service or distribution thereof in another manner or that is not based on a long-term 
        contract, but whereby a certain duration could be reasonably expected at the time of 
        establishment of the contact and during which the Company repeatedly makes separate 
        transactions in the course of economic or professional activities while providing a service. 
        <br/><br/>
        <b>The Occasional Transaction</b> means the transaction performed by the Company in the course of 
        economic or professional activities for the purpose of provision of a service or sale of goods or 
        distribution thereof in another manner to the Customer outside the course of an established 
        business relationship. 
        <br/><br/>
        <b>Virtual currency</b> means a value represented in the digital form, which is digitally transferable, 
        preservable or tradable and which natural persons or legal persons accept as a payment 
        instrument, but that is not the legal tender of any country or funds for the purposes of Article 
        4(25) of Directive (EU) 2015/2366 of the European Parliament and of the Council on payment 
        services in the internal market, amending Directives 2002/65/EC, 2009/110/EC and 2013/36/EU 
        and Regulation (EU) No 1093/2010, and repealing Directive 2007/64/EC (OJ L 337, 23.12.2015, 
        pp 35–127) or a payment transaction for the purposes of points (k) and (l) of Article 3 of the same 
        Directive. 
        <br/><br/>
        <b>PEP</b> means a natural person who performs or has performed prominent public functions and 
        with regard to whom related risks remain.
        `
    },
    {
        title: "PRINCIPLES FOR STRUCTURE AND MANAGEMENT OF THE COMPANY",
        description: `
        The organizational structure of the Company must correspond to its size and the nature, scope, 
        and level of complexity of its activities and services provided, including the risk appetite and 
        related risks, and must be structured in accordance with the principle of three lines of defense.
        The organizational structure of the Company must correspond to the complete understanding of 
        potential risks and their management. The reporting and subordination chains of the Company 
        must be ensured in such a way that all employees know their place in the organizational structure 
        and know their work duties.
        <br/><br/>
        <p className="text-white">The Management Board</p>
        <br/>
        The Management Board is the carrier of the culture of compliance with the requirements of 
        money laundering and terrorist financing prevention, guaranteeing that the Management Board 
        members and employees of the Company operate in an environment where they are fully aware 
        of the requirements for the prevention of money laundering and terrorist financing and the 
        obligations associated with these requirements, and the relevant risk considerations are taken 
        into account to a suitable extent in the decision-making processes of the Company.
        <br/><br/>
        The Management Board members bear ultimate responsibility for the measures taken to prevent 
        the use of the Company’s services for money laundering or terrorist financing. They provide 
        oversight and are accountable for:
        <ul className="list-disc list-inside">
            <li>establishing and maintaining AML<sup>1</sup>
            processes, procedures, risk, and control processes;</li>
            <li>adopting these Guidelines and other internal guidelines and instructions;</li>
            <li>determining the Company’s Guidelines for AML measures;</li>
            <li>appointing an MLRO and ensuring that the MLRO has the powers, resources and expertise 
            required to perform their assignment;</li>
            <li>allocating sufficient resources to ensure the effective implementation of the Guidelines 
            and other related documents and to maintain the organization;</li>
            <li>ensuring all relevant employees complete annual AML training.</li>
        </ul>
        <br/>
        <div className="h-px w-[15%] bg-[#98a2b3] mb-2"></div>
        <sup>1</sup>For the purpose of simplifying these Guidelines, relation to "AML" includes also prevention of terrorism financing 
        and implementation of Sanctions
        <br/><br/>
        <p className="text-white">The first line of defense – the Employees</p>
        <br/>
        The first line of defense has the function of applying the due diligence measures upon business 
        relationship and occasional transactions and applying due diligence measures during the 
        business relationship. First line of defense comprises the structural units and employees of the 
        Company with whose activities risks are associated and that must identify and assess these risks, 
        their specific features and scope and that manage these risks by way of their ordinary activities, 
        primarily by way of application of due diligence measures. The risks arising from the activities of 
        and provision of services by the Company belong to the first line of defense. They are the 
        managers (owners) of these risks and responsible for them.
        <br/><br/>
        The employees of the Company must act with the foresight and competence expected from them 
        and according to the requirements set for their positions, proceeding from the interests and the 
        goals of the Company, and ensure that the country's financial system and economic space are 
        not used for money laundering and terrorist financing. The Company takes measures to assess 
        the suitability of the employees before they start working with the relevant training.
        <br/><br/>
        For the aforementioned reasons, the employees are required to:
        <ul className="list-disc list-inside">
            <li>adhere to all requirements outlined in the Guidelines and other related documents;</li>
            <li>collect required customer information in accordance with their function and accountabilities;</li>
            <li>report information, situations, activities, transactions or attempted transactions that are 
            unusual for any type of service or customer relationship, regardless of the amount, 
            whether or not the transaction was completed without delay to the MLRO;</li>
            <li>not inform or otherwise make customers aware if the customer or any other customers 
            are or may be the subject of a report or if a report has been or may be filed;</li>
            <li>complete the appropriate AML training required for the employee’s position.</li>
        </ul>
        <br/>
        <p className="text-white">The second line of defense – Risk Management and Compliance, MLRO</p>
        <br/>
        The second line of defense consists of the risk management and compliance functions. These 
        functions may also be performed by the same person or structural unit depending on the size of 
        the Company and the nature, scope and level of complexity of their activities and provided 
        services, incl. the risk appetite and risks arising from activities of the Company.
        <br/><br/>
        The objective of the <b>compliance</b> function is to guarantee that the Company complies with 
        effective legislation, guidelines and other documents and to assess the possible effect of any 
        changes in the legal or regulative environment on the activities of the Company and on the 
        compliance framework. The task of compliance is to help the first line of defense as the owners 
        of risk to define the places where risks manifest themselves (e.g., analysis of suspicious and 
        unusual transactions, for which compliance employees have the required professional skills, 
        personal qualities, etc.) and to help the first line of defense manage these risks efficiently. The 
        second line of defense does not engage in taking risks.
        <br/><br/>
        Risk policy is implemented, and the risk management framework is controlled by the risk 
        <b>management function</b>. The performer of the risk management function ensures that all risks are 
        identified, assessed, measured, monitored, and managed, and informs the appropriate units of 
        the Company about them. The performer of the risk management function for the purposes of 
        AML primarily performs the supervision over adherence to risk appetite, supervision over risk 
        tolerance, supervision over identification of changes in risks, performs the overview of associated 
        risks, and performs other duties related to risk management.
        <br/><br/>
        The Management Board have appointed an <b>MLRO</b> for performing the second line of defense 
        functions. This person is not operationally involved in the areas that the MLRO will be monitoring 
        and verifying and is thus independent in relation to these. The MLRO is accountable for the 
        following activities:
        <ul className="list-disc list-inside">
            <li>produce and when necessary, update the Company’s Guidelines;</li>
            <li>monitoring and verifying on an ongoing basis that the Company is fulfilling the 
            requirements prescribed by these Guidelines and related documents and according to 
            external laws and regulations</li>
            <li>provide the Company’s staff and Members of the Board with advice and support 
            regarding the rules relating to money laundering and terrorist financing</li>
            <li>inform and train the members of the Management Board and relevant persons about the 
            rules relating to money laundering and terrorist financing</li>
            <li>investigate and register sufficient data on received internal notifications and decide 
            whether the activity can be justified or whether it is suspicious;</li>
            <li>file the relevant reports (i.e. UARs, SARs, STRs, etc.) with the appropriate regulatory 
            authorities in accordance with local jurisdictional requirements;</li>
            <li>check and regularly assess whether the Company’s procedures and guidelines to prevent 
            the use of the business for money laundering or terrorist financing are fit for purpose and 
            effective;</li>
            <li>identify the incidents in accordance with the Company's Guidelines and take measures 
            regarding such incidents.</li>
        </ul>
        <br/>
        The MLRO reports to the Management Board quarterly. This report must be in writing and include at least the following items:
        <ul className="list-disc list-inside">
            <li>number of customers under all risk classifications</li>
            <li>number of hits of persons in relation to the Sanctions lists and applied measures;</li>
            <li>number of customers or customers’ representatives identified as PEPs or persons with a connection to a PEP;</li>
            <li>number of internal notifications on suspicious activity or transactions;</li>
            <li>number of the relevant reports (SARs, UARs, etc.) reported to the Lithuanian Financial 
            Crime Investigation Service (FCIS);</li>
            <li>number and content of a request for information from the FCIS within the framework of 
            an investigation;</li>
            <li>confirmation that the Company’s risk assessment for money laundering and terrorist 
            financing is up to date;</li>
            <li>confirmation that these Guidelines and other related documents are up to date;</li>
            <li>confirmation that the staffing in respect of AML measures is sufficient;</li>
            <li>all inadequacies (if any) identified by control function have been addressed;</li>
            <li>list of obligatory trainings which have been held for the staff in respect of AML measures.</li>
        </ul>
        <br/>
        <p className="text-white">The third line of defense – Internal audit</p>
        <br/>
        The third line of defense is comprised by the independent and effective internal audit function. 
        The internal audit function may be performed by one or several Employees, the Company's 
        structural unit with the relevant functions or by the third party, which provides the relevant 
        service to the Company.
        <br/><br/>
        The Employees, the Company's structural unit or third party, which performs the internal audit 
        function must have the required competency, tools, and access to the relevant information in all 
        structural units of the Company. The internal audit methods must comply with the size of the 
        Company, the nature, scope, and level of complexity of the activities and provided services, incl. 
        the risk appetite and risks arising from activities of the Company.
        <br/><br/>
        The decision to conduct an internal audit is made by a resolution of the Management Board. The Management Board must assess the need to conduct an internal audit at 
        least annually.
        <br/><br/>
        <p className="text-white">The Company's structure chart</p>
        <br/>
        <img className="mx-auto" src="images/companyChart.png" alt="Company Chart"/>
        `
    },
    {
        title: "PRINCIPLES OF CUSTOMER DUE DILIGENCE MEASURES IMPLEMENTATION",
        description: `
        Customer due diligence (CDD) measures are required for verifying the identity of a new or 
        existing Customer as a well-performing risk-based ongoing monitoring of the business 
        relationship with the Customer. The CDD measures consist of 3 levels, including the simplified 
        and enhanced due diligence measures, as specified below.
        <br/><br/>
        <p className="text-white">Main Principles</p>
        The CDD measures are taken and performed to the extent necessary considering the customer’s risk profile and other circumstances in the following cases:
        <ul className="list-disc list-inside">
            <li>upon establishment of the business relationship and ongoing monitoring of the business 
            relationship;</li>
            <li>upon executing or mediating of occasional transaction(s) outside the business 
            relationship where the value of the transaction(s) exceeds 15 000 euros (or an equal 
            amount in another assets) over a period of up to one year;</li>
            <li>upon verification of information gathered while applying due diligence measures or in the 
            case of doubts as to the sufficiency or truthfulness of the documents or data gathered 
            earlier while updating the relevant data;</li>
            <li>upon suspicion of money laundering or terrorist financing, regardless of any derogations, 
            exceptions or limits provided for in these Guidelines and applicable legislation.</li>
        </ul>
        <br/>
        The Company does not establish or maintain the business relationship and not perform transaction if:
        <ul className="list-disc list-inside">
            <li>the Company is not able to take and perform any of required CDD measures;</li>
            <li>the Company has any suspicions that the Company’s services or transaction will be used 
            for money laundering or terrorist financing;</li>
            <li>the risk level of the Customer or of the transaction does not comply with the Company's 
            risk appetite</li>
        </ul>
        <br/>
        In the case of receiving information in foreign languages within the framework of CDD 
        implementation, the Company may request to demand translation of the documents to another 
        language appliable for the Company. The use of translations should be avoided in situations when 
        the original documents are prepared in a language appliable for the Company.
        <br/><br/>
        Achieving CDD is a process that starts with the CDD measures implementation. When that 
        process is complete, the Customer assigns documented individual risk level which shall form the 
        basis for follow-up measures, and which is followed up and updated when necessary. 
        The Company has applied CDD measures adequately if the Company has the inner conviction 
        that they have complied with the obligation to apply due diligence measures. The principle of 
        reasonability is observed in the consideration of inner conviction. This means that the Company 
        must, upon the application of CDD measures, acquire the knowledge, understanding and 
        assertation that they have collected enough information about the Customer, the Customer’s 
        activities, the purpose of the business relationship and of the transactions carried out within the 
        scope of the business relationship, the origin of the funds, etc., so that they understand the 
        Customer and the Customer’s (business) activities, thereby taking into account the Customer’s 
        risk level, the risk associated with the business relationship and the nature of such relationship. 
        Such a level of assertation must make it possible to identify complicated, high-value and unusual 
        transactions and transaction patterns that have no reasonable or obvious economic or legitimate 
        purpose or are uncharacteristic of the specific features of the business in question.
        <br/><br/>
        <p className="text-white">The Services Provided</p>
        <br/>
        The Company's main economic activity is the virtual currency services. For this reason, the 
        Company offers to their Customers the following transaction types:
        <ul className="list-disc list-inside">
            <li>providing virtual currency wallet service, which allows to the Customer open virtual 
            currency wallet on their name (hereinafter – the Customer´s Wallet) and make 
            transactions with this wallet: to send virtual currency deposited to this wallet to other 
            wallet(s) and to receive virtual currency sent from another wallets;</li>
            <li>providing a virtual currency exchange service, which allows to buy (by using credit card, 
                like VISA) virtual currency from the Company and deposit it to the Customer´s Wallet;</li>
            <li>providing a virtual currency exchange service, which allows to sell virtual currency from 
            the Customer´s Wallet to the Company and send the received amount of money to the 
            Customer's credit card or to the Customer's bank account;</li>
            <li>providing virtual currency exchange service, which allows exchange virtual currency 
            deposited at the Customer´s Wallet against other virtual currency with depositing latest 
            to the relevant wallet of the same Customer.</li>
        </ul>
        <br/>
        The Company provides aforementioned services only for the following virtual currencies:
        <ul className="list-disc list-inside">
            <li>Bitcoin – BTC;</li>
            <li>Ethereum – ETH;</li>
            <li>Tether – USDT.</li>
        </ul>
        <br/>
        <p className="text-white">The Verification of Information used for the Customer's Identification</p>
        <br/>
        Verification of the information for the Customer's identification means using data from a reliable 
        and independent source to confirm that the data is true and correct, also confirming, if necessary, 
        that the data directly related to the Customer is true and correct. This inter alia means that the 
        purpose of verification of information is to obtain reassurance that the Customer who wants to 
        establish the business relationship is the person they claim to be.
        <br/><br/>
        The face-to-face identification (personal meeting with the Customer) or identification using 
        information technology means (using of high-confidence e-identification system) is deemed to 
        be the reliable and independent verification of the information obtained in the course of 
        identification.
        <br/><br/>
        In situations not specified in abovementioned identification methods the reliable and 
        independent source (must exist cumulatively) is verification of the information obtained in the 
        course of identification:
        <ul className="list-disc list-inside">
            <li>which originates from two different sources;</li>
            <li>where the Customer sends a photo taken of the facial image of the Customer and the 
            identity document used for identification immediately before the data is sent and the 
            Company makes sure that the photo was taken recently;</li>
            <li>which has been issued by (identity documents) or received from a third party or a place 
            that has no interest in or connections with the Customer or the Company, i.e. that is 
            neutral (e.g. information obtained from the Internet is not such information, as it often 
            originates from the Customer themselves or its reliability and independence cannot be 
            verified);</li>
            <li>the reliability and independence of which can be determined without objective obstacles 
            and reliability and independence are also understandable to a third party not involved in 
            the business relationship; and</li>
            <li>the data included in which or obtained via which are up to date and relevant and the 
            Company can obtain reassurance about this (and reassurance can in certain cases also be 
            obtained on the basis of the two previous clauses).</li>
        </ul>
        <br/>
        <p className="text-white">Application of Simplified Due Diligence Measures (level 1)</p>
        <br/>
        Simplified due diligence (SDD) is applied where the Customer’s risk profile indicates low risk and 
        where, in accordance with the risk assessment produced by the Company, it has been identified 
        that in such circumstances the risk of money laundering or terrorist financing is lower than usual. 
        Regarding the Company services provided and the Company's risk assessment, the Company will 
        not apply SDD measures to their Customers. Thus, to all Customers at least standard due 
        diligence measures shall be applied as specified below.
        <br/><br/>
        <p className="text-white">Application of Standard Due Diligence Measures (level 2)</p>
        <br/>
        Standard due diligence measures are applied to all Customers if CDD measures must be applied 
        in accordance with the Guidelines. The following standard due diligence measures should be 
        applied:
        <ul className="list-disc list-inside">
            <li>identification of the Customer and verification of the submitted information based on 
            information obtained from a reliable and independent source, including using means of 
            electronic identification and of trust services for electronic transactions; </li>
            <li>identification and verification of a representative of the Customer and their right of 
            representation;</li>
            <li>identification of the beneficial owner and, for the purpose of verifying their identity, 
            taking measures to the extent that allows the Company to make certain that it knows 
            who the beneficial owner is, and understands the ownership and control structure of the 
            Customer;</li>
            <li>understanding of business relationships, transaction or operation and, where relevant, 
            gathering information thereon;</li>
            <li>gathering information on whether the Customer is PEP, their family member or a person 
            known to be close associate;</li>
            <li>monitoring of the business relationship.</li>
        </ul>
        <br/>
        The CDD measures specified above must be applied before establishing the business relationship. 
        The exact instruction for application standard due diligence measures is provided in the 
        Guidelines.
        <br/><br/>
        <p className="text-white">Application of Standard Due Diligence Measures (level 3)</p>
        <br/>
        In addition to CDD, the Company applies enhanced due diligence (EDD) measures in order to 
        manage and mitigate an established risk of money laundering and terrorist financing that is 
        higher than usual.
        <br/><br/>
        The Company always applies EDD measures, when:
        <ul className="list-disc list-inside">
            <li>the Customer's risk profile indicates high risk level;</li>
            <li>upon identification of the Customer or verification of submitted information, there are 
            doubts as to the truthfulness of the submitted data, authenticity of the documents or 
            identification of the beneficial owner;</li>
            <li>the Customer is a PEP;</li>
            <li>the Customer is from a high-risk third country or their place of residence or seat or the 
            seat of the payment service provider of the payee is in a high-risk third country;</li>
            <li>the customer is from such country or territory or their place of residence or seat or the 
            seat of the payment service provider of the payee is in a country or territory that, 
            according to credible sources such as mutual evaluations, reports or published follow-up 
            reports, has not established effective AML/CFT systems that are in accordance with the 
            recommendations of the Financial Action Task Force, or that is considered a low tax rate 
            territory;</li>
            <li>the Customer's economic or professional activity, field or factors indicate the risk of 
            money laundering or terrorist financing, which is higher than usual;</li>
            <li>the Customer's total amount of incoming or outgoing payments related to business 
            relationship exceeds the limits, established by the Company.</li>
        </ul>
        <br/>
        Prior to applying EDD measures, the Company's employee ensures that the business relationship 
        or transaction has a high risk and that a high-risk rate can be attributed to such business 
        relationship or transaction. Above all, the employee assesses prior to applying the EDD measures 
        whether the features described above are present and applies them as independent grounds 
        (that is, each of the factors identified allows application of EDD measures with respect to the 
        Customer).
        <br/><br/>
        When applying EDD measures, the following additional and relevant due diligence measures shall be followed:
        <ul className="list-disc list-inside">
            <li>verification of information additionally submitted upon identification of the Customer 
            based on additional documents, data or information originating from a credible and 
            independent source;</li>
            <li>gathering additional information on the purpose and nature of the business relationship 
            or transaction and verifying the submitted information based on additional documents, 
            data or information that originates from a reliable and independent source;<sup>.2</sup</li>
            <li>gathering additional information and documents regarding the actual execution of 
            transactions made in the business relationship in order to rule out the ostensibility of the 
            transactions;</li>
            <li>gathering additional information and documents for the purpose of identifying the source 
            and origin of the funds used in a transaction made in the business relationship in order to 
            rule out the ostensibility of the transactions;</li>
            <li>the making of the first payment related to a transaction via an account that has been 
            opened in the name of the Customer participating in the transaction in a credit institution 
            registered or having its place of business in a contracting state of the European Economic 
            Area or in a country where requirements equal to those of Directive (EU) 2015/849 of the 
            European Parliament and of the Council are in force;</li>
            <li>the application of due diligence measures regarding the Customer or their representative 
            while being at the same place as the Customer or their representative;</li>
            <li>gathering additional information about the customer and its beneficial owner, including 
            identification of all owners of the Customer, incl. those whose shareholding is below 
            25%;<sup>.2</sup></li>
            <li>gathering information on the origin of the funds and wealth of the customer and its 
            beneficial owner;<sup>.2,3</sup></li>
            <li>improving the monitoring of the business relationship by increasing the number and 
            frequency of the applied control measures and by choosing transaction indicators or 
            transaction patterns that are additionally verified;<sup>.2,3</sup></li>
            <li>an analysis of the Customer’s digital impression on the Internet is made (Adverse Media Search);</li>
            <li>obtaining the approval of the Management Board for transactions with new and existing 
            Customers;<sup>.2,3</sup></li>
        </ul>
        <br/>
        <div className="h-px w-[15%] bg-[#98a2b3] mb-2"></div>
        <sup>2</sup>this measure shall be always applied, where the Company comes in contact with the high-risk third country via the Customer or transaction
        <br/>
        <sup>3</sup>this measure shall be always applied, where the Customer is a PEP
        <br/><br/>
        The amount of EDD measures and this scope shall be determined by the employee, who is 
        applying such measures. The employee shall notify about EDD measures applied within 2 working 
        days after the start of applying of the EDD measures by sending relevant notification to the 
        MLRO.
        <br/><br/>
        In the case of application of EDD measures, the Company monitors the business relationship more often than usual and reassesses the Customer’s risk profile no later 
        than every six months.
        `
    },
    {
        title: "CUSTOMER DUE DILIGENCE MEASURES",
        description: `
        <p className="text-white">Identification of the Customer – natural person</p>
        <br/>
        The Company identifies the Customer who is a natural person and, where relevant, their representative and retains the following data on the Customer:
        <ul className="list-disc list-inside">
            <li>first and last name(s);</li>
            <li>personal identification code;</li>
            <li>date of birth;</li>
            <li>citizenship;</li>
            <li>the place of residence or seat;</li>
            <li>economic or professional activity.</li>
        </ul>
        <br/>
        The following valid identity documents may be used as the basis for the identification of a natural person:
        <ul className="list-disc list-inside">
            <li>an identity card;</li>
            <li>a residence permit card;</li>
            <li>an alien’s passport;</li>
            <li>a driving permit issued in a foreign country if the document includes user’s name, 
            photograph or facial image, signature or image of a signature and date of birth or personal 
            identification code;</li>
            <li>a travel document issued in a foreign country (passport).</li>
        </ul>
        <br/>
        During the verification of the data obtained during the identification of the Customer and 
        representative from a credible and independent source, the <b>first credible and independent 
        sources</b> is always:
        <ul className="list-disc list-inside">
            <li>an identity document specified above or a colored and legible copy/image of this document.</li>
        </ul>
        <br/>
        The following information obtained may be the <b>second reliable and independent source</b>:
        <ul className="list-disc list-inside">
            <li>the Customer´s photo (selfie) with identity document;</li>
            <li>the utility bill (e. g. invoice, issued and paid once a month from utilities, including electric, 
                natural gas, water, waste, etc.)</li>
            <li>information for checking the data<sup>4</sup>
            directly associated with the person (e. g. place of work, 
            residence or study).</li>
        </ul>
        <br/>
        <div className="h-px w-[15%] bg-[#98a2b3] mb-2"></div>
        <sup>4</sup> For example, the fact that the data collected in the course of identification are true and correct can be proven by 
        a confirmation in a format that can be reproduced in writing received from a reliable and independent source, 
        which states that the person lives (e. g. consumes utilities there, i.e. proves that the person lives at that place), 
        studies or works (profession or area of activity) at the place they declared, etc.
        <br/>
        The Customer who is natural person can´t use representative in the course of business relationship or occasional transaction with the Company.
        <br/><br/>
        <p className="text-white">Identification of the Customer – legal entity</p>
        <br/>
        The Company identifies the Customer which is a legal entity and their representative and retains the following data on the Customer:
        <ul className="list-disc list-inside">
            <li>business name or name (with the legal form);</li>
            <li>registry code or registration number and date of registration;</li>
            <li>name of the director(s) or names of member(s) of the management board or member(s) 
            of another equivalent body, and their authorities in representing the Customer;</li>
            <li>location of the Customer, whereby the theory of the country of establishment must be proceeded from;</li>
            <li>place of business;</li>
            <li>the details of the telecommunications.</li>
        </ul>
        <br/>
        The following documents issued by a competent authority or body not earlier than six months before their use may be implied for identification of the Customer:
        <ul className="list-disc list-inside">
            <li>registry card of the relevant register; or</li>
            <li>registration certificate of the relevant register; or</li>
            <li>a document equivalent with an aforementioned documents or relevant documents of 
            establishment of the Customer.</li>
        </ul>
        <br/>
        The Company verifies the correctness of the Customer's data specified above, using information 
        originating from a credible and independent source for that purpose. Where the Company has 
        access to the commercial register, register of non-profit associations and foundations or the data 
        of the relevant registers of a foreign country, the submission of the documents specified about 
        does not need to be demanded from the Customer.
        <br/><br/>
        The identity of legal entity and the right of legal entity's representation can be verified on the 
        basis of a document specified above, which has been authenticated by a notary or certified by a 
        notary or officially, or on the basis of other information originating from a credible and 
        independent source, including means of electronic identification and trust services for electronic 
        transactions, thereby using at least two different sources for verification of data in such an event. 
        <br/><br/>
        During the verification of the data from a credible and independent source obtained during the 
        identification of the legal entity, the source considered credible and independent when the Company:
        <ul className="list-disc list-inside">
            <li>sees the original of the document specified above;</li>
            <li>sees a copy of the document specified above that has been authenticated by a notary, 
            certified by a notary or officially certified; or</li>
            <li>has access to the data in the commercial register, register of non-profit associations and 
            foundations or the relevant registers of foreign countries via a computer network.</li>
        </ul>
        <br/>
        <b>Two different sources</b> during the identification of a legal entity means that the data medium, 
        place or measure of obtaining information must be different (i.e. it cannot be the same data medium).
        <br/><br/>
        The representative of the legal entity shall be identified as the Customer, who is a natural person 
        in accordance with these Guidelines. The Company must also identify and verify the nature and 
        scope of the right of representation. The name, date of issue and name of issuer of the document 
        that serves as a basis for the right of representation must be ascertained and retained, except in 
        case, when the right of representation was verified using information originating from the 
        relevant register (e. g. the commercial register, register of non-profit associations and 
        foundations or the relevant register of a foreign country).
        <br/><br/>
        The Company must observe the conditions of the right of representation granted to the legal 
        entity's representatives and provide services only within the scope of the right of representation.
        <br/><br/>
        <p className="text-white">The identification of the Customer's beneficial owner</p>
        <br/>
        The Company shall request from the Customer information to the Customer's beneficial owner 
        (e. g. providing the Customer with an opportunity to specify their beneficial owner in KYC 
        questionnaire).
        <br/><br/>
        The Company doesn´t establish the business relationship, if the Customer, who is a natural 
        person has beneficial owner who is not the same person as the Customer.
        <br/><br/>
        The beneficial owner of a legal entity is identified in stages where the obliged entity proceeds to 
        each subsequent stage if the beneficial owner of the legal entity cannot be determined in the 
        case of the previous stage. The stages are as follows:
        <ul className="list-disc list-inside">
            <li>is it possible to identify, in respect of the Customer that is a legal entity or a person 
            participating in the transaction, the natural person or persons who actually ultimately 
            control the legal entity or exercise influence or control over it in any other manner, 
            irrespective of the size of the shares, voting rights or ownership rights or its direct or 
            indirect nature;</li>
            <li>whether the Customer that is a legal entity or the person participating in the transaction 
            has a natural person or persons who own or control the legal entity via direct<sup>5</sup>
             or indirect<sup>6</sup>
            shareholding. Family connections and contractual connections must also be taken into 
            account here;</li>
            <li>who is the natural person in senior management<sup>7</sup>
            , who must be defined as the beneficial 
            owner, as a result of execution of the previous two stages have not made it possible for 
            the obliged entity to identify the beneficial owner.</li>
        </ul>
        <br/>
        <div className="h-px w-[15%] bg-[#98a2b3] mb-2"></div>
        <sup>5</sup><b>direct ownership</b> is a manner of exercising control whereby the natural person owns a 25 percent shareholding 
        plus one share or an ownership right of over 25 percent in the company 
        <br/>
        <sup>6</sup><b>indirect ownership</b> is a manner of exercising control whereby a 25 percent shareholding plus one share or an 
        ownership right of over 25 percent in the company is owned by a company that is controlled by a natural person or 
        several companies that are controlled by the same natural person.
        <br/>
        <sup>7</sup>a <b>member of senior management</b> is a person who makes the strategic decisions that fundamentally affect 
        business activities and/or practices and/or the company general (business) trends or in its absence 15.8.2. carries out 
        everyday or regular management functions of the company within the scope of executive power (e.g. chief executive 
        officer (CEO), chief financial officer (CFO), director or president, etc.).
        <br/><br/>
        If the documents used for the legal entity´s identification or the other submitted documents do 
        not indicate directly who the beneficial owner of the legal entity is, the relevant data (incl. data 
        about being a member of a group and the ownership and management structure of the group) 
        are registered on the basis of the statement of the representative of the legal entity or the 
        document written by hand by the representative of the legal entity.
        <br/><br/>
        The Company shall apply reasonable measures to verify the accuracy of the information 
        established on the basis of statements or a handwritten document (e.g. by making inquiries in 
        the relevant registers), requiring the submission of the legal entity's annual report or other 
        relevant document. If the Company has doubts about the accuracy or completeness of the 
        relevant information, the Company shall verify the information provided from publicly available 
        sources and, if necessary, request additional information from the Customer.
        <br/><br/>
        Where the Company establishes the business relationship with the Customer whose information 
        on beneficial owners must, in accordance with the statutes of a Member State of the European 
        Union, be submitted to the state or be registered there, the Company shall obtain a relevant 
        registration certificate or registry extract upon identification of the Customer´s beneficial owner.
        <br/><br/>
        The beneficial owner does not have to be identified in the case of the Customer listed on a 
        regulated market that is subject to disclosure requirements consistent with European Union law 
        or subject to equivalent international standards which ensure adequate transparency of 
        ownership information.
        <br/><br/>
        <p className="text-white">Political Exposed Person's identification</p>
        <br/>
        The Company shall take measures to ascertain whether the Customer, the beneficial owner of 
        the Customer or the representative of this Customer is a PEP, their family member<sup>8</sup>
        or close 
        associate<sup>9</sup>
        , or if the Customer has become such a person.
        <br/><br/>
        <div className="h-px w-[15%] bg-[#98a2b3] mb-2"></div>
        <sup>8</sup><b>family member</b> means the spouse, or a person considered to be equivalent to a spouse, of a PEP; a child and their 
        spouse, or a person considered to be equivalent to a spouse, of a PEP; a parent of a PEP 
        <br/>
        <sup>9</sup><b>close associate</b> means a natural person who is known to be the beneficial owner or to have joint beneficial 
        ownership of a legal person or a legal arrangement, or any other close business relations, with a politically exposed 
        person; and a natural person who has sole beneficial ownership of a legal entity or legal arrangement which is 
        known to have been set up for the de facto benefit of a politically exposed person         
        <br/>
        The Company shall request from the Customer information to identify if the Customer is a PEP, 
        their family member or close associate (e. g. providing the Customer with an opportunity to 
        specify the relevant information in KYC questionnaire). 
        <br/><br/>
        The Company shall verify the data received from the Customer by making inquiries in relevant 
        databases or public databases or making inquiries or verifying data on the websites of the 
        relevant supervisory authorities or institutions of the country in which the Customer has place of 
        residence or seat. PEP must be additionally verified using Google and the local search engine of 
        the Customer's country of origin, if any, by entering the customer's name in both Latin and local 
        alphabet with the customer's date of birth.
        <br/><br/>
        At least the following persons are deemed to be PEPs:
        <ul className="list-disc list-inside">
            <li>head of State or head of government;</li>
            <li>minister, deputy minister or assistant minister;</li>
            <li>member of a legislative body;</li>
            <li>member of a governing body of a political party;</li>
            <li>judge of the highest court of a country;</li>
            <li>auditor general or a member of the supervisory board or executive board of a central bank;</li>
            <li>the Chancellor of Justice;</li>
            <li>ambassador, envoy or chargé d’affaires;</li>
            <li>high-ranking officer in the armed forces;</li>
            <li>member of an administrative, management or supervisory body of a state-owned 
            enterprise;</li>
            <li>director, deputy director and member of a management body of an international organisation;</li>
            <li>person in list of Lithuania positions whose holders are considered politically exposed persons is established by a regulation of the minister responsible 
            for the field;</li>
            <li>person in list of positions, which is established by international organisation accredited in Lithuania;</li>
            <li>a person who, as per list published by the European Commission, is considered a 
            performer of prominent public functions by a Member State of the European Union, the 
            European Commission or an international organisation accredited on the territory of the 
            European Union is deemed a politically exposed person.</li>
        </ul>
        <br/>
        Middle-ranking or more junior officials are not considered PEPs.
        <br/><br/>
        The Company shall identify close associates and family members of PEPs only if their connection 
        with PEP is known to the public or if the Company has reason to believe that such a connection 
        exists.
        <br/><br/>
        Where the Customer who is a PEP no longer performs important public functions placed upon 
        them, the Company shall at least within 12 months take into account the risks that remain related 
        to the Customer and apply relevant and risk sensitivity-based measures as long as it is certain 
        that the risks characteristic of PEPs no longer exist in the case of the Customer.
        <br/><br/>
        <p className="text-white">Identification of the purpose and nature of the business relationship or a transaction</p>
        <br/>
        The Company shall understand the purpose and nature of the establishing business relationship 
        or performing transaction. Regarding the services provided, the Company shall request from the 
        Customer at least the following information for understanding the purpose and nature of the 
        business relationship or transaction:
        <ul className="list-disc list-inside">
            <li>the estimated transactions turnover with the Company per calendar year;</li>
            <li>the estimated source of funds used in the business relationship or transaction;</li>
            <li>if the business relationship or transaction is related to the Customer´s performance of 
            economic or professional activities.</li>
        </ul>
        <br/>
        The Company shall apply additional measures and collect additional information to identify the 
        purpose and nature of the business relationship or an occasional transaction in cases where:
        <ul className="list-disc list-inside">
            <li>there is a situation that refers to high value or is unusual and/or</li>
            <li>where the risk and/or risk profile associated with the Customer and the nature of the 
            business relationship gives reason for the performance of additional actions in order to 
            be able to appropriately monitor to business relationship later.</li>
        </ul>
        <br/>
        If the Customer is a legal entity, in addition to aforementioned the Company shall identify the Customer´s:
        <ul className="list-disc list-inside">
            <li><b>area of activity</b>, where the Company shall understand what the Customer deals with and 
            intends to deal with in the course of the business relationship and how this corresponds 
            to the purpose and nature of the business relationship in general and whether it is 
            reasonable, understandable and plausible;</li>
            <li><b>payment practices</b>, including the countries from which payments are received and to 
            which payments are made, the expected duration of the business relationship, the extent 
            and channels of cash and cryptocurrency use, payment channels (branch, Internet bank, 
            card payments), etc.;</li>
            <li><b>main business partners</b>, where the Company must identify who are the Customer’s main 
            partners with which transactions will be concluded in the declared area of activity and 
            with the declared activity volumes. </li>
        </ul>
        <br/>
        The area of activity, payment practices and main business partners must fit into the experience 
        profile of the Customer’s representative (or key persons) and/or the beneficial owner. Thus, the 
        Company has to identify where the representative’s and/or beneficial owner’s capacity, 
        capability, skills and knowledge (experience in general) comes from in order to operate in this 
        area of activity, with these business volumes and with these main business partners.
        <br/><br/>
        <p className="text-white">Monitoring of the business relationship </p>
        <br/>
        The Company shall monitor established business relationships where the following ongoing due 
        diligence (ODD) measures are implemented:
        <ul className="list-disc list-inside">
            <li>ensuring that the documents, data, or information collected in the course of the 
            application of due diligence measures are updated regularly and in the case of trigger 
            events, i.e., primarily the data concerning the Customer, their representative (incl. the 
            right of representation) and beneficial owner as well as the purpose and nature of the 
            business relationship;</li>
            <li>ongoing monitoring of the business relationship, which covers transactions carried out in 
            the business relationship to ensure that the transactions correspond to the Company’s 
            knowledge of the Customer, their activities and risk profile;</li>
            <li>identification of the source and origin of funds used in the transaction(s).</li>
        </ul>
        <br/>
        The Company shall regularly check and update the documents, data and information collected 
        within the course of the implementation of CDD measures. The regularity of the checks must be 
        based on the risk profile of the Customer and the checks must take place at least:
        <ul className="list-disc list-inside">
            <li>once semi-annually for the high-risk profile Customer;</li>
            <li>once annually for the medium-risk profile Customer;</li>
            <li>once every two years for the low-risk profile Customer.</li>
        </ul>
        <br/>
        The collected documents, data and information must also be checked if an event has occurred 
        which indicates the need to update the collected documents, data and information.
        <br/><br/>
        In the course of the ongoing monitoring of the business relationship, the Company shall monitor 
        the transactions concluded during the business relationship in such a manner that the latter can 
        determine whether the transactions to be concluded correspond to the information previously 
        known about the Customer (i.e., what the customer declared upon the establishment of the 
        business relationship or what has become known in the course of the business relationship).
        <br/><br/>
        The Company shall also monitor the business relationship to ascertain the customer’s activities 
        or facts that indicate criminal activities, money laundering or terrorist financing or the relation 
        of which to money laundering or terrorist financing is probable, incl. complicated, high-value and 
        unusual transactions and transaction patterns that do not have any reasonable or obvious 
        economic or legitimate purpose or that are uncharacteristic of the specific features of the 
        business in question. In the course of the business relationship, the Company shall constantly 
        assess the changes in the Customer’s activities and assess whether these changes may increase 
        the risk level associated with the Customer and the business relationship, giving rise to the need 
        to apply EDD measures.
        <br/><br/>
        In the course of the ongoing monitoring of the business relationship, the Company applies the following measures:
        <ul className="list-disc list-inside">
            <li>screening i.e., monitoring transactions in real-time;</li>
            <li>monitoring i.e., analyzing transactions later.</li>
        </ul>
        <br/>
        The objective of <b>screening</b> is to identify:
        <ul className="list-disc list-inside">
            <li>suspicious and unusual transactions and transaction patterns;</li>
            <li>transactions exceeding the provided thresholds;</li>
            <li>politically exposed persons and circumstances regarding international sanctions.</li>
        </ul>
        <br/>
        The screening of the transactions is performed automatically and includes the following measures:
        <ul className="list-disc list-inside">
            <li>established thresholds for the Customer´s transactions, depending on the Customer´s risk 
            profile and the estimated transactions turnover declared by the Customer;</li>
            <li>the scoring of virtual currency wallets where the virtual currency shall be sent in 
            accordance with the Customer´s order;</li>
            <li>the scoring of virtual currency wallets from which the virtual currency is received.</li>
        </ul>
        <br/>
        If the Customer gives order for transaction which exceeds the threshold established or for 
        transaction to the virtual currency wallet with high risk score (e.g. wallets related to fraud, crime, 
        etc.), the transaction shall be manually approved by the Employee, which shall access before the 
        approval a necessity to apply any additional CDD measures (e. g. applying EDD measures, asking 
        source and origin of funds or asking additional information regarding the transaction).
        <br/><br/>
        When <b>monitoring transactions</b> the Employee shall assess transaction with a view to detect activities and transactions that:
        <ul className="list-disc list-inside">
            <li>deviate from what there is reason to expect based on the CDD measures performed, the 
            services provided, the information provided by the customer and other circumstances 
            (e.g. exceeding estimated transactions turnover, virtual currency sending each time to 
            new virtual currency wallet, volume of transactions exceeding limit);</li>
            <li>without deviating according to previous clause, may be assumed to be part of a money 
            laundering or terrorist financing;</li>
            <li>may affect the Customer´s risk profile score.</li>
        </ul>
        <br/>
        In case, when aforementioned fact is detected, the employee shall notify MLRO and postpone 
        any transaction of the Customer until MLRO´s decision regarding this.
        <br/><br/>
        In addition to aforementioned, the MLRO shall review the Company´s transaction regularly (at 
        least once per week) to ensure that:
        <ul className="list-disc list-inside">
            <li>the Company´s employees properly performed the aforementioned obligations;</li>
            <li>there are no transactions and transaction patterns that are complicated, high-value and 
            unusual and that have no reasonable or obvious economic or legitimate purpose or are 
            uncharacteristic of the specific features.</li>
        </ul>
        <br/>
        The Company identifies the source<sup>10</sup> and origin<sup>11</sup> of the funds used in transaction(s) if necessary.
        <br/><br/>
        <div className="h-px w-[15%] bg-[#98a2b3] mb-2"></div>
        <sup>10</sup><b>the source of the funds</b> used in the transaction is reason, explanation and basis (legal relationship and its content) 
        why the funds were transferred
        <br/>
        <sup>11</sup><b>the origin of the funds</b> used in the transaction is the activity by which the funds were earned or received 
        <br/><br/>
        The need to identify the source and origin of funds depends on the Customer’s previous activities 
        as well as other known information. Thereby the identification of the source and origin of the 
        funds used in transaction shall be performed in the following cases:
        <ul className="list-disc list-inside">
            <li>the transactions exceed the limits established by the Company;</li>
            <li>if the transactions do not correspond to the information previously known about the Customer;</li>
            <li>if the Company wants to or should reasonably consider it necessary to assess whether 
            the transactions correspond to the information previously known about the Customer;</li>
            <li>if the Company suspects that the transactions indicate criminal activities, money 
            laundering or terrorist financing or that the relation of transactions to money laundering 
            or terrorist financing is probable, incl. complicated, high-value and unusual transactions 
            and transaction patterns that do not have any reasonable or obvious economic or 
            legitimate purpose or are uncharacteristic of the specific features of the business in 
            question.</li>
        </ul>
        `
    },
    {
        title: "IMPLEMENTATION OF SANCTIONS",
        description: `
        Upon the entry into force, amendment or termination of Sanctions, the Company shall verify 
        whether the Customer or a person who is planning to have the business relationship or 
        transaction with them is a subject of Sanctions. If the Company identifies a person who is a 
        subject of Sanctions or that the transaction intended or carried out by them is in breach of 
        Sanctions, the Company shall apply Sanctions and immediately inform the FCIS thereof.
        <br/><br/>
        <p className="text-white">Procedure for identifying the subject of Sanctions and a transaction violating Sanctions</p>
        The Company shall use at least one of the following sources (databases) to verify the Customer´s relation to Sanctions:
        <ul className="list-disc list-inside">
            <li>ComplyAdvantage watchlists via Sum&Substance software;</li>
            <li className="underline">Financial sanctions information and search;</li>
            <li>Other internal databases or databases managed by third parties, which contain at least 
            the lists from databases specified above.</li>
        </ul>
        <br/>
        In addition to aforementioned sources, the Company may use any other sources by the decision of the Employee who is applying CDD measures.
        <br/><br/>
        To verify that the persons’ names resulting from the inquiry are the same as the persons listed 
        in a notification containing Sanction(s), their personal data shall be used, the main characteristics 
        of which are, for a legal entity, its name or trademark, registry code or registration date, and for 
        a natural person, their name and personal identification or date of birth.
        <br/><br/>
        In order to establish the identity of the persons specified in the relevant legal act or notice being 
        the same as those identified as a result of the inquiry from databases, the Company must analyze 
        the names of the persons found as a result of the inquiry based on the possible effect of factors 
        distorting personal data (e. g. transcribing foreign names, different order of words, substitution 
        of diacritics or double letters etc.).
        <br/><br/>
        The Company shall perform abovementioned verification on an ongoing basis in the course of an 
        established business relationship. The frequency of the ongoing verifications depends on the 
        Customer’s risk profile:
        <ul className="list-disc list-inside">
            <li>once per week for the high-risk profile Customer;</li>
            <li>once per month for the medium-risk profile Customer;</li>
            <li>once per quarter for the low-risk profile Customer.</li>
        </ul>
        <br/>
        If the Employee has doubts that a person is a subject of Sanctions, the Employee shall 
        immediately notify the MLRO or the Management Board member. In this case the MLRO of the 
        Management Board member shall decide on whether to ask or acquire additional data from the 
        person or notify the FCIS immediately of their suspicion.
        <br/><br/>
        The Company shall primarily acquire additional information on their own about the person who 
        is in business relationship or is performing a transaction with them, as well as the person 
        intending to establish the business relationship, perform a transaction or an act with them, 
        preferring information from a credible and independent source. If, for some reason, such 
        information is not available, the Company shall ask the person who is in the business relationship 
        or is performing a transaction or an act with them, as well as the person intending to establish a 
        business relationship, perform a transaction or an act with them, whether the information is 
        from a credible and independent source and assess the answer.
        <br/><br/>
        <p className="text-white">Actions when identifying the Sanctions subject or a transaction violating Sanctions</p>
        <br/><br/>
        If the employee of the Company becomes aware that the Customer which is in business 
        relationship or is performing a transaction with the Company, as well as a person intending to 
        establish the business relationship or to perform a transaction with the Company, is the subject 
        of Sanctions, the employee shall immediately notify the MLRO or the Management Board 
        member, about the identification of the subject of Sanctions, of the doubt thereof and of the 
        measures taken.
        <br/><br/>
        The MLRO or the Management Board member shall refuse to conclude a transaction or 
        proceeding, shall take measures provided for in the act on the imposition or implementation of 
        the Sanctions and shall notify immediately the FCIS of their doubts and of the measures taken.
        <br/><br/>
        When identifying the subject of the Sanctions, it is necessary to identify the measures that are 
        taken to sanction this person. These measures are described in the legal act implementing the 
        Sanctions, therefore it is necessary to identify the exact sanction what is implemented against 
        the person to ensure legal and proper application of measures.
        `
    },
    {
        title: "REFUSAL TO THE TRANSACTION OR BUSINESS RELATIONSHIP AND THEIR TERMINATION",
        description: `
        The Company is prohibited to establish business relationship and the established business 
        relationship or transaction shall be terminated in case when:
        <ul className="list-disc list-inside">
            <li>the Company suspects money laundering or terrorist financing;</li>
            <li>it is impossible for the Company to apply the CDD measures, because the Customer does 
            not submit the relevant data or refuses to submit them or the submitted data gives no 
            grounds for reassurance that the collected data are adequate;</li>
            <li>the Customer which capital consists of bearer shares or other bearer securities wants to 
            establish the business relationship;</li>
            <li>the Customer who is a natural person behind whom is another, actually benefiting 
            person, wants to establish the business relationship (suspicion that a person acting as a 
            front is used);</li>
            <li>the Customer´s risk profile has become inappropriate with the Company´s risk appetite 
            (i. e. the Customer´s risk profile level is “prohibited”).</li>
        </ul>
        <br/>
        The aforementioned is not applied when the Company has notified the FCIS of the establishment 
        of the business relationship, transaction or an attempted transaction in accordance with the 
        procedure provided below and received from the FCIS a specific instruction to continue the 
        business relationship, the establishment of the business relationship or the transaction.
        <br/><br/>
        In the event of a termination of the business relationship in accordance with this chapter, the 
        Company shall transfer the Customer’s assets (the LPA´s unit redemption price) within 
        reasonable time, but preferably not later than within one month after the termination and as a 
        whole to an account opened in a credit institution which is registered or whose place of business 
        is in a contracting state of the European Economic Area or in a country where requirements equal 
        to those established in the relevant directives of the European Parliament and of the Council are 
        applied. In exceptional cases, assets may be transferred to an account other than the Customer’s 
        account or issued in cash by informing the Lithuanian Financial Crime Investigation Service about 
        this with all the relevant and sufficient information at least 7 working days in advance and on the 
        condition that the Lithuanian Financial Crime Investigation Service does not give a different 
        order. Irrespective of the recipient of the funds, the minimum information given in English in the 
        payment details of the transfer of the Customer’s assets is that the transfer is related to the 
        extraordinary termination of the Customer relationship.
        `
    },
    {
        title: "REPORTING OBLIGATION",
        description: `
        The Company through its MLRO must report to the FCIS on the activity or the circumstances that 
        they identify in the course of economic activities and whereby:
        <ul className="list-disc list-inside">
            <li>the characteristics indicate the use of criminal proceeds or the commission of crimes 
            related to this (this is primarily a report on a suspicious and unusual transaction or 
            activity, i.e. UTR or UAR);</li>
            <li>in the case of which they suspect or know or the characteristics of which indicate the 
            commission of money laundering or related crimes (this is primarily a report on a 
            transaction or activity whereby money laundering is suspected, i.e., STR or SAR);</li>
            <li>in the case of which they suspect or know or the characteristics of which indicate the 
            commission of terrorist financing or related crimes (this is primarily a report on a 
            transaction or activity whereby terrorist financing is suspected, i.e., TFR);</li>
            <li>in the case of which an attempt of the activity or circumstances specified in previous 
            clauses is present.</li>
        </ul>
        <br/>
        The minimal characteristics of suspicious and unusual transactions are provided in the guidelines 
        made by the FCIS (one of annexes of these Guidelines).
        <br/><br/>
        The Company through its MLRO must report the FCIS:
        <ul className="list-disc list-inside">
            <li>about the circumstances of refusal of establishment of the business relationship and 
            about the termination of the business relationship on the basis of circumstances provided 
            in the previous chapter (primarily a suspicious and unusual transaction or activity report, 
            i.e. UAR);</li>
            <li>about each transaction that has become known whereby a pecuniary obligation of over 
            32 000 euros or an equal sum in another currency is performed in cash, regardless of 
            whether the transaction is made in a single payment or in several linked payments over 
            a period of up to one year (an amount-based report, CTR);</li>
            <li>about identifying the subject of the Sanctions and the implementation of Sanctions or 
            suspicion thereof (international sanction report, ISR).</li>
        </ul>
        <br/>
        The reports specified above must be made before the completion of the transaction if the 
        Company suspects or knows that money laundering or terrorist financing or related crimes are 
        being committed and if said circumstances are identified before the completion of the 
        transaction. If the postponement of the transaction may cause considerable harm, it is not 
        possible to omit the transaction or it may impede capture of the person who committed possible 
        money laundering or terrorist financing, the transaction will be concluded and a report will be 
        submitted the FCIS thereafter. The Company is in contact with the Lithuanian Financial Crime 
        Investigation Service in order to identify such circumstances.
        <br/><br/>
        If the necessity of abovementioned report arises, the Employee to whom became known such 
        necessity must immediately notify the MLRO about this.
        <br/><br/>
        In any case (i.e. also in the situation where an activity or circumstance is identified after the 
        completion of the transaction), <b>the reporting obligation must be performed immediately, but 
        not later than two working days</b> after the identification of the activity or circumstance or the 
        emergence of the actual suspicion (i.e. the situation where the suspicion cannot be dispelled).
        <br/><br/>
        The report shall be sent in accordance with the guidelines, issued by the FCIS (one of annexes of these Guidelines).
        <br/><br/>
        The Company, a structural unit of the Company, a Management Board member, MLRO and the 
        Employee is prohibited to inform a person, its beneficial owner, representative or third party 
        about a report submitted on them to the FCIS, a plan to submit such a report or the occurrence 
        of reporting as well as about a precept made by the FCIS or about the commencement of criminal 
        proceedings. After a precept made by the FCIS has been complied with, the Company may inform 
        a person that the FCIS has restricted the use of the person’s account or that another restriction 
        has been imposed.
        `
    },
    {
        title: "TRAINING OBLIGATION",
        description: `
        The Company ensures that its employees, its contractors and others participating in the business 
        on a similar basis and who perform work tasks that are of importance for preventing the use of 
        the business for money laundering or terrorist financing (‘Relevant Persons’) have the relevant 
        qualifications for these work tasks. When a Relevant Person is recruited or engaged, the Relevant 
        Person’s qualifications are checked as part of the recruitment/appointment process by carrying 
        out background checks comprising extracts from criminal records in addition to the customary 
        taking of references, which is documented using a special standard form assessing employee 
        suitability.
        <br/><br/>
        In accordance with the requirements applicable to the Company on ensuring the suitability of 
        Relevant Persons, the Company makes sure that such persons receive appropriate training and 
        information on an ongoing basis to be able to fulfil the Company’s obligations in compliance with 
        the applicable legislation. It is ensured through training that such persons are knowledgeable 
        within the area of AML/CFT to an appropriate extent considering the person’s tasks and function. 
        The training must provide, first and foremost, information on all the most contemporary money 
        laundering and terrorist financing methods and risks arising therefrom.
        <br/><br/>
        This training refers to relevant parts of the content of the applicable rules and regulations, the 
        Company’s risk assessment, the Company’s Guidelines and procedures and information that 
        should facilitate such Relevant Persons detecting suspected money laundering and terrorist 
        financing. The training is structured on the basis of the risks identified through the risk 
        assessment policy.
        <br/><br/>
        The content and frequency of the training is adapted to the person’s tasks and function on issues 
        relating to AML/CFT measures. If the Guidelines is updated or amended in some way, the content 
        and frequency of the training is adjusted appropriately.        
        <br/><br/>
        For new employees, the training comprises a review of the content of the applicable rules and 
        regulations, the Company’s risk assessment policy, these Guidelines and other relevant procedures.
        <br/><br/>
        The employees and the Management Board members receive training on an ongoing basis under 
        the auspices of the MLRO in accordance with the following training plan:
        <ul className="list-disc list-inside">
            <li>periodicity: at least once a year for the Management Board members. At least once a year 
            for the Company’s employees and Relevant Person engaged.</li>
            <li>scope: review of applicable rules and regulations, the Company’s Guidelines and other 
            relevant procedures. Specific information relating to new/updated features in the 
            applicable rules and regulations. Report and exchange of experience relating to 
            transactions reviewed since the previous training.</li>
        </ul>
        <br/>
        In addition to the above, Relevant Persons are kept informed on an ongoing basis about new 
        trends, patterns and methods and are provided with other information relevant to the 
        prevention of money laundering and terrorist financing.
        <br/><br/>
        The training held is to be documented electronically and confirmed with the Relevant Person 
        signature. This documentation should include the content of the training, names of participants 
        and date of the training.
        `
    },
    {
        title: "COLLECTION AND PRESERVATION OF DATA",
        description: `
        The Company through the person (incl. Employees, Management Board members and MLRO) 
        who firstly receives the relevant information or documents shall register and retain:
        <ul className="list-disc list-inside">
            <li>all data collected within CDD measures implementation;</li>
            <li>information about the circumstances of refusal of the establishment of the business 
            relationship by the Company;</li>
            <li>the circumstances of the refusal to establish business relationship on the initiative of the 
            Customer if the refusal is related to the application of CDD measures by the Company;</li>
            <li>information on all of the operations made to identify the person participating in the 
            transaction or the Customer´s beneficial owner;</li>
            <li>information if it is impossible to take the CDD measures using information technology means;</li>
            <li>information on the circumstances of termination of the business relationship in 
            connection with the impossibility of application of the CDD measures;</li>
            <li>the each transaction date or period and a description of the contents of the transaction;</li>
            <li>information serving as the basis for the reporting obligations specified above;</li>
            <li>data of suspicious or unusual transactions or circumstances of which the Lithuanian 
            Financial Crime Investigation Service was not notified.</li>
        </ul>
        <br/>
        In addition to the abovementioned information the Company shall register the following data regarding a transaction:
        <ul className="list-disc list-inside">
            <li>upon opening an account, the account type, number, currency and significant 
            characteristics of the securities or other property;</li>
            <li>upon making a payment relating to shares, bonds or other securities, the type of the 
            securities, the monetary value of the transaction, the currency and the account number;</li>
            <li>in the case of another transaction, the transaction amount, the currency and the account 
            number.</li>
        </ul>
        <br/>
        The data specified above shall be retained for 5 years after the expiry of the business relationship 
        or the completion transaction. The data related to the performance of the reporting obligation 
        must be retained for 5 years after the performance of the reporting obligation.
        <br/><br/>
        Documents and data must be retained in a manner that allows for exhaustive and immediate 
        response to the queries made by the FCIS or, pursuant to legislation, other supervisory 
        authorities, investigation authorities or the court.
        <br/><br/>
        The Company implements all rules of protection of personal data upon application of the 
        requirements arising from the applicable legislation. The Company is allowed to process personal 
        data gathered upon CDD implementation only for the purpose of preventing money laundering 
        and terrorist financing and the data must not be additionally processed in a manner that does 
        not meet the purpose, for instance, for marketing purposes.
        <br/><br/>
        The Company deletes the retained data after the expiry of the time period, unless the legislation 
        regulating the relevant field establishes a different procedure. On the basis of a precept of the 
        competent supervisory authority, data of importance for prevention, detection or investigation 
        of money laundering or terrorist financing may be retained for a longer period, but not for more 
        than five years after the expiry of the first time period.
        `
    },
    {
        title: "AVOIDING CONFLICT OF INTERESTS",
        description: `
        The Employees (incl. MLRO) must avoid the conflict of interests and when this happens, 
        immediately notify the Management Board member or the MLRO.
        <br/><br/>
        The conflict of interests is understood as all the circumstances known to the Company or its 
        employees that may affect the decisions of making a transaction or establishing business 
        relationship and which do not correspond to the interests of the Company or its customer.
        <br/><br/>
        To achieve the goal of avoiding the conflict of interests, the Company shall collect and regularly 
        update its employee’s data in order to identify their interests in the context of preventing money 
        laundering and terrorist financing. The Company collects the following data about each 
        employee:
        <ul className="list-disc list-inside">
            <li>the birthplace and place of residence of the employee;</li>
            <li>other job positions and contracts of the employee that they have in the context of the 
            economic field;</li>
            <li>the data regarding the close relatives of the employee (spouse, parents, children, siblings 
            and their children): for each person, their place of residence and place of work.</li>
            <li>other data known to the employee which may indicate to the interests in the context of 
            preventing money laundering and terrorist financing.</li>
        </ul>
        <br/>
        The failure of the employee to provide the data specified above is considered to be a significant 
        violation of the employment contract and may result in the extraordinary cancellation of the 
        employment contract for reason arising from the employee.
        <br/><br/>
        The Company identifies and analyses, inter alia, whether the persons directing customers to the 
        Company (e.g. agents, resellers, etc.) have any interests regarding the Customer (e.g., provide 
        them with legal services, accounting services, services providing the establishment of companies 
        and other legal structures, etc.) which cause the conflict of interests between the person 
        directing customers to the Company and the Customer.
        <br/><br/>
        In case of identifying a conflict of interests or circumstances indicating a conflict of interests, the 
        Company shall apply all necessary measures to prevent it. If it is impossible to prevent the conflict 
        of interests, the Company must not conclude any transactions or establish the business 
        relationship.
        <br/><br/>
        The Management Board is responsible for avoiding conflict of interests in the Company.
        `
    },
    {
        title: "INTERNAL CONTROL OF EXECUTION OF THE GUIDELINES",
        description: `
        The performance of the Guidelines shall be internally controlled by the Internal Control Officer 
        appointed by the Management Board. The Internal Control Officer must have the required 
        competency, tools, and access to the relevant information in all structural units of the Company.
        <br/><br/>
        The Internal Control Officer shall provide the internal control report to the Management Board 
        at least quarterly. The Management Board is responsible for exercising control over the due 
        performance of the requirements set out in these Guidelines, including the performance of 
        internal control function. For this reason, the Management Board is obliged to:
        <ul className="list-disc list-inside">
            <li>analyze the results of performed internal control;</li>
            <li>implement actions to eliminate deficiencies occurred.</li>
        </ul>
        <br/>
        A detailed overview of internal control activities will be given in document “Internal Control 
        Guidelines”, which is annexed to the Guidelines.
        `
    },
    {
        title: "ANNEXES",
        description: `
        <table className="border border-[#98a2b3]">
            <tr className="border border-[#98a2b3]">
                <th className="border-r border-[#98a2b3]">Annex title</th>
                <th>Document description</th>
            </tr>
            <tr className="border border-[#98a2b3]">
                <td className="w-1/2 px-2 border-r border-[#98a2b3]">Risk Assessment Policy</td>
                <td className="w-1/2 px-2">Establishes principles for the Company´s risk 
                management (incl. risk assessment and risk 
                factors) regarding to money laundering and 
                terrorist financing risks. 
                Includes its own annexes.</td>
            </tr>
            <tr className="border border-[#98a2b3]">
                <td className="w-1/2 px-2 border-r border-[#98a2b3]">Internal Control Guidelines</td>
                <td className="w-1/2 px-2">Establishes principles and procedure for 
                internal control for performing obligations 
                provided for in the Guidelines. 
                Includes its own annexes.</td>
            </tr>
            <tr className="border border-[#98a2b3]">
                <td className="w-1/2 px-2 border-r border-[#98a2b3]">Guidelines on the characteristics of suspicious 
                transactions</td>
                <td className="w-1/2 px-2">FCIS guidelines which provide instructions and 
                examples of transactions and other 
                circumstances, which shall be considered 
                suspicious from the ML/TF perspective.</td>
            </tr>
            <tr className="border border-[#98a2b3]">
                <td className="w-1/2 px-2 border-r border-[#98a2b3]">Guidelines for submitting a report to the 
                Lithuanian Financial Crime Investigation 
                Service</td>
                <td className="w-1/2 px-2">FCIS guidelines which provide instructions for 
                the report (e. g. SAR STR, ISR etc.) shall be 
                submitted to FCIS.</td>
            </tr>
        </table>
        `
    },
]
import React from "react";

// type = "solid" | "gradient"
const Cards = ({ grid, cardData, type }) => {
  function groupArray(arr, groupSize) {
    return arr.reduce((result, value, index) => {
        if (index % groupSize === 0) {
            result.push(arr.slice(index, index + groupSize));
        }
        return result;
    }, []);
}

  return (
    <>
      {groupArray(cardData, grid).map((arrGroup) => (
        <div className="flex max-md:flex-col gap-9 pb-9">
          {arrGroup.map((card) => (
            <div
              style={{
                background:
                  type === "gradient" &&
                  "linear-gradient(180deg, #21262D -7.3%, #21262D00 122.02%)",
              }}
              className={`w-full md:w-[${100 / grid}%] p-8 rounded-2xl ${
                type === "solid" ? "bg-card-dark" : ""
              }`}
            >
              {/* border-[#353D45] border-[1px]  bg-card-dark */}
              <img src={card.icon} alt="" className="w-[64px] pb-6" />
              <p
                className="text-2xl text-white font-semibold pb-6"
                style={{ letterSpacing: "-0.96px" }}
              >
                {card.title}
              </p>
              {typeof card.description == "string" ? (
                <p
                  className="text-lg text-white/80 font-[300]"
                  style={{ letterSpacing: "-0.36px" }}
                >
                  {card.description}
                </p>
              ) : (
                card.description
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default Cards;

import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const StartNow = () => {
  const [scrollY, setScrollY] = useState(0);
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Parallax speed={30}>
      <div
        className={`mb-[20px] sm:mb-[50px] md:mb-[128px] ${
          inView ? "fadeIn" : "opacity-0"
        }`}
        ref={ref}
      >
        <div className="max-md:w-[90%] max-md:m-auto">
          <p
            className="text-[42px] lg:text-[54px] font-semibold text-center text-white max-sm:mb-3"
            style={{ letterSpacing: "-2.16px" }}
          >
            {t("home.getStarted.title")}
          </p>
          <p
            className="text-base lg:text-lg text-center text-dark-text max-w-[800px] mx-auto max-sm:text-sm"
            style={{ letterSpacing: "-0.36px" }}
          >
            {parse(t("home.getStarted.description"))}
          </p>
        </div>
        <div className="flex justify-center mt-[46px] max-sm:mt-10">
          <div className="bg-card-dark p-[42px] flex max-xl:flex-col gap-[74px] rounded-2xl max-md:w-[90%] max-sm:p-8">
            <div className="md:w-[318px] relative flex flex-col justify-between">
              <div>
                <img src="/icons/userCreate.svg" alt="" className="pb-5 max-sm:pb-3 max-sm:w-12" />
                <p
                  className="text-left text-custom-orange pb-3 font-[600] text-lg"
                  style={{ letterSpacing: "-0.36px" }}
                >
                  {t("home.getStarted.create.name")}
                </p>
                <p
                  className="text-left text-base text-dark-text font-[400] max-sm:text-sm"
                  style={{ letterSpacing: "-0.64px" }}
                >
                  {t("home.getStarted.create.description")}
                </p>
              </div>
              <div className="flex">
                <Link to={process.env.REACT_APP_SIGN_IN}>
                  <div className="border-custom-primary border-[1px] text-custom-primary hover:bg-custom-primary hover:border-transparent cursor-pointer 
                        transition-colors hover:text-black px-5 py-3 w-fit rounded-lg text-base mt-5 font-[600] max-sm:py-2 max-sm:px-4">
                    Sign up
                  </div>
                </Link>
              </div>
            </div>
            <div className="md:w-[318px] relative flex flex-col justify-between">
              <div>
                <img src="/icons/verifyAccount.svg" alt="" className="pb-5 max-sm:pb-4 max-sm:w-12" />
                <p
                  className="text-left text-custom-orange pb-3 font-[600] text-lg"
                  style={{ letterSpacing: "-0.36px" }}
                >
                  {t("home.getStarted.verify.name")}
                </p>
                <p
                  className="text-left text-base text-dark-text font-[400] max-sm:text-sm"
                  style={{ letterSpacing: "-0.64px" }}
                >
                  {t("home.getStarted.verify.description")}
                </p>
              </div>
              <div className="flex">
                <Link to={process.env.REACT_APP_SIGN_IN}>
                  <div className="border-custom-primary border-[1px] text-custom-primary hover:bg-custom-primary hover:border-transparent cursor-pointer 
                        transition-colors hover:text-black px-5 py-3 w-fit rounded-lg text-base mt-5 font-[600] max-sm:py-2 max-sm:px-4">
                    Verify
                  </div>
                </Link>
              </div>
            </div>
            <div className="md:w-[318px] flex flex-col justify-between">
              <div>
                <img src="/icons/deposit.svg" alt="" className="pb-5 max-sm:pb-4 max-sm:w-12" />
                <p
                  className="text-left text-custom-orange pb-3 font-[600] text-lg"
                  style={{ letterSpacing: "-0.36px" }}
                >
                  {t("home.getStarted.deposit.name")}
                </p>
                <p
                  className="text-left text-base text-dark-text font-[400] max-sm:text-sm"
                  style={{ letterSpacing: "-0.64px" }}
                >
                  {t("home.getStarted.deposit.description")}
                </p>
              </div>
              <div className="flex">
                <Link to={process.env.REACT_APP_SIGN_IN}>
                  <div className="border-custom-primary border-[1px] text-custom-primary hover:bg-custom-primary hover:border-transparent cursor-pointer 
                        transition-colors hover:text-black px-5 py-3 w-fit rounded-lg text-base mt-5 font-[600] max-sm:py-2 max-sm:px-4">
                    Trade
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="border-[1px] border-dark-text/50 flex max-md:flex-col max-md:gap-8 max-md:w-[90%] justify-between container md:w-[1186px] mt-8 p-8 
              max-lg:p-7 rounded-2xl items-center max-sm:p-6">
          <div className="flex items-center max-md:gap-3">
            <p className="bg-gradient-to-r bg-clip-text font-bold text-transparent from-slate-50/20 to-slate-50/0 xl:text-[32px] md:text-2xl text-base">
              Time is going...
            </p>
            <p className="text-custom-primary xl:text-[32px] md:text-2xl text-base font-semibold">
              Take action for your financial tomorrow
            </p>
          </div>
          <Link to={process.env.REACT_APP_SIGN_UP}>
            <div className="border-custom-primary border-[1px] text-custom-primary hover:bg-custom-primary hover:border-transparent cursor-pointer 
                  transition-colors hover:text-black px-5 py-3 w-fit rounded-lg text-base font-[600] max-sm:py-2 max-sm:px-4">
              Get Started
            </div>
          </Link>
        </div>
      </div>
    </Parallax>
  );
};

export default StartNow;

import React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { useWindowSize } from "../../lib/hooks/WindowSizeHook";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
// import { useContext } from "react";
// import { OpeningSoonContext } from "../../context/openingSoonContext";

const AccountTypes = () => {
  // const { setWarning } = useContext(OpeningSoonContext);
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const {width} = useWindowSize();

  const personalAccountTypePoints = [
    "Easy-to-use platform.",
    "Low fees for every transaction/deposit.",
    "Referral program.",
    "Multiple security layers."
  ]

  const businessAccountTypePoints = [
    "Simple interface.",
    "Higher transaction limit.",
    "EMI Banking.",
    "Elite security."
  ]

  return (
    <div className="md:mb-[100px] relative ">
      <div class="side-light top-[-15%] lg:top-[-30%] max-sm:left-[-30%]"></div>
      <div class="side-light top-[70%] left-[40%] sm:left-[50%] lg:top-[30%]"></div>

      <Parallax speed={width < 700 ? 10 : 30}>
        <div
          className={`container m-auto py-[52px] md:py-[100px] ${
            inView ? "fadeIn" : "opacity-0"
          }`}
          ref={ref}
        >
          <div className="max-md:w-[90%] max-md:m-auto">
            <p
              className="text-center text-white text-[42px] lg:text-[54px] font-semibold mb-6 max-sm:text-4xl max-sm:mb-3"
              style={{ letterSpacing: "-2.16px" }}
            >
              {t("home.types.title")}
            </p>
            <p className="text-center text-dark-text pb-10 max-sm:text-sm max-sm:pb-6 max-w-[740px] mx-auto">
              {parse(t("home.types.description"))}
            </p>
          </div>
          <div className="lg:flex justify-center gap-8">
            <div className="w-[90%] md:w-[500px] p-8 bg-[#2B3035] rounded-2xl max-lg:mb-6 max-lg:m-auto z-[10] max-sm:p-6">
              <img src="/icons/Group.png" alt="" className="pb-6 max-sm:pb-4" />
              <p
                className="text-2xl text-white font-semibold pb-6 max-sm:pb-4"
                style={{ letterSpacing: "-0.96px" }}
              >
                {t("home.types.personal.name")}
              </p>
              <p
                className="text-sm md:text-base text-white font-normal pb-6 max-sm:pb-4"
                style={{ letterSpacing: "-0.64px" }}
              >
                {t("home.types.personal.description")}
              </p>
              <div className="md:w-[320px] w-full pb-6 max-sm:pb-4">
                {personalAccountTypePoints.map((point) => (
                  <div className="flex gap-2.5 pb-[18px] max-sm:pb-3">
                    <img src="/icons/checkGradient.svg" alt="" />
                    <p
                      className="text-white max-sm:text-sm"
                      style={{ letterSpacing: "-0.36px" }}
                    >
                      {/* {t(`home.types.personal.list.${indx}`)} */}
                      {point}
                    </p>
                  </div>
                ))}
              </div>
              <Link to={process.env.REACT_APP_SIGN_UP}>
                <div
                  className="bg-custom-primary/20 hover:bg-custom-primary-dark/20 text-custom-primary hover:text-custom-primary-dark text-center py-[11px] rounded-lg font-semibold transition-colors"
                  style={{ letterSpacing: "-0.64px" }}
                >
                  {/* {t("home.types.personal.register")} */}
                  Register as Personal
                </div>
              </Link>
            </div>
            <div className="w-[90%] md:w-[500px] p-8 bg-[#2B3035] rounded-2xl max-lg:m-auto">
              <img src="/icons/business.svg" alt="" className="pb-6 max-sm:pb-4" />
              <p
                className="text-2xl text-white font-[600] pb-6 max-sm:pb-4"
                style={{ letterSpacing: "-0.96px" }}
              >
                {t("home.types.business.name")}
              </p>
              <p
                className="text-sm md:text-base text-white font-normal pb-6 max-sm:pb-4"
                style={{ letterSpacing: "-0.64px" }}
              >
                {t("home.types.business.description")}
              </p>
              <div className="md:w-[320px] w-full pb-6 max-sm:pb-4">
                {businessAccountTypePoints.map((point) => (
                  <div className="flex gap-2.5 pb-[18px] max-sm:pb-3">
                    <img src="/icons/checkGradient.svg" alt="" />
                    <p
                      className="text-white max-sm:text-sm"
                      style={{ letterSpacing: "-0.36px" }}
                    >
                      {/* {t(`home.types.business.list.${indx}`)} */}
                      {point}
                    </p>
                  </div>
                ))}
              </div>
              <Link to={process.env.REACT_APP_SIGN_UP}>
                <div
                  className="bg-custom-primary hover:bg-custom-primary-dark text-black text-center py-[11px] rounded-lg font-[600] transition-colors"
                  style={{ letterSpacing: "-0.64px" }}
                >
                  {t("home.types.business.register")}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default AccountTypes;

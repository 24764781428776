export const complaints = [
  {
    title: "1. Introduction",
    description: `Niza Global UAB is registered in Lithuania with registration number 306048866 and license number 306048866 for providing virtual currency services. Address: Architektų g.56-101, LT-04111 Vilnius, Lithuania. 
    <br/><br/>
    This Policy outlines how we handle any complaints that we receive and complies with our regulatory requirements.
    <br><br>
    <b>To expedite dispute resolution procedures, we suggest the following procedure to simplify the process. However, we would like to emphasize that clients retain the right to seek resolution through judicial authorities at any time.</b>
    `
  },
  {
    title: "2. How to Make a Complaint",
    description: `If you are a client of Niza Global UAB, we would encourage you first to speak with the Customer Support team. If you have a complaint, the best way to contact us is via the web browser chat. Alternatively, you can submit a complaint via our online “Submit a complaint” form or by sending an email to <a className="underline" href="mailto:support@niza.io">support@niza.io</a>
    <br/><br/>
    If you choose to send an email, you’ll need to provide the following information:
    <ul className="list-disc list-inside">
      <li>your name and surname;</li>
      <li>the phone number and email address associated with your account;</li>
      <li>what the issue is;</li>
      <li>when the problem arose; and</li>
      <li>how you'd like us to put the matter right;</li>
    </ul>
    `
  },
  {
    title: "3. What Happens Next",
    description: `Once you’ve submitted your complaint, we’ll acknowledge receipt shortly after. We'll then investigate your complaint and respond to you via email.
    <br/><br/>
    Your complaint will be logged in our system, and a designated Customer Support team member will handle your matter.
    <br/><br/>
    We assure you that the team member handling your complaint will be an experienced staff member and, where appropriate, someone not directly involved in the matter about which you are raising a complaint. They will have the authority to settle your complaint or will have access to someone who has such authority.
    `
  },
  {
    title: "4. Steps Towards Resolving Your Complaint",
    description: `Step 1 – The designated team member will acknowledge receipt of your complaint, by email, within 2 business days.
    <br/><br/>
    Step 2 – We will carry out a thorough investigation into your complaint, and you should receive a response and an explanation.
    <br/><br/>
    Step 3 – If you are not happy with our response, you are invited to provide your comments, particularly if we have omitted something that you consider relevant to the matter.
    <br/><br/>
    Step 4 – Niza Global UAB will provide you with a final response to the initial complaint, confirming our position and setting out our conclusions.
    <br/><br/>
    Step 5 – If you are not satisfied with our final resolution, you can refer your complaint to the relevant external dispute resolution scheme.
    `
  },
  {
    title: "5. Time Frames",
    description: `We aim to resolve your complaint and issue our final conclusions within 15 business days of receiving it. If we cannot provide a final response within that time period, we will contact you to explain why and confirm when you can expect a substantive response from us.
    <br/><br/>
    In any event, we will send you a final response within 35 business days of receiving your complaint.
    <br/><br/>
    Please note that Niza Global UAB may request additional documents in support of your claim, and we expect them to be submitted promptly.
    `
  },
  {
    title: "6. What Happens if You are Not Satisfied with Our Response",
    description: `If despite our best efforts, you’re still unhappy with how we dealt with your complaint, you can contact the relevant external dispute resolution scheme.`
  },
  {
    title: "7. Complaint Records",
    description: `Legislation obliges us to keep a record of each complaint received and the measures taken for its resolution. We retain these records for a minimum of 5 years from the date the complaint was received.`
  },
]
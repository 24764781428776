import React from "react";
// import { useTranslation } from "react-i18next";
// import { Hero as CommonHero } from "../common/Hero";

const Hero = () => {
  // const { t } = useTranslation();

  return (
    // <CommonHero>
    <div className="max-md:h-[1080px] h-[100vh] bg-[#141A20] relative fade-bottom">
      <div className="z-[10] absolute left-[50%] top-[42%] max-sm:mt-0 translate-x-[-50%] translate-y-[-50%] w-[100%]">
        <div className="flex justify-between max-lg:flex-col container mt-40 max-lg:mt-80 max-md:mt-[28rem]">
          <div className="2xl:w-1/2 xl:w-7/12 lg:w-9/12 w-full">
            {/* left side */}
            <p className="text-[40px] md:text-[54px] font-[500] text-white mb-[26px] ">
              The name <span className="text-custom-primary">NIZA</span> talks
              about all of our aspects.
            </p>
            <p className="text-dark-text text-lg w-[83%]">
              We put security, user experience and providing efficient services
              as our top priority, by improving ourselves daily and by relying
              on{" "}
              <span className="text-white/90 font-[500]">
                our costumers needs and feedbacks.
              </span>
            </p>
            <div className="flex max-md:flex-col gap-5 mt-8">
              <div className="bg-card-dark w-fit rounded-xl p-4 min-w-[178px] max-md:w-full">
                <p className="text-dark-text text-base">Established in</p>
                <p className="text-custom-primary text-[40px] font-[700]">
                  2021
                </p>
              </div>
              <div className="bg-card-dark w-fit rounded-xl p-4 min-w-[178px] max-md:w-full">
                <p className="text-dark-text text-base">Countries served</p>
                <p className="text-custom-primary text-[40px] font-[700]">
                  100+
                </p>
              </div>
              <div className="bg-card-dark w-fit rounded-xl p-4 min-w-[178px] max-md:w-full">
                <p className="text-dark-text text-base">Users served</p>
                <p className="text-custom-primary text-[40px] font-[700]">
                  100,000+
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-[45%] w-full mt-16">
            {/* right side */}
            <img
              src="/images/aboutHero.png"
              alt=""
              className="xl:min-w-[623px] min-w-[90%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

const Hero = () => {
  return (
    <div className="bg-[#142128] relative overflow-hidden">
      <div className="absolute right-[-20%] top-[-30%] w-6 h-6 side-light2 max-md:hidden" />
      <div className="absolute left-0 bottom-0">
        <img src="/icons/niza_vector.png" alt="" className="" />
      </div>
      <div className="md:pb-[154px] pb-[100px] pt-[170px] md:pt-[254px] container relative">
        <img
          src="/icons/comissionNiza2.png"
          alt=""
          className="absolute top-[30%] left-[40%] max-sm:top-[40%] max-sm:left-[80%]"
        />

        <div className="flex justify-between max-md:flex-col gap-10 max-sm:gap-15">
          <p className="md:text-[54px] text-4xl font-semibold text-white max-md:w-full max-lg:w-[60%] leading-[120%]">
            <span className="text-custom-primary">Niza</span> is your trusted
            and secure crypto partner
          </p>
          <div className="max-md:flex max-md:justify-center">
            <img src="/icons/shieldTick.png" alt="" className="max-md:w-[181px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

const Hero = () => {
  return (
    <div className="bg-[#142128] relative overflow-hidden">
      <div className="absolute right-[-20%] top-[-30%] w-6 h-6 side-light2 max-md:hidden" />
      <div className="absolute left-0 bottom-0">
        <img src="/icons/niza_vector.png" alt="" className="max-md:hidden" />
      </div>
      <div className="md:pb-[154px] pb-[100px] pt-[170px] md:pt-[254px] container relative">
        <img
          src="/icons/comissionNiza2.png"
          alt=""
          className="absolute sm:top-[30%] sm:left-[40%] max-sm:bottom-[-10%] left-[-15%]"
        />

        <p className="md:text-[28px] text-sm text-custom-primary mb-8">Last Updated: 22 April 2024</p>
        <p className="md:text-[54px] text-4xl font-[600] text-white">Terms & Conditions</p>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import { Hero as CommonHero } from "../common/Hero";

const Hero = () => {
  return (
    <CommonHero
      imgs={[
        <img
          src="images/niza-banking-hero.png"
          className="absolute bottom-0"
          alt="globe"
        />,
      ]}
      height={'max-md:h-[70vh]'}
    >
      <div className="flex justify-center mb-[100px]">
        <div className="max-md:container md:w-[600px]">
          <p className="text-white text-[36px] md:text-[54px] text-center font-[500]">
            Transfer funds globally with a{" "}
            <span className="text-custom-primary">0% Fee.</span>
          </p>
          <p className="text-center text-secondary text-[400] text-[14px] md:text-[24px]">
            Get your Personal/Business{" "}
            <span className="text-custom-primary">IBAN</span> within a day and
            exchange funds for crypto within seconds.
          </p>
        </div>
      </div>
      <img
        src="/images/sepa-card.svg"
        alt="Sepa Card"
        className="absolute top-[67%] md:top-[40%] right-[50px] xl:right-[200px] max-sm:w-[150px]"
      />
      <img
        src="/images/swift-card.svg"
        alt="Sepa Card"
        className="absolute top-[87%] md:top-[70%] left-[50px] xl:left-[200px] max-sm:w-[150px]"
      />
    </CommonHero>
  );
};

export default Hero;

export const cookiesPolicyBoxContents = [
  {
    title: "Analytics",
    description: "We use cookies to analyse users' activities in order to improve the Website. For example, cookies allow us to look at overall performance, such as the average number of searches performed by users. We may analyse this data to understand how to improve the functionality and usability of the Website."
  },
  {
    title: "Settings and user preferences",
    description: "We use cookies to collect certain user information, such as browser type, server, language and country settings, and to store user preferences to make our Website more user-friendly. For example, we may store information in a cookie about the most recent search queries you have made, so that you can easily retrieve them when you return to our Website or substitute your profile information when you register for an account. <br/>Cookies also prevent you from having to re-enter your account information when you return to our Website. We may also use your IP address to give you the most relevant search results based on your location (city or postcode)."
  },
  {
    title: "Providing performance measurement services, improving the effectiveness of targeting advertising and marketing",
    description: "We use cookies, web beacons and other similar technologies, including from our third party partners such as Google and Facebook, to provide performance measurement services, improve the effectiveness of targeted advertising and for marketing purposes. This happens when you visit our Website. These cookies, web beacons and other similar technologies allow us to show you our advertising material on other websites you visit online.<br/>Our third-party advertising partners may also use these technologies to identify your online interests at different times and on different websites in order to show you targeted ads and other content personalized to you.<br/>In addition, We may share information about your activities on the Website with third parties (including the operators of third-party websites) in order to show you targeted advertisements and other content that is personalized for you. We also use cookies to program targeted advertisements on our Website. We use cookies, web beacons and other similar technologies, including from third-party partners, in this program and when displaying other advertisements, to find out which ads users have seen, how many times they have been shown a particular ad and on which sites it appears.<br/>You may prohibit the lawful use of your Personal Information for marketing purposes. You may prohibit the collection and use of information to display targeted advertisements in accordance with the Privacy Policy."
  },
  {
    title: "Tracking of site-to-site conversions",
    description: "We use cookies, web beacons and other similar technologies, including from third-party partners, to match user activity with the third-party website from which a user comes to our Website or to match user activity that links to the third-party website from our Website. Some third-party affiliate websites receive remuneration for the activity of users they refer to our Website or, in turn, pass on to us remuneration for the activity of users who are redirected to them. We also use cookies and other technologies to correlate user activity with marketing material that contains a link to our Website."
  },
  {
    title: "Ensuring security",
    description: "We use cookies to protect against certain types of cyberattacks."
  },
  {
    title: "Testing as well as improving efficiency and usability",
    description: "We use cookies to provide a consistent interface during A/B testing of certain aspects of our Website to improve the products we offer. We also use cookies to improve the effectiveness and reliability of our Website."
  },
  {
    title: "Preventing fraud and spam",
    description: "We use cookies when we investigate fraudulent activity. Sometimes we outsource the tracking of cookies and devices to third parties, and sometimes we perform indexing ourselves to identify activities related to specific cookies."
  },
  {
    title: "Compliance with requirements",
    description: "We may use cookies to anonymise the data collected about traffic and activities associated with the use of our Website."
  },
]
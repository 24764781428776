import React from "react";
import Hero from "../components/banking/Hero";
import BankingServices from "../components/banking/BankingServices";
import WhyNiza from "../components/about/WhyNiza";

const Banking = () => {
  return (
    <div>
      <Hero />
      <BankingServices />
      <WhyNiza />
    </div>
  );
};

export default Banking;

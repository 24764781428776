import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ContactSalesTeam = () => {
  const [companyName, setCompanyName] = useState("");
  const [countryBusiness, setCountryBusiness] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactPrefernces, setContactPrefernces] = useState("Email");

  const [openSelect, setOpenSelect] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        openSelect
      ) {
        setOpenSelect(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef, openSelect]);

  return (
    <div className="relative md:h-[95vh] max-md:py-[100px] max-md:flex max-md:justify-center max-sm:py-20">
      <div className="absolute left-[-30%] top-[-10%] w-6 h-6 side-light-brighter max-md:hidden" />
      <div className="md:absolute top-[-10%] left-[50%] md:translate-x-[-50%] p-6 bg-[#16212F] md:w-[587px] w-[90%] rounded-xl border-[#353D45] border-[1px] z-10">
        <p className="text-xl text-custom-primary mb-3">Reach to our</p>
        <div className="flex justify-between items-center mb-6">
          <p className="md:text-[54px] text-[42px] text-white font-[600]">
            Sales Team
          </p>
          <img src="icons/EmailSalesIcon.png" alt="" />
        </div>
        <p className="text-base text-white font-[600] mb-3">Details</p>
        <form className="flex flex-col gap-6">
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-3 placeholder:text-dark-text/50 outline-none text-dark-text"
            placeholder="Company name*"
          />
          <input
            type="text"
            value={countryBusiness}
            onChange={(e) => setCountryBusiness(e.target.value)}
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-3 placeholder:text-dark-text/50 outline-none text-dark-text"
            placeholder="Country of Business*"
          />
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-3 placeholder:text-dark-text/50 outline-none text-dark-text"
            placeholder="First name*"
          />
          <input
            type="text"
            value={secondName}
            onChange={(e) => setSecondName(e.target.value)}
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-3 placeholder:text-dark-text/50 outline-none text-dark-text"
            placeholder="Last name*"
          />
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-3 placeholder:text-dark-text/50 outline-none text-dark-text"
            placeholder="Email address*"
          />
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-3 placeholder:text-dark-text/50 outline-none text-dark-text"
            placeholder="Phone number*"
          />
          <div
            className="bg-transparent border-t-none border-b-[1px] border-[#353D45]/50 w-full p-2 placeholder:text-dark-text/50 outline-none text-dark-text flex items-center justify-between relative cursor-pointer select-none"
            onClick={() => setOpenSelect(!openSelect)}
            ref={selectRef}
          >
            {contactPrefernces}
            <FontAwesomeIcon icon={openSelect ? faChevronUp : faChevronDown} className="text-dark-text text-xs"/>
            {openSelect && (
              <div className="absolute bg-card-dark border-[1px] border-[#353D45]/50 w-full top-[110%] left-0 rounded-lg">
                <div
                  className="hover:bg-white/10 p-2 rounded-lg"
                  onClick={() => {
                    setContactPrefernces("Phone Number");
                    setOpenSelect(false);
                  }}
                >
                  Phone Number
                </div>
                <div
                  className="hover:bg-white/10 p-2 rounded-lg"
                  onClick={() => {
                    setContactPrefernces("Email");
                    setOpenSelect(false);
                  }}
                >
                  Email
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <input type="checkbox" className="cursor-pointer"/>
            <p className="text-sm text-white">
              By continuing I agree to the{" "}
              <Link to="/terms">
                <span className="text-custom-primary underline">
                  Terms of Service
                </span>{" "}
                &{" "}
              </Link>
              <Link to="/privacy-policy">
                <span className="text-custom-primary underline">
                  Privacy Policy
                </span>
              </Link>
            </p>
          </div>
          <a
            href={`mailto:sales@niza.io?subject=${encodeURIComponent(
              `${firstName} ask to Sales Team`
            )}&body=${encodeURIComponent(
              `Company Name: ${companyName}
               Country of Business: ${countryBusiness}
               First Name: ${firstName}
               Last Name: ${secondName}
               Email: ${email}
               Phone Number: ${phoneNumber}
               Contact Preference: ${contactPrefernces}
              `
            )}`}
            className="
              bg-custom-primary border-[1px] border-custom-primary rounded-lg py-2 px-4 font-[600] text-base hover:bg-transparent hover:text-custom-primary 
              transition-colors text-center
            "
          >
            Send
          </a>
        </form>
      </div>
    </div>
  );
};

export default ContactSalesTeam;

import React from "react";
import Hero from "../components/termsAndConditions/Hero";
import Content from "../components/termsAndConditions/Content";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <>
    <Helmet>
      <title>Niza - Terms & Conditions</title>
    </Helmet>
    <div>
      <Hero />
      <Content />
    </div>
    </>
  );
};

export default TermsAndConditions;

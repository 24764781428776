import React from "react";
import { OpeningSoonContext } from "../../context/openingSoonContext";
import { useContext } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import Snackbar from "../alert/Snackbar";
import SubFooter from "./SubFooter";
import AcceptCookies from "../bar/AcceptCookies";
import OpeningSoon from "../alert/OpeningSoon";
import DownloadNiza from "../bar/DownloadNiza";

const Layout = ({ children }) => {
  const { warning } = useContext(OpeningSoonContext);

  return (
    <div className="bg-gradient-to-b from-[#0E141C] to-[#0E1C24] scrollbar scrollbar-thumb-rose-500 relative">
      {/* bg-[#09090B] */}
      <Navigation />
      {children}
      {warning ? <OpeningSoon /> : null}
      <Footer />
      <SubFooter />
      <Snackbar />
      <AcceptCookies />
      <DownloadNiza />
    </div>
  );
};

export default Layout;

import React from 'react'

const Unverified = (props) => {
  return (
    <div className='flex flex-col items-center justify-center gap-10 relative max-sm:gap-5' style={{ fontFamily: "Geist Variable" }}>
        <div className='absolute top-1 right-3 cursor-pointer' onClick={props.closeModal}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g opacity="0.5"><path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg></div>
        <div className='flex flex-col items-center gap-6 max-sm:gap-4'>
            <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none"><path d="M24.3333 24.3333L37.6667 37.6667M37.6667 24.3333L24.3333 37.6667M31 1C55 1 61 7 61 31C61 55 55 61 31 61C7 61 1 55 1 31C1 7 7 1 31 1Z" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <div className='flex flex-col gap-2 items-center'>
                <p className='text-red-500 text-3xl max-sm:text-2xl'>Unverified Source</p>
                <p className='text-xl text-white max-sm:text-base'>Your search [{props.link}] is not a verified Niza Global official source</p>
            </div>
        </div>
        <div className='px-9 max-sm:px-0'>
            <p className='text-base text-white/50 font-light max-sm:text-sm'>Please verify that the information and the format you entered are correct:</p>
            <ul className='list-disc list-inside text-white/50 max-sm:text-sm'>
                <li className='pl-3'>Mobile number: xx-xxxxxxxxx</li>
                <li className='pl-3'>Telegram/X/Facebook/Youtube: @xxxxx (with @ symbol)</li>
                <li className='pl-3'>TikTok/Instagram: xxxx (without @ symbol)</li>
                <li className='pl-3'>Discord: xxxx#xxxx (without @ symbol)</li>
                <li className='pl-3'>LinkedIn/Telegram group/Medium/Reddit: https://xxxxx (website link)</li>
            </ul>
            <p className='text-base text-white/50 font-light mt-3 max-sm:text-sm'>If you have questions about the format, please feel free to contact Customer Service.</p>
        </div>
        <div className='flex flex-col gap-12 max-sm:gap-8'>
            <div className='max-w-[484px]'>
                <p className='text-base text-white/50 font-light max-sm:text-sm'>*Do not disclose your account information and password to anyone.</p>
                <p className='text-base text-white/50 font-light max-sm:text-sm'>*Beware of scams and operate with caution. For more information, please refer to the 
                    <a href="https://help.niza.io/hc/en-us" className='text-custom-primary hover:underline hover:decoration-custom-primary cursor-pointer'> Help Manual</a>.
                </p>
            </div>
            <button className='w-full bg-custom-primary text-gray-800 rounded-md py-3 px-5 font-semibold max-sm:py-2.5 max-sm:text-sm' onClick={props.closeModal}>OK</button>
        </div>
    </div>
  )
}

export default Unverified
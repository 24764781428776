import React from "react";
import { Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../lib/hooks/WindowSizeHook";

const NizaTrade = () => {
  const { width } = useWindowSize();

  const { ref } = useInView({
    threshold: 0,
  });

  return (
    <Parallax speed={width < 700 ? 30 : 40}>
      <div className={`lg:mb-40`} ref={ref}>
        <div className="container text-center max-sm:pt-6">
          <p className="text-[32px] text-custom-primary font-light pb-[12px] max-sm:font-normal">
            NIZA TRADE
          </p>
          <p className="text-[36px] md:text-[54px] text-custom-primary font-semibold pb-9">
            Trade Like a Pro{" "}
            <span className="text-white">
              all {width < 700 ? <br /> : null}in one Powerful Interface
            </span>
          </p>
        </div>
        <div className="flex justify-center relative h-fit">
          <div className="border-[5px] border-custom-secondary w-fit rounded-2xl max-md:max-w-[90%]">
            <img
              src="images/nizaWebApp.png"
              alt=""
              className="2xl:max-w-[1424px] xl:max-w-[1024px] lg:max-w-[900px] md:max-w-[600px] "
            />
          </div>
          <div class="fade-overlay"></div>
          <div className="absolute top-[80%] flex max-lg:flex-col gap-8 max-md:w-[80%] max-sm:gap-6">
            <div
              className="bg-card-dark 2xl:max-w-[348px] md:max-w-[308px] w-full p-6 text-center flex flex-col gap-4 rounded-xl border-[#353D45] border justify-between 
                  max-sm:gap-2 max-sm:p-4"
            >
              <p className="text-2xl text-white">Trade like a Pro</p>
              <p className="text text-gray-300 max-sm:text-sm">
                Be on top of your game with the data presented in a clean way{" "}
              </p>
              <div className="flex justify-center">
                <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
                  <p className="text-custom-primary cursor-pointer hover:decoration-custom-primary hover:underline">
                    Learn More
                  </p>
                </Link>
              </div>
            </div>
            <div
              className="bg-card-dark 2xl:max-w-[348px] md:max-w-[308px] w-full p-6 text-center flex flex-col gap-4 rounded-xl border-[#353D45] border justify-between 
                  max-sm:gap-2 max-sm:p-4"
            >
              <p className="text-2xl text-white">Analyze the market</p>
              <p className="text text-gray-300 max-sm:text-sm">
                Evaluate all the factors and data before making the right move.{" "}
              </p>
              <div className="flex justify-center">
                <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
                  <p className="text-custom-primary cursor-pointer hover:decoration-custom-primary hover:underline">
                    Learn More
                  </p>
                </Link>
              </div>
            </div>
            <div
              className="bg-card-dark 2xl:max-w-[348px] md:max-w-[308px] w-full p-6 text-center flex flex-col gap-4 rounded-xl border-[#353D45] border justify-between 
                  max-sm:gap-2 max-sm:p-4"
            >
              <p className="text-2xl text-white">Make informed decisions</p>
              <p className="text text-gray-300 max-sm:text-sm">
                Get better insights on market trends with a state-of-the art
                display.{" "}
              </p>
              <div className="flex justify-center">
                <Link to={process.env.REACT_APP_SUPPORT_CENTER}>
                  <p className="text-custom-primary cursor-pointer hover:decoration-custom-primary hover:underline">
                    Learn More
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default NizaTrade;

import React from "react";
import Hero from "../components/referralAndBonuses/Hero";
import Commission from "../components/referralAndBonuses/Commission";
import { Helmet } from "react-helmet";

const ReferralAndBonuses = () => {
  return (
    <>
      <Helmet>
        <title>Niza - Referral & Bonuses</title>
      </Helmet>
      <div>
        <Hero />
        <Commission />
      </div>
    </>
  );
};

export default ReferralAndBonuses;

import React from "react";
import { Link } from "react-router-dom";

import "../../styles/allCoins.css";

const Hero = () => {
  return (
    <div className="bg-[#142128] min-h-[100vh] flex items-center relative overflow-hidden">
      <div className="absolute md:right-[-20%] md:top-[-7%] max-md:bottom-[-25%] max-md:right-[-60%] w-10 h-10 side-light-brighter " />
      <img
        src="/icons/comissionNiza.png"
        alt=""
        className="absolute left-7 bottom-20"
      />
      <div className="container flex max-lg:flex-col max-lg:gap-[60px] justify-between items-center max-lg:py-[160px] relative">
        <img src="/icons/comissionNiza2.png" alt="" className="absolute top-[-10%] left-[40%]" />
        <div className="lg:w-[45%] w-full">
          <p className="text-[36px] md:text-[54px] text-white font-[600]">Invite Friends</p>
          <p className="text-[36px] md:text-[54px] text-white font-[600]">
            Earn <span className="text-custom-primary">30%</span> Commission
          </p>
          <p className="text-white text-lg w-[80%] mt-8">
            Invite your friends to join NIZA and enjoy the journey together.
            You'll earn commissions when they join the community. Sharing the
            joy adds a touch of magic to your experience!
          </p>
          <Link to={process.env.REACT_APP_SIGN_IN}>
            <div className="bg-custom-primary border-[1px] border-custom-primary rounded-lg w-fit p-4 font-[600] mt-8 cursor-pointer hover:bg-transparent hover:text-custom-primary transition-colors max-sm:py-3">
            Refer Friends
            </div>
          </Link>
        </div>
        <div className="lg:w-[40%] w-full">
          <img src="referral.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;

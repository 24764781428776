export const allCoins = [
  [
    {
      name: "Dogecoin",
      icon: "/icons/cryptoIcons/dogecoin.svg",
    },
    {
      name: "Dogecoin",
      icon: "/icons/cryptoIcons/dogecoin.svg",
    },
    {
      name: "Polkadot",
      icon: "/icons/cryptoIcons/polkadot.svg",
    },
    {
      name: "Solana",
      icon: "/icons/cryptoIcons/solana.svg",
    },
    {
      name: "Ripple",
      icon: "/icons/cryptoIcons/ripple.svg",
    },
    {
      name: "Tether",
      icon: "/icons/cryptoIcons/tether.svg",
    },
    {
      name: "Cardano",
      icon: "/icons/cryptoIcons/cardano.svg",
    },
    {
      name: "Ethereum",
      icon: "/icons/cryptoIcons/ethereum.svg",
    },
    {
      name: "Bitcoin",
      icon: "/icons/cryptoIcons/bitcoin.svg",
    },
    {
      name: "Cardano",
      icon: "/icons/cryptoIcons/cardano.svg",
    },

    {
      name: "Cardano",
      icon: "/icons/cryptoIcons/cardano.svg",
    },
  ],
  [
    {
      name: "TRON",
      icon: "/icons/cryptoIcons/tron.svg",
    },
    {
      name: "TRON",
      icon: "/icons/cryptoIcons/tron.svg",
    },
    {
      name: "Filecoin",
      icon: "/icons/cryptoIcons/filecoin.svg",
    },
    {
      name: "Polygon",
      icon: "/icons/cryptoIcons/polygon.svg",
    },
    {
      name: "Monero",
      icon: "/icons/cryptoIcons/monero.svg",
    },
    {
      name: "Aave",
      icon: "/icons/cryptoIcons/aave.svg",
    },
    {
      name: "Aion",
      icon: "/icons/cryptoIcons/aion.svg",
    },
    {
      name: "AChain",
      icon: "/icons/cryptoIcons/achain.svg",
    },
    {
      name: "Pascal Lite",
      icon: "/icons/cryptoIcons/pascallite.svg",
    },
    {
      name: "Aion",
      icon: "/icons/cryptoIcons/aion.svg",
    },
    {
      name: "Aion",
      icon: "/icons/cryptoIcons/aion.svg",
    },
  ],
  [
    {
      name: "NEOS",
      icon: "/icons/cryptoIcons/neos.svg",
    },
    {
      name: "Air Swap",
      icon: "/icons/cryptoIcons/airswap.svg",
    },
    {
      name: "NEOS",
      icon: "/icons/cryptoIcons/neos.svg",
    },
    {
      name: "Tezos",
      icon: "/icons/cryptoIcons/tezos.svg",
    },
    {
      name: "Aave",
      icon: "/icons/cryptoIcons/aave.svg",
    },
    {
      name: "Dai",
      icon: "/icons/cryptoIcons/dai.svg",
    },
    {
      name: "Cosmos",
      icon: "/icons/cryptoIcons/cosmos.svg",
    },
    {
      name: "Waves",
      icon: "/icons/cryptoIcons/waves.svg",
    },
    {
      name: "Ethereum Classic",
      icon: "/icons/cryptoIcons/ethereumClassic.svg",
    },
    {
      name: "NEOS",
      icon: "/icons/cryptoIcons/neos.svg",
    },
    {
      name: "NEOS",
      icon: "/icons/cryptoIcons/neos.svg",
    },
  ]
];


export const tableCoins = [
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin pg2",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin pg3",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin pg4",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
  [
    {
      coin: "bitcoin",
      name: "Bitcoin",
      code: "BTC",
      favourite: false,
    },
    {
      coin: "ethereum",
      name: "Ethereum",
      code: "ETH",
      favourite: false,
    },
    {
      coin: "tether",
      name: "Tether",
      code: "USDT",
      favourite: false,
    },
    {
      coin: "solana",
      name: "Solana",
      code: "SOL",
      favourite: false,
    },
    {
      coin: "bnb",
      name: "BNB",
      code: "BNB",
      favourite: false,
    },
    {
      coin: "xrp",
      name: "XRP",
      code: "XRP",
      favourite: false,
    },
  ],
];
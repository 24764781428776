import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Router from "./router/Router";
import Layout from "./components/layout/Layout";
import { ParallaxProvider } from "react-scroll-parallax";
import ScrollToTop from "./components/ScrollToTop";
import { Suspense } from "react";
import { OpeningSoonProvider } from "./context/openingSoonContext";

function App() {
  return (
    <ParallaxProvider>
      <OpeningSoonProvider>
        <Layout>
          <Router />
          <ScrollToTop />
        </Layout>
      </OpeningSoonProvider>
    </ParallaxProvider>
  );
}

export default function WrappedApp() {
  return (
    <Suspense>
      <App />
    </Suspense>
  )
}

import React, {useState} from 'react'
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import axios from 'axios';
import Verified from '../components/nizaVerify/Verified';
import Unverified from '../components/nizaVerify/Unverified';

const NizaVerify = () => {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const [errorNoURL, setErrorNoURL] = useState(false);

    const [verifyURL, setVerifyURL] = useState('');
    const [isOfficialSource, setIsOfficialSource] = useState([]);

    const change = (event) => {
        setVerifyURL(event.target.value);
        setErrorNoURL(false);
    }

    const checkURL = async () => {
        if (verifyURL === '') {
            setErrorNoURL(true);
            return;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_OFFICIAL_SOURCE}`, {
                params: { source: verifyURL }
            });
            setIsOfficialSource(response.data);
        } catch (error) {
            console.log(error);
            setIsOfficialSource(false);
        }
        setOpen(true);
    }

  return (
    <div className='max-md:h-[720px] h-[100vh] bg-[#141A20] relative fade-bottom' style={{ fontFamily: "Geist Variable" }}>
        <div className='flex justify-center items-center h-full container'>
            <div className='max-w-3xl flex flex-col items-center justify-center gap-10 max-md:mb-40 max-sm:gap-6'>
                <div className='flex flex-col items-center gap-5 max-sm:gap-3'>
                    <img src="/niza-verify.png" alt="Niza Verify" />
                    <p className='text-center text-neutral-400 text-base max-w-[720px]'>
                        On this page, you can verify whether a URL, email address, phone number, or social media account like X and Telegram, is a verified Niza official 
                        source. This will help you avoid losing your assets to phishing and online scams.
                    </p>
                </div>
                <div className='flex flex-col items-center gap-2 w-full relative cursor-pointer z-10'>
                    <input
                        value={verifyURL}
                        onChange={change}
                        type="text" 
                        placeholder='Please enter the information in full and in the correct form for verification'
                        onKeyDown={(e) => { if (e.key === "Enter") {checkURL();} }}
                        className='
                            w-full border border-neutral-500 rounded-[38px] py-3 pr-3 pl-6 text-neutral-300 text-sm placeholder-neutral-500 
                            bg-transparent max-sm:pr-8 max-sm:placeholder:text-[13px]
                        ' 
                    />
                    <div className='absolute right-3 top-3' onClick={checkURL}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.5 5.00391L12.5 10.0039L7.5 15.0039" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    {errorNoURL && <p className='text-red-600 max-sm:text-sm'>Enter a URL to check validity</p>}
                </div>
            </div>
        </div>
        <Popup
            open={open}
            closeOnDocumentClick
            position={"right bottom"}
            modal={true}
            onClose={closeModal}
            className={`${isOfficialSource?.success ? '' : 'popup-niza-verify-margin'} popup-niza-verify`}
        >
            {isOfficialSource?.success && <Verified link={verifyURL} closeModal={closeModal}/>}
            {isOfficialSource?.success === false && <Unverified link={verifyURL} closeModal={closeModal}/>}
        </Popup>
    </div>
  )
}

export default NizaVerify
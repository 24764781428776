import React, { useState, useEffect } from 'react';
import Carousel from "react-slick";

const CarouselBanking = () => {
    const cardData = [
        {
            icon: "/icons/sepa-transfer.svg",
            title: "Euro Zone Transfers",
            description:
                "Single Euro Payments Area (SEPA) is our area of expertise since we started our operations. Our experienced team is here to assist you in all things SEPA.",
        },
        {
            icon: "/icons/swift-international.svg",
            title: "International Transfers (SWIFT)",
            description:
            "With Niza Global you can send funds all over the world in EUR, USD, GBP.",
        },
        {
            icon: "/icons/crypto-exchange.svg",
            title: "Crypto Exchange",
            description:
            "Buy and sell 70+ foreign cryptocurrencies without any hidden fees with Niza Global.",
        },
        {
            icon: "/icons/internal-transfers.svg",
            title: "Internal Transfers",
            description:
            "Niza Global provides a possibility to send funds between its clients completely free of charge.",
        },
      ];

    const [horizontal, setHorizontal] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth <= 768) {
            setHorizontal(false);
        } else {
            setHorizontal(true);
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: horizontal ? 3 : 1,
        slidesToScroll: 1,
        vertical: horizontal,
        verticalSwiping: horizontal,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
              },
            },
        ],
        // className: "md:top-[-45px]" // use to move item vertically
    };

  return (
    <div className='w-full md:w-1/2 h-full overflow-hidden pl-20 max-lg:pl-4 max-md:px-6 max-md:pb-6'>
        <Carousel {...settings}>
            {cardData.map((item, indx) => (
                <div className='border-2 border-[#FFFFFF1A] rounded-[18px] p-6 !flex flex-col gap-3 w-[480px] flex-1 md:mb-2.5 max-md:mr-4 max-sm:p-4'>
                    <div className='flex flex-col gap-4 max-md:gap-0 max-md:flex-row-reverse max-md:justify-between max-md:items-center'>
                        {indx === 0 && <img src={item.icon} alt="Sepa" className="w-24" />} 
                        {indx !== 0 && <div className='rounded-full p-3 bg-[#aaaa22] w-16 max-md:w-12'><img src={item.icon} alt="Bank" className="" /></div>}
                        <p className='text-xl text-white font-medium max-sm:text-lg'>{item.title}</p>
                    </div>
                    <p className='text-sm text-gray-300 max-sm:text-xs'>{item.description}</p>
                </div>
            ))}
      </Carousel>
    </div>
  )
}

export default CarouselBanking
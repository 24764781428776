import React from "react";

const Pricing = () => {
  return (
    <div className="mb-24 relative">
      <div className="absolute left-[-30%] top-[20%] w-6 h-6 side-light-brighter max-md:hidden" />
      <div className="absolute right-[-30%] bottom-[-40%] w-6 h-6 side-light-brighter max-md:hidden" />
      <p className="text-[36px] md:text-[54px] container text-white text-center font-medium py-[35px]">
        Account Pricing
      </p>
      <div className="flex max-lg:flex-col gap-[20px] container md:px-[100px] xl:px-[177px] my-9">
        <div className="bg-gradient-to-b from-[#ffffff12] to-[#ffffff05] w-full lg:w-[50%] rounded-lg p-6">
          <p className="text-[32px] text-white">Individual</p>
          <p className="text-[18px] text-custom-primary py-3  font-semibold">
            SEPA PAYMENTS (EUR)
          </p>
          <p className="text-white/90">IBAN Deposit Fee SEPA:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0€ - 50K€</p>
              <p className="text-white font-[500]">0.20% min €5</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">50K€ +</p>
              <p className="text-white font-[500]">0.35%</p>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Deposit SEPA return fee:</p>
            <p className="text-white font-[500]">0.20% min €5</p>
          </div>
          <div className="flex justify-between mt-5">
            <p className="text-white/90">IBAN Withdrawal To Bank Fee:</p>
            <p className="text-white font-[500]">0.20% min €5</p>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Monthly IBAN account fee:</p>
            <p className="text-white font-[500]">0€</p>
          </div>
          <hr className="h-px my-5 bg-darker-text border-0" />
          <p className="text-custom-primary text-[18px] font-semibold">
            SWIFT PAYMENTS (EUR, GBP, USD)
          </p>
          <p className="text-white/90 mt-4">IBAN Deposit Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">EUR</p>
              <p className="text-white font-[500]">0.30% min €25</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">GBP</p>
              <p className="text-white font-[500]">0.20% min £35</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">USD</p>
              <p className="text-white font-[500]">0.30% min $70</p>
            </div>
          </div>

          <p className="text-white/90 mt-4">IBAN Withdrawal To Bank Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">EUR</p>
              <p className="text-white font-[500]">0.35% min €35</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">GBP</p>
              <p className="text-white font-[500]">0.15% min £35</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">USD</p>
              <p className="text-white font-[500]">0.30% min $70</p>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Withdrawal Trace, Recall, Amend:</p>
            <p className="text-white font-[500]">100€</p>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Monthly IBAN account fee:</p>
            <p className="text-white font-[500]">0€</p>
          </div>
          <hr className="h-px my-5 bg-darker-text border-0" />
          <p className="text-custom-primary text-[18px] font-semibold">
            PAYMENTS WITHIN UK (FasterPayments and CHAPS)
          </p>
          <p className="text-white/90 mt-3">Deposit Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0 - £250k</p>
              <p className="text-white font-[500]">£2</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">£250k +</p>
              <p className="text-white font-[500]">£25</p>
            </div>
          </div>
          <p className="text-white/90 mt-4">Deposit return fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0 - £250k</p>
              <p className="text-white font-[500]">£2</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">£250k +</p>
              <p className="text-white font-[500]">£25</p>
            </div>
          </div>
          <p className="text-white/90 mt-4">Withdrawal To Bank Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0 - £250k</p>
              <p className="text-white font-[500]">£2</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">£250k +</p>
              <p className="text-white font-[500]">£25</p>
            </div>
          </div>
          <hr className="h-px my-5 bg-darker-text border-0" />
          <p className="text-custom-primary text-[18px] font-semibold">
            ADDITIONAL SERVICES OPERATIONS
          </p>
          <div className="flex flex-col gap-[14px] mt-[14px]">
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">Setup Fee:</p>
              <p className="text-white font-[500]">3€</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">
                IBAN Reactivation fee (applicable if the account had no incoming
                our outgoing transactions for 90 calendar days):
              </p>
              <p className="text-white font-[500]">5€</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">Foreign exchange:</p>
              <p className="text-white font-[500]">1%</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">WIRE transfer return fee:</p>
              <p className="text-white font-[500]">50€</p>
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#ffffff12] to-[#ffffff05] w-full lg:w-[50%] rounded-lg p-6">
          <p className="text-[32px] text-white">Corporate</p>
          <p className="text-[18px] text-custom-primary py-3  font-semibold">
            SEPA PAYMENTS (EUR)
          </p>
          <p className="text-white/90">IBAN Deposit Fee SEPA:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0€ - 50K€</p>
              <p className="text-white font-[500]">0.75% min €10</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">50K€ +</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Deposit SEPA return fee:</p>
            <p className="text-white font-[500]">0.75% min €10</p>
          </div>
          <div className="flex justify-between mt-5">
            <p className="text-white/90">IBAN Withdrawal To Bank Fee:</p>
            <p className="text-white font-[500]">0.75% min €10</p>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Monthly IBAN account fee:</p>
            <p className="text-white font-[500]">250€</p>
          </div>
          <hr className="h-px my-5 bg-darker-text border-0" />
          <p className="text-custom-primary text-[18px] font-semibold">
            SWIFT PAYMENTS (EUR, GBP, USD)
          </p>
          <p className="text-white/90 mt-4">IBAN Deposit Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">EUR</p>
              <p className="text-white font-[500]">0.75% min €50</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">GBP</p>
              <p className="text-white font-[500]">0.75% min £50</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">USD</p>
              <p className="text-white font-[500]">0.75% min $140</p>
            </div>
          </div>

          <p className="text-white/90 mt-4">IBAN Withdrawal To Bank Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">EUR</p>
              <p className="text-white font-[500]">0.75% min €70</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">GBP</p>
              <p className="text-white font-[500]">0.75% min £70</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">USD</p>
              <p className="text-white font-[500]">0.75% min $140</p>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Withdrawal Trace, Recall, Amend:</p>
            <p className="text-white font-[500]">200€</p>
          </div>
          <div className="flex justify-between mt-4">
            <p className="text-white/90">Monthly IBAN account fee:</p>
            <p className="text-white font-[500]">250€</p>
          </div>
          <hr className="h-px my-5 bg-darker-text border-0" />
          <p className="text-custom-primary text-[18px] font-semibold">
            PAYMENTS WITHIN UK (FasterPayments and CHAPS)
          </p>
          <p className="text-white/90 mt-3">Deposit Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0 - £250k</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">£250k +</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
          </div>
          <p className="text-white/90 mt-4">Deposit return fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0 - £250k</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">£250k +</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
          </div>
          <p className="text-white/90 mt-4">Withdrawal To Bank Fee:</p>
          <div className="bg-[#ffffff12] p-4 rounded-lg mt-4 flex flex-col gap-3">
            <div className="flex justify-between">
              <p className="text-white/70">0 - £250k</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70">£250k +</p>
              <p className="text-white font-[500]">0.75%</p>
            </div>
          </div>
          <hr className="h-px my-5 bg-darker-text border-0" />
          <p className="text-custom-primary text-[18px] font-semibold">
            ADDITIONAL SERVICES OPERATIONS
          </p>
          <div className="flex flex-col gap-[14px] mt-[14px]">
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">Setup Fee:</p>
              <p className="text-white font-[500]">1,500€</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">
                IBAN Reactivation fee (applicable if the account had no incoming
                our outgoing transactions for 90 calendar days):
              </p>
              <p className="text-white font-[500]">50€</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">Foreign exchange:</p>
              <p className="text-white font-[500]">2%</p>
            </div>
            <div className="flex justify-between">
              <p className="text-white/70 w-[50%]">Deposit WIRE transfer return fee:</p>
              <p className="text-white font-[500]">100€</p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-white/70 text-center container mb-9 max-sm:text-sm">
        The pricing group assigned during onboarding by our Onboarding team is
        subject to change. <br />
        Card services will become available in the near future. *SWIFT and
        Multi-currency pricing available on special request.
      </p>
    </div>
  );
};

export default Pricing;

import React from "react";
import Hero from "../components/disclosures/Hero";
import Content from "../components/disclosures/Content";
import { Helmet } from "react-helmet";

const Disclosures = () => {
  return (
    <>
      <Helmet>
        <title>Niza - Disclosures</title>
      </Helmet>
      <div>
        <Hero />
        <Content />
      </div>
    </>
  );
};

export default Disclosures;

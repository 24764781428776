import React from "react";
import parse from "html-react-parser";
import { complaints } from "../../lib/data/complaintsPolicy";

const Content = () => {
  return (
    <div className="container py-[150px]">
      <div className="flex flex-col gap-10">
        {complaints.map((term) => (
          <div>
            <p className="text-white text-lg font-semibold mb-3">{term.title}</p>
            <div className="text-dark-text">
              {parse(term.description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;

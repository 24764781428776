import React from "react";
import { disclosuresText } from "../../lib/data/disclosures";
import { disclosureCountries } from "../../lib/data/disclosures";

const Content = () => {
  return (
    <div className="container pt-[42px] pb-[150px]">
      <p className="text-white mb-5 text-lg">
        Niza <span className="text-custom-primary">Global</span> LEGAL
        DISCLOSURES
      </p>
      <p className="text-dark-text text-base mb-5">
        Last updated: Oct 01, 2023
      </p>
      <p className="text-dark-text text-base mb-5">
        PLEASE READ THESE LEGAL DISCLOSURES CAREFULLY. BY ACCESSING OR USING THE
        SERVICES, YOU AGREE TO BE LEGALLY BOUND BY THESE DISCLOSURES.
      </p>
      {disclosuresText.map((text) => (
        <p className="text-dark-text text-base mb-5">{text}</p>
      ))}
      <div className="flex flex-col gap-8 mt-9">
        {disclosureCountries.map((country) => (
          <div className="bg-card-dark border-[#353D45] border-[1px] rounded-2xl p-6 flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <img src={country.img} alt="" />
                <p className="text-white font-[600] text-lg">{country.name}</p>
              </div>
              <div>
                <p className="text-custom-primary sm:text-lg text-sm">
                  ID: {country.id}
                </p>
              </div>
            </div>
            <p className="text-base text-dark-text font-[400]">
              {country.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;

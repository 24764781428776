import React from "react";
import Hero from "../components/bankingFees/Hero";
import Pricing from "../components/bankingFees/Pricing";

const BankingFees = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <Pricing />
    </div>
  );
};

export default BankingFees;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { OpeningSoonContext } from "../../context/openingSoonContext";

const OpeningSoon = () => {
  const { setWarning } = useContext(OpeningSoonContext);

  return (
    <div className="fixed top-0 left-0 z-20 bg-black/40 w-[100vw] h-[100vh]">
      <div className="flex justify-center ">
        <div className="w-[300px] md:w-[430px] relative">
          <img src="images/openningSoon.png" alt="" className="openningSoon"/>
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => setWarning(false)}
            className="absolute max-md:top-[100px] max-md:right-[10px] top-[145px] right-[20px] text-xl cursor-pointer openningSoon"
          />
        </div>
      </div>
    </div>
  );
};

export default OpeningSoon;
// left-[50%] translate-x-[-50%]

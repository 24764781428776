import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const AcceptCookies = () => {
  const [acceptCookies, setAcceptCookies] = useState(undefined);
  const [openCookieSettings, setOpenCookieSettings] = useState(false);

  const [strictlyNecesaryCookies, setStrictlyNecesaryCookies] = useState(true);
  const [performance, setPerformance] = useState(true);
  const [tagertingCookies, setTagertingCookies] = useState(true);

  useEffect(() => {
    if(!localStorage.getItem("accept-cookies")){
      localStorage.setItem("accept-cookies", undefined);
    }
  }, []);

  function saveCookieSettings(acceptance) {
    localStorage.setItem("accept-cookies", acceptance);
  }

  return (
    <>
      {localStorage.getItem("accept-cookies") !== "undefined" ? null : (
        <div className="fixed bottom-0 left-0 w-full z-20">
          <div className="bg-custom-primary p-6 flex max-md:flex-col items-center gap-3 w-full">
            <p className="md:w-[42%] w-full text-sm">
              By selecting Accept, you agree to the use of cookies and similar
              technologies for marketing, analytics, and advertising purposes.
              Visit Cookie Settings to understand how NIZA utilizes cookies and
              to customize your preferences.
            </p>
            <div className="flex items-center max-md:w-full gap-3">
              <div
                className="bg-black font-[600] text-custom-primary px-6 py-4 w-fit border-[1px] border-black rounded-lg cursor-pointer hover:bg-custom-primary hover:text-black transition-colors"
                onClick={() => {
                  setAcceptCookies(true);
                  saveCookieSettings(true);
                }}
              >
                Accept
              </div>
              <div
                className="text-black font-[600] bg-custom-primary border-[1px] border-black px-6 py-4 w-fit rounded-lg cursor-pointer hover:bg-black hover:text-custom-primary transition-colors"
                onClick={() => {
                  setAcceptCookies(true);
                  setOpenCookieSettings(true);
                }}
              >
                Cookie Settings
              </div>
            </div>
          </div>
        </div>
      )}
      {openCookieSettings ? (
        <div className="fixed left-0 top-0 w-[100vw] h-[100vh] bg-black/60 flex justify-center items-center z-30">
          <div className="bg-card-dark rounded-xl sm:max-w-[500px] max-w-[90%]">
            <div className="p-6 pb-4 border-b-[1px] border-[#353D45]">
              <div className="flex justify-between ">
                <img src="/logo.png" alt="" className="w-[130px] h-[27.56px]" />
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={() => {
                    setOpenCookieSettings(false);
                  }}
                  className="text-dark-text text-2xl cursor-pointer"
                />
              </div>
            </div>
            <div className="p-6">
              <p className="text-white font-[600]">Privacy Preference Center</p>
              <p className="text-dark-text text-sm font-[400] mt-2">
                Choose the cookies you want to enable. Enabling the strictly
                necessary cookies helps you navigate Niza properly by enabling
                its functionalities. Enabling the performance cookies helps us
                enhance the user's experience in Niza. Enabling the targeting
                cookies helps you see ads regarding your primary interests.
              </p>
              <div
                className="text-black bg-custom-primary px-3 py-2 w-fit border-[1px] border-custom-primary rounded-lg cursor-pointer hover:bg-transparent hover:text-custom-primary transition-colors font-[600] mt-4 mb-6"
                onClick={() => {
                  setOpenCookieSettings(false);
                  saveCookieSettings(true);
                }}
              >
                Allow All
              </div>
              <p className="text-white font-[600]">
                Manage Consent Preferences
              </p>
              <div className="border-[1px] border-[#353D45] rounded-lg p-4 mt-2 flex flex-col gap-6">
                <div className="flex justify-between items-center">
                  <p className="text-white font-[500] text-sm">
                    Strictly Necessary Cookies
                  </p>
                  <div
                    className="border-[1px] border-[#353D45] rounded-full cursor-pointer"
                    onClick={() =>
                      setStrictlyNecesaryCookies(!strictlyNecesaryCookies)
                    }
                  >
                    <div
                      className={`w-5 h-5 bg-white rounded-full ${
                        strictlyNecesaryCookies ? "mr-5" : "ml-5"
                      }`}
                    ></div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-white font-[500] text-sm">Performance</p>
                  <div
                    className="border-[1px] border-[#353D45] rounded-full cursor-pointer"
                    onClick={() => setPerformance(!performance)}
                  >
                    <div
                      className={`w-5 h-5 bg-white rounded-full ${
                        performance ? "mr-5" : "ml-5"
                      }`}
                    ></div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-white font-[500] text-sm">
                    Tagerting Cookies
                  </p>
                  <div
                    className="border-[1px] border-[#353D45] rounded-full cursor-pointer"
                    onClick={() => setTagertingCookies(!tagertingCookies)}
                  >
                    <div
                      className={`w-5 h-5 bg-white rounded-full ${
                        tagertingCookies ? "mr-5" : "ml-5"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              {/* Buttons */}
              <div className="flex items-center gap-4 mt-6">
                <div
                  className="w-fit bg-transparent border-custom-primary border-[1px] rounded-lg text-custom-primary px-3 py-2 hover:bg-custom-primary hover:text-black transition-colors cursor-pointer"
                  onClick={() => {
                    setOpenCookieSettings(false);
                    saveCookieSettings(false);
                  }}
                >
                  Reject All
                </div>
                <div
                  className="text-black bg-custom-primary px-3 py-2 grow border-[1px] border-custom-primary rounded-lg cursor-pointer hover:bg-transparent hover:text-custom-primary transition-colors font-[600] text-center"
                  onClick={() => {
                    setOpenCookieSettings(false);
                    saveCookieSettings(true);
                  }}
                >
                  Confirm My Choices
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AcceptCookies;

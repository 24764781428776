import React, { useEffect, useState } from "react";
import { cookiesPolicyBoxContents } from "../../lib/data/cookiesAndPolicy";
import parse from "html-react-parser";

const Content = () => {

  return (
    <div className="container py-[150px] flex flex-col gap-[42px]">
      <div>
        <p className="text-lg text-white font-[600] mb-3">
          1. Introduction. What is a cookie?
        </p>
        <p className="text-base text-dark-text">
          This Cookie Policy explains when and for what purpose cookies may be
          sent to visitors to the Website. Cookies are text-only data that a
          website transmits for the purpose of saving information to a user's
          hard drive or other Internet-connected device. Cookies enable the
          Website to remember important information that makes the user's
          experience more convenient. A cookie will typically contain the name
          of the domain to which it refers, the expiry date, and a randomly
          assigned unique number or other value. Some cookies will be used on
          the Website whether or not you are logged in to your account. <br />
          <br />
          Session cookies are temporary and will remain in the cookie file of
          your browser until you leave the Website. Permanent cookies remain in
          the browser of your computer for a much longer period (the length of
          this period depends on the expiry date of the specific cookies). We
          use session cookies to keep track of the total number of visitors to
          our Website. We may also use cookies to remember your computer and to
          personalize the Website for you the next time you visit. In such
          cases, we may associate your Personal Information with the cookies.
        </p>
      </div>
      <div>
        <p className="text-lg text-white font-[600] mb-3">2. Uses of cookies</p>
        <p className="text-base text-dark-text">
          We use cookies, web beacons and other similar technologies, including
          from our third-party partners such as Google and Facebook, to provide
          performance measurement services, improve the effectiveness of
          targeted advertising and for marketing purposes. These cookies, web
          beacons and similar technologies allow us to show you our
          advertisements on other websites you visit on the internet and thereby
          make it easier for you to find us. We may also share information about
          your activities on the Website with third parties (including the
          operators of third party websites and/or social media sites) for the
          purpose of displaying targeted advertisements and other personalised
          content.
          <br />
          <br />
          We also use these cookies, web beacons and other similar technologies
          in our targeted advertising program and when displaying other
          advertisements to find out which advertisements users have seen and
          how many times they have been shown a particular advertisement. We may
          also allow those participating in our Website targeting program to use
          cookies, web beacons and other similar technologies in their
          advertisements that we display on our Website and on third-party
          websites to understand how users interact with our advertisements.
        </p>
      </div>
      <div className="flex flex-col gap-[42px]">
        {cookiesPolicyBoxContents.map((content) => (
          <div className="bg-card-dark border-[#353D45] border-[1px] rounded-2xl p-6 flex flex-col gap-[20px] ">
            <p className="text-lg text-white font-[600]">{content.title}</p>
            <p className="text-base text-dark-text">
              {parse(content.description)}
            </p>
          </div>
        ))}
      </div>
      <div>
        <p className="text-lg text-white font-[600] mb-3">
          3. Changes to the Cookie Policy
        </p>
        <p className="text-base text-dark-text">
          Any changes to this Cookie Policy will take effect on the date of
          publication on the Website. By using the Website or submitting
          information to us after such changes have been made, you accept the
          revised Cookie Policy. If we need to use Personal Information
          collected through the Website in a manner substantially different from
          that specified at the time of collection, we will notify users by
          sending an e-mail and/or posting a notice on the relevant Website 30
          days before such use or otherwise as required by law.
        </p>
      </div>
    </div>
  );
};

export default Content;

import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import ReferralAndBonuses from "../pages/ReferralAndBonuses";
import Disclosures from "../pages/Disclosures";
import TermsAndConditions from "../pages/TermsAndConditions";
import CookiesAndPolicy from "../pages/CookiesAndPolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactSales from "../pages/ContactSales";
import ComplaintsPolicy from "../pages/ComplaintsPolicy";
import AMLPolicy from "../pages/AMLPolicy";
import Banking from "../pages/Banking";
import BankingFees from "../pages/BankingFees";
import NizaVerify from "../pages/NizaVerify";

// Feed
// import Feed from "../pages/Feed";
// import Blog from "../pages/Feed/Blog";
// import Activity from "../pages/Feed/Activity";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="referralAndBonuses" element={<ReferralAndBonuses />} />
          <Route path="disclosures" element={<Disclosures />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="cookie-policy" element={<CookiesAndPolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="contact" element={<ContactSales />} />
          <Route path="complaints-policy" element={<ComplaintsPolicy />} />
          <Route path="aml-policy" element={<AMLPolicy />} />
          <Route path="banking" element={<Banking />} />
          <Route path="banking-fees" element={<BankingFees />} />
          <Route path="verify" element={<NizaVerify />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;

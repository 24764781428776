import React from "react";

const Hero = () => {
  return (
    <div className="bg-[#142128] relative overflow-hidden">
      <div className="absolute right-[-20%] top-[-30%] w-6 h-6 side-light2 max-md:hidden" />
      <div className="absolute left-0 bottom-0">
        <img src="/icons/niza_vector.png" alt="" className="max-md:hidden" />
      </div>
      <div className="md:pb-[154px] pb-[100px] pt-[170px] md:pt-[254px] container relative ">
        <img
          src="/icons/comissionNiza2.png"
          alt=""
          className="absolute sm:top-[30%] sm:left-[40%] max-sm:bottom-[-10%] left-[-15%]"
        />
        <div className="flex justify-between items-center max-lg:mb-[20px]">
          <p className="md:text-[54px] text-4xl font-semibold text-white">
            Niza Global banking fees
          </p>
          <p className="text-custom-primary text-[128px] font-semibold leading-[120px] max-lg:hidden">
            %
          </p>
        </div>
        <p className="text-lg md:text-2xl text-white max-sm:text-base">
          This article lists various fees associated with cash accounts.
          <br />
          It covers the different types of charges such as transfer, monthly maintenance
          fees, setup fees, and others.
        </p>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react'
import { terms } from '../../lib/data/termsAndConditions'
import parse from "html-react-parser";

const Content = () => {
  return (
    <div className='container py-[150px]'>
      <div className='flex flex-col gap-[42px]'>
        {terms.map((term)=>(
          <div>
            <p className='text-white text-lg font-[600] mb-3'>{term.title}</p>
            <p className='text-dark-text'>{parse(term.description)}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Content

export const terms = [
  {
    title: "1. PREAMBLE",
    description: `1.1 This document (hereinafter referred to as the "T&C") contains the basic provisions concerning the functioning of the NIZA GLOBAL System and working within it. 
    <br/><br/>
    1.2. The T&C sets out the principles and conditions under which NIZA GLOBAL UAB (hereinafter referred to as the "Provider"), a company incorporated under the laws of Lithuania, located at: Architektų g.56-101, LT-04111 Vilnius, Lithuania, registration number 306048866 and which is registered in Lithuania as a virtual currency service operator in accordance with the applicable laws of the Republic of Lithuania – provides Customers access to the System Services, charging fees in accordance with the Tariffs as prescribed, and Customer in turn agrees to use the System and its Services in accordance with these T&C and the Internal Policies.
    <br/><br/>1.3. These T&C is an official public offer of the Provider to provide the possibility to use the System and its Services, and is addressed to legally capable persons.
    <br/><br/>1.4. The Provider may regulate by its Internal Policies matters not reflected in the T&C by providing information about such Internal Policies on the Website. Acceptance of these T&C constitutes the Customer's agreement to comply with all terms of both the T&C and the Internal Policies.
    <br/><br/>1.5. The content on the Website is provided on an 'as is' basis for information purposes only. The Provider makes no representations, warranties or guarantees, either express or implied, that the content of the Website is accurate, current, complete and/or can be used as a basis for any kind of action or inaction.
    <br/><br/>1.6. Unless the context otherwise requires, words used in one gender shall include the meaning of any or all genders respectively.`
  },
  {
    title: "2. FIAT TO CRYPTO SERVICES",
    description: `2.1. Fiat-to-Crypto Services will be provided to Сustomer by Niza Global UAB.
    <br>
    2.2. Сustomers are required to read, accept and comply with the Terms of Use and Privacy Policy of Niza Global UAB.`
  },
  {
    title: "3. DEFINITIONS",
    description: `3.1. Parties are Provider and Customer.
    <br/><br/>
    3.2. Provider's contact details are the following addresses on the Provider's side or postal correspondence: NIZA GLOBAL UAB, Architektų g. 56-101, LT-04111 Vilnius, Lithuania.
    <br/><br/>
    3.3. System (NIZA GLOBAL System) is a set of hardware and software designed, created and operated in order to provide Customer with Services of Provider system.
    <br/><br/>
    3.4. System Services are transactions performed through the System for the receipt and sending of Funds in the Wallets of the Customers.
    <br/><br/>
    3.5. Website means https://niza.io/.
    <br/><br/>
    3.6. Applicant means an individual who wishes to create an Account and submits an application for registration via the System.
    <br/><br/>
    3.7. Customer means an individual or a legal entity who is the owner of the Account and who has completed the Registration procedure and has acquired the right to use the System in accordance with the T&C and the Internal Policies.
    <br/><br/>
    3.8. Contact Data is the e-mail address and mobile phone number entered in the System by the Customer.
    <br/><br/>
    3.9. Authorisation means the process of authenticating a person by Login and Password.
    <br/><br/>
    3.10. Authorisation Data means a combination of Login and Password.
    <br/><br/>
    3.11. Login means the Customer's e-mail address or a digit-letter combination set by the Customer.
    <br/><br/>
    3.12. Password means a digit-letter combination of symbols set by the Customer during Registration and used in conjunction with the Login to log into the Account.
    <br/><br/>
    3.13. Registration means the result of entering the registration data into the System and accepting the T&C, after which the Applicant is identified by the System.
    <br/><br/>
    3.14. Verification means the procedure for the Customer to enter data into the System and confirm them by completing the online form and attaching supporting documents, following the instructions in the Customer's Account in accordance with the requirements of the applicable law and the Internal Policies.
    <br/><br/>
    3.15. Verified Account means the status of the Customer's Account which has been verified.
    <br/><br/>
    3.16. Non-Verified Account means the status of the Customer's Account which has not been verified. The holders of Non-Verified Accounts are not provided with the System Services.
    <br/><br/>
    3.17. Account means the Customer's virtual account in the System registered in accordance with these T&C and the Internal Policies. Unless otherwise specified, "Account" means a Personal Account.
    <br/><br/>
    3.18. Personal Account means an Account created by the Customer.
    <br/><br/>
    3.19. Wallet means a part of the Account reflecting the balance of Funds belonging to the Customer in one of the selected currencies. There can be several Wallets in one Account.
    <br/><br/>
    3.20. Wallet Type means a Wallet in one of the available currencies.
    <br/><br/>
    3.21. Funds mean funds in electronic format, which belong to the Customer and are held in his/her Wallet.
    <br/><br/>
    3.22. Fees mean remuneration charged by the Provider for the System Services rendered to the Customer.
    <br/><br/>
    3.23. Transaction means an action initiated by the Customer to receive Funds in his/her Wallet or to send Funds from his/her Wallet within the System.
    <br/><br/>
    3.24. Payment means the Funds recorded in the Wallet and transferred from the Customer to a third party or from a third party to the Customer.
    <br/><br/>
    3.25. Payment Order is the Customer's order to carry out a Transaction executed electronically through the System.
    <br/><br/>
    3.26. Internal Policies is the Provider's internal documents governing the System, including the Anti-Money Laundering and Counter-Terrorist Financing Compliance Policy (AML Policy) and Privacy Policy, Chargeback Policy and others published on the Website.
    <br/><br/>
    3.27. Chargeback means a bank card payment cancellation procedure initiated by the cardholder through his/her bank.
    <br/><br/>
    3.28. Suspension of Account is suspension of all Transactions in all of the Customer's Wallets by the Provider in cases stipulated in Section 12.
    <br/><br/>
    3.29. Shell Bank is a bank registered in a jurisdiction in which it is not physically present and which is not affiliated with any financial institution subject to the relevant legislation.
    <br/><br/>
    3.30. Inactive Account means an Account which the Customer has not logged in to for 1 year or more.
    <br><br>
    3.31. Virtual currency (crypto currency) means a set of electronic data (digital code or designation) contained in an information system.`
  },
  {
    title: "4. GENERAL PROVISIONS",
    description: `4.1. To become a Customer and use the System Services, an Applicant has to create the Account in accordance with the procedures stated in the Section 4.
    <br/><br/>
    4.2. The Customer shall have the right:
    <br/><br/>
    4.2.1. Receive information about the System Services provided by the Provider, the provisions of the T&C and the Internal Policies, access to the System Services in accordance with the status of their Account and other restrictions provided by the T&C and the Internal Policies.
    <br/><br/>
    4.2.2. Receive technical and informational support in connection with the use of the System Services.
    <br/><br/>
    4.3. The Customer shall be obliged:
    <br/><br/>
    4.3.1. to comply with the terms of the T&C and the Internal Policies;
    <br/><br/>
    4.3.2.to provide true, complete and up-to-date data when registering in the System, undergoing the Verification procedure, when changing data in the System, as well as at the request of the Provider in cases stipulated by the T&C and the Internal Policies;
    <br/><br/>
    4.3.3. not to allow third parties to use its Account;
    <br/><br/>
    4.3.4. . to take all reasonable measures to keep his Authorisation Data secret and not to disclose them to third parties. The Customer shall be fully responsible for the integrity of his Authorisation Data and all risks associated with its loss and/or compromise;
    <br/><br/>
    4.3.5. upon discovering a Transaction carried out without the Customer's consent, unauthorised access to his personal data, loss of Authorisation Data – immediately notify the Provider via the Support Team;
    <br/><br/>
    4.3.6. to use a functioning device with Internet access;
    <br/><br/>
    4.3.7. not to use any malicious software on their computer (or any other data carrier); use only licensed software; work with the System on a computer that has the following installed: antivirus software with the database updated to the date, the updated version of a browser, all the necessary updates for the operating system and the software;
    <br/><br/>
    4.3.8. not to use the System for carrying out Transactions aimed at illegal profit making or concealment of income from taxation;
    <br/><br/>
    4.3.9. not to use the System for purposes contrary to the provisions of the law of the country where the Customer is a resident, not to engage in Prohibited Activities;
    <br/><br/>
    4.3.10. to avoid having a negative balance of Funds in his/her Wallet;
    <br/><br/>
    4.3.11. to be fully liable for any cancelled, invalid or disputed Transactions, including Chargebacks;
    <br/><br/>
    4.3.12. not to carry out Transactions involving shell banks.
    <br/><br/>
    4.4. The Provider shall be obliged to:
    <br/><br/>
    4.4.1. provide the System Services to the Customers in accordance with these T&C and the Internal Policies;
    <br/><br/>
    4.4.2. keep safe the Funds of the Customer deposited in the Account;
    <br/><br/>
    4.4.3. execute the Customer's instructions for disposal of the Funds in the Account within the limits established by these T&C, the Internal Policies and the applicable legislation.
    <br/><br/>
    4.5. The Provider reserves the right to modify the System Services provided, including software updates, procedures and interfaces.
    <br/><br/>
    4.6. The Provider shall be entitled to suspend operation of the System in case of detecting malfunctions, in order to prevent unauthorised access to the System, as well as for preventive maintenance.
    <br/><br/>
    4.7. The Fees for the use of the System Services shall be charged in accordance with the provisions of Section 7 of the T&C.
    <br/><br/>
    4.8. The Provider shall monitor compliance with the T&C and the Internal Policies by the Customers. The Provider shall have the right to refuse to carry out Transactions in the cases specified in these T&C, any other supplementary agreement, the Internal Policies or the applicable laws.
    <br/><br/>
    4.9. In order to provide services, the Provider uses the assistance of third parties.
    `
  },
  // <br/><br/>
  //  4.9.2. Before using the banking services, the Customer is obliged to familiarize with the service provider terms and conditions.
  {
    title: "5. REGISTRATION, VERIFICATION AND ACCEPTING OFFER",
    description: `5.1. The Applicant wishing to create an Account shall complete the Registration: fill in the registration forms on the Website, provide the registration data and accept the terms of these T&C by clicking on the «Sign Up» button.
    <br/><br/>
    5.2. Acceptance of the T&C by the Applicant is considered to be the conclusion of a bilateral written agreement between the Parties.
    <br/><br/>
    5.3. By accepting these T&C, the Applicant confirms that it is acting on its own behalf and not on behalf or in the interests of third parties.
    <br/><br/>
    5.4. Upon Verification, in order to receive access to the System Services available to the Verified Accounts, the Customer must provide the Provider with all the necessary data as specified in the Internal Policies.
    <br/><br/>
    5.5. In case not providing all the required data, or if any of the data provided is found to be incorrect, incomplete, irrelevant, the verification has not been passed and the restrictions for the Account shall be applied in accordance with the provisions of the Non-Verified Account.
    <br/><br/>
    5.6. Verification normally takes no more than 24 hours from the moment the Customer has provided the requested data. In some cases, if the Provider has a justified reason to carry out additional verification, the Verification may take more than 24 hours.
    <br/><br/>
    5.7. Verification shall be deemed completed when the Customer receives a confirmation mail from the Provider to the email address specified by the Customer in the System. Upon completion of the Verification, the Customer shall have access to all the System Services available to the Verified Accounts.
    <br/><br/>
    5.8. The Customer shall notify the Provider of any changes to the data provided for Verification purposes in a timely manner, as well as provide up-to-date data within a reasonable period of time.
    <br/><br/>
    5.9. The Provider shall have the right at any time and at its discretion to ask the Customer to confirm that the documents and data provided are true, complete and up-to-date and/or to provide additional supporting documents or information within 14 days.
    <br/><br/>
    5.10. If the Customer fails to submit the requested documents and/or data within the time limit indicated in clause 5.9, the Provider shall have the right to:
    <br/><br/>
    5.10.1. cancel the Verification of the Account and apply the restrictions provided for Non-Verified Accounts;
    <br/><br/>
    5.10.2. apply Suspension of the Account.`
  },
  {
    title: "6. ACCOUNT",
    description: `6.1. After completing Registration in the System, an Account is created and the Applicant becomes a Customer.
    <br/><br/>
    6.2. The Customer may add a Wallet for each of the available currencies. All Funds transferred to the Customer's Account are held in the Wallets in the relevant currencies.
    <br/><br/>
    6.3. Funds held in the Wallet are not limited in duration except as set out in Section 14 in respect of unclaimed funds, no interest will accrue thereon.
    <br/><br/>
    6.4. . Limits on deposits, payments and withdrawals may apply to the Wallet, depending on the type of Wallet and other factors taken into account by the Provider. These limits are defined in accordance with the T&C and are available on the Website.
    <br/><br/>
    6.5. The Customer may only use the Non-Verified Account for the purpose of obtaining Verified Account status.
    <br/><br/>
    6.6. The Customer can create a Personal Account. A detailed description of the functionality of the Personal Accounts can be found on the Website.
    <br/><br/>
    6.7. To create an Account, an individual must be at least 18 years old and have full legal capacity under their personal law. By creating a Personal Account, the Customer declares that he or she has attained the age of 18 years.
    <br/><br/>
    6.8. The Customer may create an Account only if it does not contradict the provisions of the laws of the country of residence or registration of the Customer. By creating an Account, the Customer declares and warrants to the Provider that the opening of such an Account by the Customer does not violate any laws or regulations applicable to the Customer. The Customer shall pay to the Provider the amount of any loss incurred by the Provider in the case of a breach by the Customer of the provisions of this clause.
    <br/><br/>
    6.9. Information on the Customer's Transactions with Funds and use of the Wallet shall be recorded and stored by the Provider for the duration of the agreement with the Customer and for a further five (5) years from the date of termination of these T&C and closure of the Account.
    <br/><br/>
    6.10. By initiating a new Transaction that changes the balance of the Funds in the Wallet, the Customer confirms their consent to the amount of the said balance. The Customer's consent is confirmed by pressing the relevant button, which initiates the sending of a new Payment Order.
    <br/><br/>
    6.11. Access to the Wallet and any Transactions using the Wallet are possible only after Authorisation.
    <br/><br/>
    6.12. The Login is formed by the System by default from the email specified by the Customer upon Registration, or changed upon the Customer's request to the Support Team. The Password is created by the Customer independently. The Password is used for Authorisation.
    <br/><br/>
    6.13. The Customer is fully responsible for keeping his Authorisation Data secret. Any action in the Account taken using valid Authorisation Data shall be recognised as an action by the Customer.
    <br/><br/>
    6.14. After 10 failed attempts to enter the Authentication Data the Account shall be suspended. In order to regain access to the Account, the Customer must contact the Support Team.
    <br/><br/>
    6.15. In the case of loss of the Authentication Data, the Customer shall apply to the Support Team with a request to restore access to the Account.
    <br/><br/>
    6.16. The Customer may close his/her Wallet at any time by contacting the Support Team. The closure of an individual Wallet does not entail the closure of the Account.
    <br/><br/>
    6.17. It is not permitted to create multiple Accounts for one person ("Multiple Accounts"). If Multiple Accounts are detected, the Provider has the right to automatically mark such Accounts as fraudulent and suspend the provision of the System Services for such Accounts.`
  },
  {
    title: "7. TRANSACTIONS",
    description: `7.1. The Customer can deposit and/or withdraw from his/her Wallet by accessing his Account on the Website and following the relevant instructions for deposit or withdrawal of funds from the Wallet. Methods of deposit or withdrawal from the Wallet may vary depending on the Customer's location. The currently available methods for deposit and withdrawal of funds from the Wallet are available on the Website.
    <br/><br/>
    7.2. The Provider has the right to limit the list of methods to deposit and withdraw funds from the Wallets for certain categories of Customers depending on the risk level of the Customer's activities and/or transactions. Specific restrictions are indicated on the Website.
    <br/><br/>
    7.3. When the Wallet is funded by third parties, the Customer has all rights and obligations in relation to the Funds deposited into the Wallet. Such transactions carried out by third parties shall be deemed by the Parties to have been carried out in the Customer's best interests.
    <br/><br/>
    7.4. For security purposes, the Provider shall set the limits for depositing funds into the Wallet. These limits are displayed in the Customer's Account when selecting one of the available deposit methods.
    <br/><br/>
    7.5. Depending on the deposit or withdrawal method, third parties may charge additional fees for deposits into the Wallet.
    <br/><br/>
    7.6. The Transaction shall be made on the basis of the Customer's Payment Order made electronically using the Wallet.
    <br/><br/>
    7.7. When accepting a Payment Order to transfer Funds from the Customer's Wallet, the Provider shall identify the Customer using the Authorisation Data. In certain cases, the Provider shall have the right to request additional identification procedures before executing the Payment Instruction.
    <br/><br/>
    7.8. All Transactions performed using the Customer's Authorisation Data shall be deemed to have been performed by the Customer unless the Provider is notified of unauthorised access to the Account or use of the Account for fraudulent purposes.
    <br/><br/>
    7.9. The timing of Transactions which depend on the availability of services provided by third party financial organisations shall be determined in accordance with the terms of services provided by such organisations. The Provider shall not be liable for delays caused by third parties.
    <br/><br/>
    7.10. The Provider shall have the right, by default, to send notifications of Transactions carried out using the Account to the email address indicated by the Customer in the System. The Customer may waive the notification by contacting the Support Team.
    <br/><br/>
    7.11. Withdrawal of funds. The Provider shall be entitled to limit the list of recipients of Funds for certain categories of Customers depending on the risk level of the Customer's activities and/or transactions. Specific restrictions are indicated on the Website.
    <br/><br/>
    7.12. The applicable payment limits for different Account statuses and Customer categories, depending on the Customer's activity and/or transaction risk, are indicated on the Website.
    <br/><br/>
    7.13. The Customer may choose the method of withdrawal of Funds by forming a withdrawal request from his Wallet. The Provider has the right to require the Customer to complete a Verification before making a Withdrawal of Funds.
    <br/><br/>
    7.14. In order to deposit funds to the Wallet using a bank card, the Provider has the right to require the Customer to go through "Know Your Customer" verification procedures in accordance with the AML Policy.
    <br/><br/>
    7.15. If the Customer has successfully passed Verification and the Wallet has been replenished using a bank card, this transaction cannot be cancelled and no refund can be made to the card.
    <br/><br/>
    7.16. The Customer may exchange currencies in his/her Account for other currencies available in the System at the exchange rate specified in the System. The list of currencies is indicated on the Website.
    <br/><br/>
    7.17. The Customer shall be entitled to transfer funds between his/her Wallets within his/her Account or to the Wallets of the Accounts of other customers in the System.
    <br/><br/>
    7.18. In his/her Account, the Customer may upload electronic reporting documents for all Transactions carried out for any period of time determined by the Customer at his/her own discretion, free of charge.
    <br/><br/>
    7.19. All successful Transactions carried out by the Customer through the System are final and are not subject to dispute, revision or cancellation.`
  },
  {
    title: "8. RATES AND PROCEDURES FOR CHARGING FEES",
    description: `8.1. The Provider shall charge the Fees for the Services provided to the Customer.
    <br/><br/>
    8.2. Information on the amount and manner of charging Fees is available on the Website when selecting the payment processing method.
    <br/><br/>
    8.3. The Provider has the right to unilaterally change the Fees. The changes shall take effect as from the date of their publication on the Website.
    <br/><br/>
    8.4. All Fees are calculated in the currency of the Transaction and rounded to the nearest whole number.
    <br/><br/>
    8.5. The System Services provided to the Customer by the Provider are not subject to VAT or any other turnover taxes.
    <br/><br/>
    8.6. All relevant Fees shall be debited from the Customer's Wallet by the Provider at the time of completion of the relevant Transaction in respect of which such Fees are charged.
    <br/><br/>
    8.7. If there are insufficient funds in the Wallet to charge a Fee, the System will block the Transaction in question.
    <br/><br/>
    8.8.When transferring Funds between Wallets denominated in different currencies, the exchange rate shall be calculated in accordance with the exchange rate published on the Website, plus a set Fee. The final exchange rate applicable to a Transaction is fixed at the time of the Transaction.`
  },
  {
    title: "9. PERSONAL DATA",
    description: `9.1. Data processing and storage is governed by the Privacy Policy, published on the Website as a separate document.`
  },
  {
    title: "10. LIABILITY",
    description: `10.1. If the Parties fail to perform and/or improperly perform their obligations under the T&C, the Parties shall be liable in accordance with the T&C, the Internal Policies and applicable law.
    <br/><br/>
    10.2. The Customer shall pay the Provider a penalty in the amount of 0.1% (zero point one per cent) of the outstanding obligation for each day of delay in the fulfilment of any obligation expressed in monetary terms as provided in the T&C. The Provider shall be entitled but not obliged to demand payment of the penalty.
    <br/><br/>
    10.3. The Customer undertakes to reimburse to the Provider all amounts paid by the latter as compensation for damages in connection with the Customer's breach of the applicable law when using the System, subject to the Provider providing appropriate documentation confirming the amount of the losses incurred by the Provider.
    <br/><br/>
    10.4. The Customer shall be fully liable for all Transactions recorded on his Wallet, including any Transactions involving bank payment cards. Such Transactions also include Transactions carried out by third parties who have accessed his/her Wallet.
    <br/><br/>
    10.5. The Customer shall take full responsibility to get acknowledged with the current legislation of the Customer’s residence, regulating the Account opening and money transfer Transactions via the System. The Provider shall not be liable for any breach of the national laws by the Customer resulted by the use of the System. If a Customer’s use of the System and Services contradicts the laws of this Customer’s jurisdiction, such Customer should immediately stop using the Services.
    <br/><br/>
    10.6. The Customer shall be fully responsible for all risks associated with the use of the Internet in their interaction with the Provider, other Customers, third parties.
    <br/><br/>
    10.7. The Customer shall not undertake any activity that misleads other parties about services offered by the Provider, which can directly or indirectly damage the Provider’s reputation, including but not limited to impersonating themselves as representatives of the Provider in any way or context, providing false data related to the System including but not limited to falsifying transaction IDs, wallet IDs and any other data. as text, screenshots, or any other media, creating clones of the Provider’s website, and other similar activities. In case of violation the Provider reserves the right to suspend the Customer’s Accounts, freeze funds for the period of investigation, and limit the usage of the System in other ways. The Provider also reserves the right to pursue legal action in courts of applicable jurisdiction, including cases when this rule is violated by other parties that are not the Customers of the Provider.
    <br/><br/>
    10.8. The Customer shall protect the Provider’s interests, reimburse the Provider’s losses and pay compensations to the Provider as well as indemnify the Provider and his affiliates against any claims or damages, costs or expenses (including expenses for legal support, penalties or forfeits) resulting from violation by the Customer of these T&C or any applicable laws or regulations and (or) use of the System. This provision shall survive termination of relations between the Parties.
    <br/><br/>
    10.9. If, through the Customer’s fault, a completed Payment serves as a ground for a payer to file a claim to the Provider for protection of his violated right, the Provider may demand that the recipient of the Funds reimburses losses resulting from collection of funds or other property from the Provider to the benefit of the payer.`
  },
  {
    title: "11. DISCLAIMER",
    description: `11.1 The Provider shall not be responsible to the Customer in the case of:
    <br/><br/>
    11.1.1. The Customer transfers his data to any third parties, deliberate provision of access to his Wallet in the System, or any other violation of the confidentiality of a Customer’s information caused by the Customer’s fault;
    <br/><br/>
    11.1.2. Illegal activities of any third parties, including those related to the use of the Customer’s registration data and a Customer’s email or the Authorization Details;
    <br/><br/>
    11.1.3. If there are viruses or other malicious software in the hardware and software used by the Customer to access the System;
    <br/><br/>
    11.1.4. Any disputes pertaining to any transactions between the Customers made via the System;
    <br/><br/>
    11.1.5. For violation of the current legislation by the Customer in relation to the use of the System as well as for the complaints pertaining to the Customer from tax, regulation, and law enforcement authorities regarding any reporting documentation or taxation in relation to the Customer’s transactions involving the System;
    <br/><br/>
    11.1.6. If the Customer does not have access to software or hardware that ensure the use of the System;
    <br/><br/>
    11.1.7. If the Customer cannot be contacted using the Contact Details provided by the Customer, including due to the Customer providing incorrect information or failing to update such information in a timely manner;
    <br/><br/>
    11.1.8. For any payment services provided to the Customer by third parties;
    <br/><br/>
    11.1.10. For temporary inoperability of the System, malfunctioning and errors in the operation of hardware or software (including disconnection or damage of electricity supply and communication networks, software malfunctioning, interruption of mail services, Internet provider’s, payment system operations, disruption in other lines, channels and(or) networks that are provided, offered or serviced by third parties, etc.), occurred not through the Provider’s fault; in this case, the Provider shall not bear responsibility for Customer’s possible losses.
    <br/><br/>
    11.2. In the case of loss of Authorisation Data, blocking of the Wallet by the System, loss of Password by the Customer, Suspension of Account or other events which cannot be resolved without Verification, the Provider is not liable for the inability to restore access to the Account due to the Customer losing access to his e-mail or mobile phone number.
    <br/><br/>
    11.3. The Provider shall not be responsible for any damages and losses incurred by a Customer or by any third party as a result of:
    <br/><br/>
    11.3.1. Suspension of Account in accordance with these T&C or the requirements of the law;
    <br/><br/>
    11.3.2. Failure to pass the Verification;
    <br/><br/>
    11.3.3. Incorrect completion of Payment Orders to carry out the Transactions or completion by mistake;
    <br/><br/>
    11.3.4. Violation by the Customer of the established procedure for making Payments;
    <br/><br/>
    11.3.6. Failure to keep up to date with the current version of the T&C or the Internal Policies and other information published on the Website;
    <br/><br/>
    11.3.7. Impossibility to use a desired payment method at the Customer’s location;
    <br/><br/>
    11.3.8. Violation by the Customer of these T&C or Internal Policies, as well as the Provider’s instructions and guidelines.
    <br/><br/>
    11.4. In any event, the Provider’s responsibility to a Customer shall always be limited to the amount that does not exceed the amount of the Fees charged to such Customer for a period of the previous 3 months.
    <br/><br/>
    11.5. The Provider shall not be responsible for any indirect or consequential damages incurred by the Customer or any third parties, including any loss of profit, loss (forfeit, non-receipt, unsubstantiated spending) of revenue, income, contracts, customers, time, data, enterprise, or reputation.
    <br/><br/>
    11.6. The Provider shall provide to the Customer Services “as is” as at the moment, without any express, implied or statutory representations or guarantees.`
  },
  {
    title: "12. COMMUNICATION BETWEEN THE PARTIES",
    description: `12.1 Communication between the Provider and the Customer shall take place via the Contact Details.
    <br/><br/>
    12.2. Communication between the Customer and the Provider can be conducted in English and Russian.
    <br/><br/>
    12.3. The Provider may inform the Customer on the changes in the Services provided either by sending the email notifications or by posting the updates on the Website.
    <br/><br/>
    12.4. The document flow between the Customer and Provider shall be in electronic form via the Account. Moreover, the use of the Authorisation Details by the Customer shall be deemed by the Parties due and sufficient method of the Customer Authentication for the purposes of the document flow as well as for confirmation of authenticity and integrity of the provided electronic document, except for the cases provided for by the T&C or the Internal Policies. Entering the Customer’s Authenticated Data shall be deemed by the Parties equal to the Customer’s handwritten signature.`
  },
  {
    title: "13. TERM AND TERMINATION OF OBLIGATIONS",
    description: `13.1. Indefinite Term. These T&C is concluded for an indefinite term and shall be in effect until terminated in accordance with the provisions stated below.
    <br/><br/>
    13.2. The Customer is entitled to terminate relations, including concluded agreements with the Provider by deleting the Account at his discretion at any time if the Customer does not have any unfulfilled obligations to the Provider. The Account can be deleted in the relevant section of the Customer’s Account.
    <br/><br/>
    13.3. After the Customer initiates deletion of the Account, the execution of the Transactions with the use of the Account shall be impossible. Any monetary obligations of the Parties created prior to the date of deletion of the Account shall retain until they are fully executed.
    <br/><br/>
    13.4. The Provider has the right to terminate these T&C unilaterally with at least 10 days' notification to the Customer's e-mail.
    <br/><br/>
    13.5. The Provider may terminate the T&C and delete the Account without prior notification to the Customer in the following cases:
    <br/><br/>
    13.5.1. If the Customer declines to accept amendments to the T&C, it shall be terminated on the date of entry into force of such amendments;
    <br/><br/>
    13.5.2. At the expiry of the period for three years from the date of the Suspension of Account.
    <br/><br/>
    13.6. In case any funds remain on the Account of the Customer with whom the T&C are terminated, the Customer shall withdraw the remaining funds within a reasonable period of time deducting the applicable fees by indicating the payment details to the Provider.
    <br/><br/>
    13.7. Should the Account be terminated (deleted), the account and transactional information that meet data retention standards will be securely stored for 5 years in accordance with the requirements of the law.`
  },
  {
    title: "14. UNCLAIMED FUNDS",
    description: `14.1. Any unclaimed funds will be held by the Provider for a period of 3 years from the date of the Suspension of Account andor termination of the Account, at the expiry of which time, the Customer will be deemed to have waived any claim in respect of such funds. The Customer hereby agrees that upon expiry of such period, the Provider will be relieved of any further obligation to pay those unclaimed funds to the Customer.`
  },
  {
    title: "15. AMENDMENTS",
    description: `15.1. The Provider may unilaterally amend these T&C by publishing the updated text on the Website.
    <br/><br/>
    15.2. The Provider shall notify the Customer on the upcoming amendments to these T&C by posting an informational message inside the Customer's Account no later than 2 (two) days before such amendments come into force. The Customer shall either accept or reject the amendments before the date of their proposed date of entry into force. The Customer shall notify the Provider on objection to the amendments via contacting the Support Team. In case the Customer objects to the amendments, the T&C shall be terminated on the date the amendments enter into force.
    <br/><br/>
    15.3. The amendments come in force since the amended version of the T&C is published on the Website or on the date the corresponding amended version enters into force if it is directly stated on the Website.
    <br/><br/>
    15.4. The use of the Services by the Customer shall be considered as the unconditional acceptance of the updated T&C.
    <br/><br/>
    15.5. Any appendixes to the T&C shall constitute the integral part of these T&C.
    <br/><br/>
    15.6. The Fees changes shall be effective immediately and shall not require prior notification.`
  },
  {
    title: "16. FORCE MAJEURE",
    description: `16.1. The Parties shall not be responsible for any delays in the performance or non-performance of the obligations, any expenses, including losses, and costs related to complaints by any third parties that may result from any due to any acts of civil or military authorities, civil disturbances, strikes or other labour disputes, fires, transportation contingencies, interruptions in telecommunications, utility, Internet services or network Provider services, acts or omissions of a third party, infiltration or disruption of the services by a third party by any means, including without limitation, DDoS attacks, software viruses, Trojan horses, worms, time bombs or any other software program or technology designed to disrupt or delay the Services (each a “Force Majeure Event”). The Party experiencing the impact of any Force Majeure Event shall notify the other Party within 10 days after the commencement of such Force Majeure Event.
    <br/><br/>
    16.2. The provisions of clause 16.1. shall not limit or terminate the Customer’s obligations pertaining to making the returning Payments, as well as payment of any fines, penalties, fees, Commissions, returning any goods, or (not) performing any works or services after the Force Majeure is over.`
  },
  {
    title: "17. OTHER PROVISIONS",
    description: `17.1. These T&C and the relations between the Provider and the Customer arising from them are governed by the laws of Lithuania.
    <br/><br/>
    17.2. The Customer has the right to settle any dispute on out-of-court procedure through negotiations. The basis for negotiations shall be either Party’s submitting a written complaint to the other Party. All Complaints and claims shall be reviewed within thirty days of their receipt.
    <br/><br/>
    17.3. In case a dispute cannot be resolved by negotiations within the specified period of time, either Party may apply to a court. The Provider is subject to the laws and courts of the country in which the Provider is based.
    <br/><br/>
    17.4. The choice of law and jurisdiction in the clause 15.3. does not affect the mandatory rights of the Customer under the laws of the country of his/her residence.
    <br/><br/>
    17.5. A Customer shall not transfer any rights or obligations under these T&C to any third party without the prior written consent given by the Provider.
    <br/><br/>
    17.6. The invalidity or unenforceability of any provision of these T&C shall result in the exclusion of such provision without affecting the validity of the remaining provisions of the T&C.
    <br/><br/>
    17.7. These T&C comes into force the moment the Applicant clicks the "Sign up" button during the Registration process.
    <br/><br/>
    17.8. The Customer acknowledges and confirms that all the provisions of the T&C are clear, and that the Customer agrees with the Internal Policies available on the Website, accepts them unconditionally and shall comply with its provisions and requirements.`
  },
]
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { navbarTabs } from "../../lib/data/navbar";
import { v4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import { useContext } from "react";
import { OpeningSoonContext } from "../../context/openingSoonContext";

const MobileNavbar = ({ open, setOpen }) => {
  const modalRef = useRef(null);
  const { setWarning } = useContext(OpeningSoonContext);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      console.log("outside");
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`relative z-50 ${!open && "hidden"} `}>
      <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black/60 flex justify-center items-center z-30">
        <div
          className="bg-card-dark h-[70%] sm:w-[75%] w-[90%] rounded-xl p-5"
          style={{ scrollbarWidth: "none" }}
          ref={modalRef}
        >
          <div className="flex flex-col gap-6 h-full">
            <div className="flex justify-between items-center text-dark-text">
              <Link to="/" onClick={() => setOpen(false)}>
                <img
                  src="/nizaglobal-logo.svg"
                  alt=""
                  className="h-[30px] sm:h-10 mr-16"
                />
              </Link>
              <FontAwesomeIcon
                icon={faXmark}
                className="text-3xl cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex flex-col gap-6 overflow-scroll h-full">
              {navbarTabs.map((tab, index) => (
                <>
                  <div key={v4()}>
                    <p className="text-white text-xl mb-6 max-lg:mb-4">{tab.name}</p>
                    <div className="flex flex-col gap-4">
                      {tab.childrenLinks.map((childLink) =>
                        childLink.coming_soon ? (
                          <p
                            onClick={() => setWarning(true)}
                            className="text-dark-text text-sm font-medium flex items-center gap-[5px]"
                          >
                            {parse(childLink.name)}
                          </p>
                        ) : (
                          <Link
                            to={childLink.link}
                            onClick={() => setOpen(false)}
                          >
                            <p className="text-dark-text text-sm font-medium flex items-center gap-[5px] hover:underline hover:decoration-white hover:text-white">
                              {parse(childLink.name)}
                            </p>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                  {index === 0 ? (
                    <div className="flex gap-3 items-center">
                      <Link
                        to={process.env.REACT_APP_SIGN_IN}
                        onClick={() => setOpen(false)}
                      >
                        <div className="text-white border-[1px] border-white px-4 py-3 w-fit rounded-lg font-semibold hover:bg-white hover:text-black max-lg:py-2.5 max-lg:text-sm">
                          Sign in
                        </div>
                      </Link>
                      <Link
                        to={process.env.REACT_APP_SIGN_UP}
                        onClick={() => setOpen(false)}
                      >
                        <div className="text-black border-[1px] border-custom-primary bg-custom-primary px-4 py-3 w-fit rounded-lg font-semibold max-lg:py-2.5 max-lg:text-sm">
                          Get Started
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {navbarTabs.length !== index + 1 ? (
                    <hr className="border-[#555556]" />
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;

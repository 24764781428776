import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Niza - Not found 404</title>
      </Helmet>
      <div className="h-[100vh] flex justify-center items-center relative">
        <div>
          <img
            src="/icons/comissionNiza.png"
            alt=""
            className="absolute left-7 bottom-20"
          />
          <img
            src="/icons/comissionNiza2.png"
            alt=""
            className="absolute top-[25%] right-[30%]"
          />
          <p className="text-white text-center text-[54px]">404</p>
          <p className="text-white text-center text-[34px]">Not Found</p>
        </div>
      </div>
    </>
  );
};

export default NotFound;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import MobileNavbar from "./MobileNavbar";
import { useContext } from "react";
import { OpeningSoonContext } from "../../context/openingSoonContext";
import { navbarTabs } from "../../lib/data/navbar";
import { v4 } from "uuid";
import parse from "html-react-parser";

const NavLink = ({ childLink, setWarning }) => {
  const [isHovered, setIsHovered] = useState(false);

  return childLink.coming_soon ? (
    <div
      className={`rounded-lg hover:text-custom-primary text-white py-4 pl-0 pr-5 hover:bg-[#2D333B] hover:px-4 transition-all ease-in`}
      onClick={() => setWarning(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex justify-between items-center mb-3">
        <p
          className={`${
            childLink.external && "text-custom-primary"
          } text-base flex items-center gap-1`}
        >
          {parse(childLink.name)}
        </p>
        {isHovered && (
          <FontAwesomeIcon icon={faArrowUp} className=" text-lg rotate-45" />
        )}
      </div>
      <p className="text-dark-text text-xs">{childLink.description}</p>
    </div>
  ) : (
    <Link to={childLink.link}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`rounded-lg hover:text-custom-primary text-white py-4 pl-0 pr-5 hover:bg-[#2D333B] hover:px-4 transition-all ease-in flex items-center ${
          childLink.image ? "gap-3" : ""
        }`}
      >
        {childLink.image ? (
          <div className="w-[40px]">
            <img src={childLink.image} alt={childLink.name} />
          </div>
        ) : null}
        <div className="w-full">
          <div
            className={`flex justify-between items-center ${
              childLink.description ? "mb-3" : "w-full"
            }`}
          >
            <p
              className={`${
                childLink.external && "text-custom-primary"
              } text-base flex items-center gap-[5px]`}
            >
              {parse(childLink.name)}
            </p>
            {isHovered && (
              <FontAwesomeIcon
                icon={faArrowUp}
                className=" text-lg rotate-45"
              />
            )}
          </div>
          {childLink.description ? (
            <p className="text-dark-text text-xs">{childLink.description}</p>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

const Navbar = () => {
  const { setWarning } = useContext(OpeningSoonContext);
  const [openMobile, setOpenMobile] = useState(false);
  const [clickedTab, setClickedTab] = useState(undefined);

  return (
    <>
      <div className="w-full">
        <div className="bg-[#262b31]">
          <div className="container p-5 flex items-center min-lg:w-full max-xl:gap-2 max-xl:py-5 max-xl:px-0 max-sm:px-3">
            <div className="grow flex items-center max-xl:gap-2">
              <Link to="/">
                <img
                  src="/nizaglobal-logo.svg"
                  alt=""
                  className="h-[30px] sm:h-10 xl:mr-16"
                />
              </Link>
              <div className="max-lg:hidden lg:flex items-center gap-2 max-xl:gap-1">
                {navbarTabs.map((tab) => (
                  <div
                    key={v4()}
                    className="flex items-center gap-2.5 px-4 py-3 rounded-lg hover:bg-white/10 transition-colors cursor-pointer relative max-xl:py-2 max-xl:px-3 max-xl:gap-2"
                    onMouseOver={() => setClickedTab(tab.name)}
                    onMouseLeave={() => setClickedTab(undefined)}
                  >
                    <p
                      className="text-white text-sm 2xl:text-lg font-medium"
                      style={{ letterSpacing: "-0.72px" }}
                    >
                      {tab.name}
                    </p>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="text-white text-xs"
                    />
                    {clickedTab === tab.name ? (
                      <div className="absolute top-[100%] left-0 pt-2">
                        <div
                        style={{
                          width: `${tab.width ? tab.width : "396"}px`
                        }}
                          className={`rounded-xl bg-gradient-to-br from-white/50 via-white/10 to-transparent p-[1px] w-[${
                            tab.width ? tab.width : "396"
                          }px]`}
                        >
                          <div className="bg-card-dark rounded-xl p-5 flex flex-col gap-2 w-full">
                            {tab.childrenLinks.map((childLink) => (
                              <NavLink
                                childLink={childLink}
                                setWarning={setWarning}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex grow justify-end max-lg:justify-end">
              <div className="flex items-center max-xl:gap-1">
                <Link to={process.env.REACT_APP_SIGN_IN}>
                  <div className="text-white text-lg px-4 py-2 font-semibold max-lg:hidden lg:block max-xl:text-sm">
                    Sign In
                  </div>
                </Link>
                <Link to={process.env.REACT_APP_SIGN_UP}>
                  <div className="text-black text-lg bg-custom-primary hover:bg-custom-primary-dark transition-colors px-3 py-1.5 md:px-5 md:py-2 rounded-lg font-semibold h-fit max-xl:text-sm">
                    Sign Up
                  </div>
                </Link>
                <div className="items-center max-lg:flex lg:hidden ml-5">
                  <FontAwesomeIcon
                    onClick={() => setOpenMobile(true)}
                    icon={faBars}
                    className="text-3xl text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileNavbar open={openMobile} setOpen={setOpenMobile} />
    </>
  );
};

export default Navbar;

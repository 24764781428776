import React from "react";
import { Link } from "react-router-dom";

const SubFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="bg-[#000000] text-white">
      <div className="container py-16 max-sm:py-8">
        <div className="flex max-md:flex-col gap-6 mb-9 max-md:gap-5 max-sm:gap-4 max-sm:mb-6">
          <Link to="/privacy-policy">
            <p className="text-white hover:underline text-lg py-4 pr-4 max-sm:text-base">
              Privacy Notice
            </p>
          </Link>
          <Link to="/terms">
            <p className="text-white hover:underline text-lg py-4 pr-4 max-sm:text-base">
              Terms of Service
            </p>
          </Link>
          <Link to="/complaints-policy">
            <p className="text-white hover:underline text-lg py-4 pr-4 max-sm:text-base">
              Complaints Policy
            </p>
          </Link>
          <Link to="/cookie-policy">
            <p className="text-white hover:underline text-lg py-4 pr-4 max-sm:text-base">
              Cookies Policy
            </p>
          </Link>
          {/* <Link to="/disclosures">
            <p className="text-white hover:underline text-lg py-4 pr-4 max-sm:text-base">
              Disclosures
            </p>
          </Link> */}
          <Link to="/aml-policy">
            <p className="text-white hover:underline text-lg py-4 pr-4 max-sm:text-base">
              AML Policy
            </p>
          </Link>
        </div>
        <p className="text-sm mb-6 max-sm:mb-4">© {year} NIZA Global, Inc.</p>
        <p className="text-sm text-dark-text max-sm:text-[13px]">
          These materials are intended solely for general information and do not
          constitute investment advice, nor do they recommend, solicit, or
          endorse the buying, selling, staking, or holding of any cryptoasset or
          the adoption of any specific trading strategy. NIZA explicitly
          refrains from manipulating the prices of the crypto assets it offers.
          It is crucial to note that certain crypto products and markets operate
          without regulatory oversight, potentially leaving you without the
          protection afforded by government compensation or regulatory schemes.
          The inherent unpredictability of crypto-asset markets poses a risk of
          financial loss. Tax obligations may arise from returns or increases in
          the value of cryptoassets, necessitating independent advice on your
          taxation position. Additionally, geographic restrictions may apply.
        </p>
      </div>
    </div>
  );
};

export default SubFooter;

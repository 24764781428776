import React from "react";

export const Hero = ({ children, imgs, shadow, height }) => {
  return (
    <div className={`2xl:h-[100vh] md:h-[93vh] ${height ? height : '' } h-[1000px] bg-[#141A20] relative fade-bottom`}>
      <div className="z-[10] absolute left-[50%] top-[42%] max-sm:mt-0 translate-x-[-50%] translate-y-[-50%] w-[100%]">
        {children}
      </div>
      {/* <div className="absolute right-[-20%] top-[-10%] w-7 h-7 side-light-brighter max-md:hidden " /> */}
      {/* {imgs ? imgs.map((image) => image) : null} */}
      {imgs ? imgs : null}
      {shadow ? <div className="absolute w-full h-full z-[1] bg-black/40"></div> : null}
    </div>
  );
};

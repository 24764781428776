import React from "react";
import Hero from "../components/contactSales/Hero";
import ContactSalesTeam from "../components/contactSales/ContactSalesTeam";
import { Helmet } from "react-helmet";

const ContactSales = () => {
  return (
    <>
      <Helmet>
        <title>Niza - Contact Sales</title>
      </Helmet>
      <div>
        <Hero />
        <ContactSalesTeam />
      </div>
    </>
  );
};

export default ContactSales;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../lib/hooks/WindowSizeHook";

const NizaComunties = () => {
const { ref, inView } = useInView({
    threshold: 0,
  });

  const { width } = useWindowSize();

  return (
    <Parallax speed={width < 700 ? 10 : 30}>
      <div
        className={`mb-40 max-sm:mb-20 ${inView ? "fadeIn" : "opacity-0"}`}
        ref={ref}
      >
        <div className="text-center container">
          <p className="text-custom-primary text-[54px] font-semibold max-sm:text-5xl">
            Niza <span className="text-white">Communities</span>
          </p>
          <p className="text-dark-text max-sm:text-sm max-sm:mt-3 max-w-[800px] mx-auto">
            Join the Niza community and get all the latest updates on the crypto
            world. Talk with fellow traders and learn more.
          </p>
        </div>
        <div className="flex gap-3 items-center justify-center mt-[46px] mb-5 max-xl:mt-10 max-sm:mt-8">
          <div className="border-red-500 border-[1px] rounded-[5px] px-4 py-2 w-fit flex items-center gap-1.5 max-sm:gap-1 max-sm:py-1 max-sm:px-3">
            <div className="relative">
              <div className="w-2 h-2 bg-red-500 rounded-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"></div>
              <div className="w-4 h-4 bg-red-300/20 rounded-full "></div>
            </div>
            <p className="text-[20px] font-[500] text-white max-sm:text-base">
              Live
            </p>
          </div>
          <p className="text-custom-primary text-[24px] font-[300] max-sm:text-xl">
            Communities
          </p>
        </div>
        <div className="container flex max-lg:flex-col max-lg:items-center gap-[24px] justify-center">
          <div
            style={{
              background:
                "linear-gradient(86.46deg, rgba(20, 70, 197, 0.4) -7.3%, rgba(20, 70, 197, 0) 122.02%)",
            }}
            className="w-[391px] rounded-xl px-[34px] py-[35px] max-lg:w-full max-sm:p-6"
          >
            <div className="flex items-center gap-3">
              <img src="/icons/discord.svg" alt="" width={36} />
              <p className="text-white xl:text-2xl text-xl font-[500]">
                Discord
              </p>
            </div>
            <div className="flex">
              <Link
                to={process.env.REACT_APP_DISCORD}
                className="block mt-6 text-white xl:text-2xl text-lg max-sm:mt-4 hover:decoration-white hover:underline"
              >
                discord.gg/Niza{" "}
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="rotate-45 xl:ml-6 ml-4"
                />
              </Link>
            </div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(93.6deg, rgba(19, 142, 255, 0.38) 4.59%, rgba(40, 144, 240, 0) 121.02%)",
            }}
            className="w-[391px] rounded-xl px-[34px] py-[35px] max-lg:w-full max-sm:p-6"
          >
            <div className="flex items-center gap-3">
              <img src="/icons/telegram.svg" alt="" width={36} />
              <p className="text-white xl:text-2xl text-xl font-[500]">
                Telegram
              </p>
            </div>
            <div className="flex">
              <Link
                to={process.env.REACT_APP_TELEGRAM}
                className="block mt-6 text-white xl:text-2xl text-lg max-sm:mt-4 hover:decoration-white hover:underline"
              >
                t.me/nizaio{" "}
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="rotate-45 xl:ml-6 ml-4"
                />
              </Link>
            </div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(94.5deg, rgba(255, 255, 255, 0.08) 0.52%, rgba(255, 255, 255, 0) 109.85%)",
            }}
            className="w-[391px] rounded-xl px-[34px] py-[35px] max-lg:w-full max-sm:p-6"
          >
            <div className="flex items-center gap-3">
              <img src="/icons/xTwitter.svg" alt="" width={36} />
              <p className="text-white xl:text-2xl text-xl font-[500]">
                X.com{" "}
                <span className="text-dark-text font-[300]">(Ex Twitter)</span>
              </p>
            </div>
            <div className="flex">
              <Link
                to={process.env.REACT_APP_TWITTER}
                className="block mt-6 text-white xl:text-2xl text-lg max-sm:mt-4 hover:decoration-white hover:underline"
              >
                x.com/nizacoin{" "}
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="rotate-45 xl:ml-6 ml-4"
                />
              </Link>
            </div>
          </div>
        </div>
        <p className="text-custom-primary text-[24px] font-[300] text-center mb-5 mt-8 max-sm:font-normal">
          Coming Soon
        </p>
        <div className="flex items-center gap-5 justify-center text-dark-text max-sm:gap-3">
          {/* <div className="flex gap-[11px] bg-white/10 items-center px-5 py-4 rounded-[10px] max-sm:p-3">
            <FontAwesomeIcon icon={faInstagram} className="text-3xl max-sm:text-2xl" />
            <p className="text-xl text-white max-md:hidden">Instagram</p>
          </div> */}
          <div className="flex gap-[11px] bg-white/10 items-center px-5 py-4 rounded-[10px] max-sm:p-3">
            <FontAwesomeIcon
              icon={faFacebook}
              className="text-3xl max-sm:text-2xl"
            />
            <p className="text-xl text-white max-md:hidden">Facebook</p>
          </div>
          <div className="flex gap-[11px] bg-white/10 items-center px-5 py-4 rounded-[10px] max-sm:p-3">
            <FontAwesomeIcon
              icon={faYoutube}
              className="text-3xl max-sm:text-2xl"
            />
            <p className="text-xl text-white max-md:hidden">Youtube</p>
          </div>
          <div className="flex gap-[11px] bg-white/10 items-center px-5 py-4 rounded-[10px] max-sm:p-3">
            <FontAwesomeIcon
              icon={faTiktok}
              className="text-3xl max-sm:text-2xl"
            />
            <p className="text-xl text-white max-md:hidden">Tiktok</p>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default NizaComunties;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const Activities = () => {
  return (
    <div className="max-lg:mt-[700px] max-sm:mt-[1000px]">
      <p className="text-[54px] font-[600] text-center text-light-text tracking-[-2.16px] mt-[200px] max-sm:text-5xl">
        Activities
      </p>
      <p className="text-dark-text text-center pb-[54px] max-md:pb-10 max-sm:text-sm max-sm:mt-2">
        Pay attention to Niza's events here. Don't miss the chance to build your
        financial tomorrow today.
      </p>
      <div
        className="select-none"
        style={{
          paddingBottom: "30px",
          position: "relative",
        }}
      >
        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className="carouselTrack"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 4,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <Link to="https://x.com/nizacoin/status/1730496744251605339?s=46">
            <img
              src="/images/activities/card1.png"
              className="pointer-events-none rounded-2xl border-[3px] border-white/20"
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          </Link>
          <Link to="https://x.com/nizacoin/status/1747951547936313743?s=46">
            <img
              className="pointer-events-none rounded-2xl border-[3px] border-white/20"
              src="/images/activities/card2.png"
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          </Link>
          <Link to="https://x.com/nizacoin/status/1737443988062240915?s=46">
            <img
              className="pointer-events-none rounded-2xl border-[3px] border-white/20"
              src="/images/activities/card3.png"
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          </Link>
          <Link to="https://x.com/nizacoin/status/1772534344072171820?s=46">
            <img
              className="pointer-events-none rounded-2xl border-[3px] border-white/20"
              src="/images/activities/card4.png"
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          </Link>
          <Link to="https://x.com/nizacoin/status/1787483337705308343?s=46">
            <img
              className="pointer-events-none rounded-2xl border-[3px] border-white/20"
              src="/images/activities/card5.png"
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          </Link>
        </Carousel>
      </div>
    </div>
  );
};

export default Activities;

export const navbarTabs = [
  {
    name: "Explorer",
    width: 396,
    childrenLinks: [
      {
        name: "NIZA TRADE <img src='/icons/nizaPro.png' alt=''>",
        description: "Meet all your demands as an advanced crypto trader.",
        link:process.env.REACT_APP_TRADE,
        coming_soon: false,
      },
      {
        name: "Referral & Bonuses Program",
        description: "Invite your friends and share the benefits.",
        link: "/referralAndBonuses",
        coming_soon: false,
      },
      {
        name: "Staking (New)",
        description: "Generate passive income via your digital assets.",
        coming_soon: false,
        link: "https://app.niza.io/earn/stake"
      },
    ]
  },
  {
    name: "Buy Crypto",
    width: 396,
    childrenLinks: [
      {
        name: "Debit / Credit Card",
        description: "Invest in your crypto income via your card",
        link: process.env.REACT_APP_SIGN_UP,
        coming_soon: false,
      },
      {
        name: "Global Bank Transfer",
        description: "Send money abroad fast and securely.",
        link: process.env.REACT_APP_SIGN_UP,
        coming_soon: false,
      },
      {
        name: "Apple / Google Pay",
        description: "Save your time when you buy crypto.",
        link: process.env.REACT_APP_SIGN_UP,
        coming_soon: false,
      },
    ]
  },
  {
    name: "Banking",
    childrenLinks: [
      {
        name: "Personal IBAN",
        description: "Make secure and fast payments.",
        link: "/banking",
        coming_soon: false,
      },
      {
        name: "Transfer Fees",
        description: "All banking fees.",
        link: "/banking-fees",
        coming_soon: false,
      },
    ]
  },
  {
    name: "Company",
    width: 396,
    childrenLinks: [
      {
        name: "About Us",
        description: "Embrace Niza, where investing is comfortable.",
        link: "/about",
        coming_soon: false,
      },
      {
        name: "Contact",
        description: "Reach us here with any queries you have.",
        link: "/contact",
        coming_soon: false,
      },
    ]
  },
  {
    name: "Buy Niza",
    width: 300,
    childrenLinks: [
      {
        name: "Niza Global",
        link: 'https://app.niza.io/',
        image: '/icons/NizaGlobalBuy.svg',
        coming_soon: false,
      },
      {
        name: "Uniswap",
        link: 'https://app.uniswap.org/explore/tokens/ethereum/0xb58e26ac9cc14c0422c2b419b0ca555ee4dcb7cb',
        image: '/icons/Uniswap.svg',
        coming_soon: false,
      },
      {
        name: "MEXC",
        link: 'https://www.mexc.com/exchange/NIZA_USDT',
        image: '/icons/MEXC.svg',
        coming_soon: false,
      },
      {
        name: "LBANK",
        link: 'https://www.lbank.com/trade/niza_usdt',
        image: '/icons/LBANK.svg',
        coming_soon: false,
      },
      {
        name: "BitMart",
        link: 'https://www.bitmart.com/trade/en-US?symbol=NIZA_USDT&layout=pro',
        image: '/icons/BitMart.svg',
        coming_soon: false,
      },
      {
        name: "Gate.io",
        link: 'https://www.gate.io/trade/NIZA_USDT',
        image: '/icons/Gateio.svg',
        coming_soon: false,
      },
    ]
  },
]
import React, { useState } from "react";
import { Link } from "react-router-dom";

const DownloadNiza = () => {
  const [open, setOpen] = useState(true);

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const isAndroid = () => {
    return /Android/.test(navigator.userAgent);
  };

  const getDownloadLink = () => {
    if (isIOS()) {
      return "https://apps.apple.com/us/app/niza-buy-btc-eth-500/id6468353879";
    } else if (isAndroid()) {
      return "https://play.google.com/store/apps/details?id=io.niza.app";
    } else {
      return "/";
    }
  };

  return open && isMobile ? (
    <div className="fixed bottom-[15px] left-[50%] translate-x-[-50%] z-10 bg-[#30384d] w-[95%] p-[20px] rounded-lg shadow-xl flex items-center justify-between">
      <div className="flex items-center gap-4">
        <img
          src="/app-logo.png"
          alt="App Logo"
          className="w-[65px] rounded-xl"
        />
        <div>
          <p className="text-white font-bold"><span className="text-custom-primary">Niza:</span> Buy BTC, ETH & +500</p>
          <p className="text-[#aaa]">Get the Mobile App</p>
        </div>
      </div>
      <div className="flex items-center gap-5">
        <Link
          to={getDownloadLink()}
          target="_blank"
        >
          <div className="bg-dark-background p-3 rounded-lg">
            <img
              src="/icons/download.svg"
              alt=""
              className="w-[30px] text-custom-primary cursor-pointer"
            />
          </div>
        </Link>
        <img
          src="/icons/close.svg"
          alt="Close"
          className="w-[18px] cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
    </div>
  ) : null;
};

export default DownloadNiza;

import React from "react";
import Cards from "../common/Cards";
import { Link } from "react-router-dom";

const BankingServices = () => {
  const cardData = [
    {
      icon: "/icons/swift-international.svg",
      title: "International Transfers (SWIFT)",
      description:
        "With Niza Global you can send funds all over the world in EUR, USD, GBP.",
    },
    {
      icon: "/icons/sepa-transfer.svg",
      title: "Euro Zone Transfers",
      description:
        "Single Euro Payments Area (SEPA) is our area of expertise since we started our operations. Our experienced team is here to assist you in all things SEPA.",
    },
    {
      icon: "/icons/crypto-exchange.svg",
      title: "Cryptoexchange",
      description:
        "Buy and sell 70+ foreign cryptocurrencies without any hidden fees with Niza Global.",
    },
    {
      icon: "/icons/internal-transfers.svg",
      title: "Internal Transfers",
      description:
        "Niza Global provides a possibility to send funds between its clients completely free of charge.",
    },
  ];

  return (
    <div className="py-[130px]">
      <p className="text-white text-center text-[38px] md:text-[54px] font-medium mb-14">
        Our <span className="text-custom-primary">Banking</span>
        <br />
        services & offerings
      </p>
      <div className="max-md:container md:w-[800px] xl:w-[1226px] mx-auto">
        <Cards grid={2} cardData={cardData} type="gradient" />
      </div>
      <Link to="/banking-fees">
        <div className="bg-custom-primary w-fit p-3 font-bold container mx-auto hover:bg-transparent cursor-pointer rounded-lg border border-custom-primary transition-all ease-in-out hover:text-custom-primary">
          Check our banking Fees
        </div>
      </Link>
    </div>
  );
};

export default BankingServices;
